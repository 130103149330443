import React, { Fragment } from 'react'

import icon from '@/assets/img/ios-active.png'
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api'

const Mapbox = ({ latitude, longitude }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
  })

  if (loadError) return ''
  if (!isLoaded) return ''

  return (
    <Fragment>
      <GoogleMap
        mapContainerStyle={{
          height: '40em',
          borderRadius: 10,
        }}
        zoom={latitude < 0 && longitude < 0 ? 13 : 3}
        center={{ lat: latitude, lng: longitude }}
        options={{
          disableDefaultUI: true,
        }}
      >
        {latitude < 0 && longitude < 0 && (
          <Marker
            position={{
              lat: latitude,
              lng: longitude,
            }}
            icon={{
              url: icon,
              scaledSize: new window.google.maps.Size(80, 80),
              origin: new window.google.maps.Point(0, 0),
            }}
          />
        )}
      </GoogleMap>
    </Fragment>
  )
}

export default Mapbox
