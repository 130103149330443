import React from 'react'

import IconChevronRight from '@/components/Presentational/Icons/IconChevronRight'
import IconLoader from '@/components/Presentational/Icons/IconLoader'

import * as S from './styled'

const CollapsibleItem = ({ title, status, spinner, dataTestId, onClick }) => {
  return (
    <S.Item
      role='button'
      tabIndex='0'
      data-testid={dataTestId}
      onClick={onClick}
      spinner={spinner}
    >
      <S.Header>
        <S.Title>{title}</S.Title>
        <IconChevronRight />
      </S.Header>

      <hr />
      {spinner ? (
        <IconLoader />
      ) : status === 'PENDING' ? (
        <S.Label status='pending'>Pendente</S.Label>
      ) : status === 'ANALYSIS' ? (
        <S.Label status='analysis'>Em andamento</S.Label>
      ) : (
        <S.Label status='success'>Completo</S.Label>
      )}
    </S.Item>
  )
}

export default CollapsibleItem
