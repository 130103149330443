import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import IMAGE1 from '@/assets/img/logo_color.png'
import IMAGE2 from '@/assets/img/sucess.png'
import Chatbot from '@/components/Containers/Chatbot'
import ButtonPrimary from '@/components/Presentational/Buttons/Primary'
import Footer from '@/components/Presentational/Footer'

import { Footers, Main, Row, Container, Logo } from './styled'

const DeleteAccount = () => {
  const navigate = useNavigate()

  useEffect(() => {
    localStorage.removeItem('st')
  }, [])

  return (
    <Main>
      <Row>
        <Container>
          <Logo src={IMAGE1} alt='logo' />
          <p>Sua conta foi apagada</p>
          <Logo src={IMAGE2} alt='logo' style={{ width: '40px' }} />

          <p>Obrigado por usar nosso produto.</p>
          <p style={{ color: '#004BC1', textAlign: 'center' }}>
            Esperamos que possa retornar <br /> em breve!
          </p>
          <br />

          <ButtonPrimary
            style={{
              backgroundColor: '#004BC1',
              border: 'none',
              color: '#ffffff',
            }}
            onClick={() => navigate('/')}
            name='Ir para o inicio'
          />
        </Container>
      </Row>
      <Footers>
        <Footer isShowBanner={false} />
      </Footers>
      <Chatbot />
    </Main>
  )
}
export default DeleteAccount
