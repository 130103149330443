import React from 'react'

import IMAGE from '@/assets/img/error-generic.png'
import Layout from '@/layout/External'

import * as S from './styled'

const NotFound = () => {
  return (
    <Layout>
      <S.Main>
        <S.Container>
          <div data-testid='page-not-found'>
            <h1>Não foi possível seguir com a solicitação.</h1>
            <p>Clique abaixo para falar com nosso suporte.</p>
            <S.Link href='/page-not-found?show-bot'>Fale conosco</S.Link>
          </div>
          <img src={IMAGE} alt='error generic' />
        </S.Container>
      </S.Main>
    </Layout>
  )
}

export default NotFound
