import React, { createContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { SETTINGS } from '@/constants/settings'

export const SettingsContext = createContext({})

export const SettingsProvider = ({ children }) => {
  const location = useLocation()

  useEffect(() => {
    const settings = SETTINGS.filter(
      (item) => item.route === location.pathname
    ).shift()

    if (settings) {
      const description = document.querySelector(`meta[name='description']`)
      description.setAttribute('content', settings.description)

      const title = document.querySelector('title')
      title.innerText = settings.title
    }
  }, [location])

  return (
    <SettingsContext.Provider value={{}}>{children}</SettingsContext.Provider>
  )
}
