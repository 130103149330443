import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
`

export const Button = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
`
