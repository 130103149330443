import React from 'react'
import ReactHtmlParser from 'react-html-parser'

import * as S from './styled'

const Details = ({ question, answer }) => {
  return (
    <S.Details>
      <S.Summary>
        <h5>{question}</h5>
      </S.Summary>
      <div>{ReactHtmlParser(answer)}</div>
    </S.Details>
  )
}

export default Details
