import React from 'react'
import { isMobile } from 'react-device-detect'

// import { URL_CHATBOT } from "@/constants"
import {
  Container,
  Dropdown,
  DropdownContainer,
  Icon,
  Li,
  Link,
  Main,
  Paragraph,
  Ul,
} from './styled'

const Menu = () => {
  return (
    <React.Fragment>
      <Ul>
        <Li>
          <svg
            width='32'
            height='32'
            viewBox='0 0 32 32'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <rect
              width='31.9998'
              height='32'
              rx='2'
              fill='#004BC1'
              fillOpacity='0.1'
            />
            <path
              d='M15.9999 6.75V6.74974L15.9886 6.75026C10.6529 6.99282 6.75005 11.0028 6.75 16C6.75 21.1003 10.8993 25.25 16 25.25C17.6908 25.25 19.3261 24.7924 20.7661 23.9229L23.6468 25.1568C23.7933 25.2197 23.9472 25.25 24.1 25.25C24.3988 25.25 24.693 25.1332 24.9132 24.913C25.2456 24.5806 25.3422 24.0786 25.1567 23.6469L23.9223 20.7665C24.7927 19.3264 25.2499 17.6912 25.2499 16C25.2499 10.8997 21.1006 6.75 15.9999 6.75ZM21.6823 19.9909L21.6821 19.9912C21.6069 20.0984 21.6073 20.2213 21.6164 20.3012C21.6264 20.3884 21.6534 20.48 21.6855 20.5666C21.7502 20.741 21.8532 20.9414 21.9592 21.1317C22.0548 21.3031 22.158 21.4751 22.2465 21.6226C22.2571 21.6403 22.2675 21.6575 22.2776 21.6744C22.378 21.8419 22.4436 21.9544 22.466 22.0065L22.8105 22.8108L22.0079 22.4669C22.0077 22.4668 22.0076 22.4667 22.0074 22.4666C21.9544 22.4435 21.8416 22.3774 21.6747 22.2773C21.6595 22.2682 21.6441 22.2589 21.6283 22.2495C21.4795 22.1601 21.3053 22.0555 21.1317 21.9588C20.9414 21.8527 20.741 21.75 20.5665 21.6855C20.4799 21.6535 20.3883 21.6265 20.301 21.6167C20.2211 21.6078 20.0984 21.6077 19.9916 21.6831C18.8135 22.5123 17.4338 22.9498 15.9997 22.9498C12.1678 22.9498 9.04971 19.8317 9.04971 15.9998C9.04971 12.1678 12.1678 9.0498 15.9997 9.0498C19.8317 9.0498 22.9497 12.1678 22.9497 15.9998C22.9497 17.4335 22.5117 18.8139 21.6823 19.9909Z'
              fill='#004BC1'
              stroke='#004BC1'
              strokeWidth='0.5'
            />
          </svg>
        </Li>
        <Li>
          <Container>
            <p>Chat</p>
            <Paragraph>
              Tire suas dúvidas de maneira prática acessando nosso chatbot.
            </Paragraph>
            <Link onClick={() => document.getElementById('chat-image').click()}>
              Acessar
            </Link>
          </Container>
        </Li>
      </Ul>
      <Ul>
        <Li>
          <svg
            width='32'
            height='32'
            viewBox='0 0 32 32'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <rect
              width='31.9998'
              height='32'
              rx='2'
              fill='#004BC1'
              fillOpacity='0.1'
            />
            <rect
              x='8'
              y='8'
              width='16'
              height='16'
              rx='3'
              stroke='#004BC1'
              strokeWidth='2'
            />
            <rect
              x='11.5'
              y='11.5'
              width='9'
              height='3'
              rx='0.5'
              stroke='#004BC1'
            />
            <rect
              x='11.5'
              y='17.5'
              width='9'
              height='3'
              rx='0.5'
              stroke='#004BC1'
            />
          </svg>
        </Li>
        <Li>
          <Container>
            <p>Perguntas Frequentes</p>
            <Paragraph>
              Aprenda com as dúvidas dos outros usuários, veja quais são as
              perguntas mais frequentes.
            </Paragraph>
            <Link onClick={() => window.open('/faq', '_blank')}>Acessar</Link>
          </Container>
        </Li>
      </Ul>
    </React.Fragment>
  )
}

const Help = () => {
  if (isMobile) {
    return (
      <div className='title-section'>
        <div className='group'>
          <p>Ficou com alguma dúvida?</p>
        </div>
        <Main>
          <Menu />
        </Main>
      </div>
    )
  }

  return (
    <Dropdown>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 20,
          background: '#004BC1',
          width: '100%',
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
        }}
      >
        <Icon>
          <svg
            width='38'
            height='28'
            viewBox='0 0 38 28'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g filter='url(#filter0_d_4395_30030)'>
              <path
                d='M14.9991 13L8 22H30L22.9991 13C22.9991 13 20.9517 10 18.9991 10C17.0465 10 14.9991 13 14.9991 13Z'
                fill='#004BC1'
              />
            </g>
            <defs>
              <filter
                id='filter0_d_4395_30030'
                x='0'
                y='0'
                width='38'
                height='28'
                filterUnits='userSpaceOnUse'
                colorInterpolationFilters='sRGB'
              >
                <feFlood floodOpacity='0' result='BackgroundImageFix' />
                <feColorMatrix
                  in='SourceAlpha'
                  type='matrix'
                  values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                  result='hardAlpha'
                />
                <feOffset dy='-2' />
                <feGaussianBlur stdDeviation='4' />
                <feComposite in2='hardAlpha' operator='out' />
                <feColorMatrix
                  type='matrix'
                  values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0'
                />
                <feBlend
                  mode='normal'
                  in2='BackgroundImageFix'
                  result='effect1_dropShadow_4395_30030'
                />
                <feBlend
                  mode='normal'
                  in='SourceGraphic'
                  in2='effect1_dropShadow_4395_30030'
                  result='shape'
                />
              </filter>
            </defs>
          </svg>
        </Icon>
        <p style={{ color: '#fff' }}>Ficou com alguma dúvida?</p>
      </div>
      <DropdownContainer>
        <Menu />
      </DropdownContainer>
    </Dropdown>
  )
}

export default Help
