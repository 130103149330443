import colors from '@/styles/colors'
import { Paragraph, Span } from '@/styles/fonts'

import styled from 'styled-components'

const handleSizeType = (type) => {
  if (type === 'reverse') {
    return 'grid-column: 5 / 11;'
  }

  if (type === 'no-reverse') {
    return 'grid-column: 1 / 5;'
  }

  if (type === 'full-width') {
    return 'grid-column: 1 / 7;'
  }
  if (type === 'no-full-width') {
    return 'grid-column: 7 / 11;'
  }
}

export const Container = styled.div`
  border: 1px solid ${colors.grey3};
  border-radius: 24px;
  box-shadow: 0px 0px 8px hsla(0, 0%, 0%, 0.08);
  display: grid;
  font-size: 16px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  line-height: 20px;
  padding: 16px;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : colors.white};
  ${(props) =>
    props.status == null ? 'pointer-events: none;' : 'cursor: pointer;'};
  ${({ type }) => handleSizeType(type)}

  :hover {
    border: 1px solid ${colors.primary};
  }
`

export const Disabled = styled.div`
  border: 1px solid ${colors.grey3};
  border-radius: 24px;
  box-shadow: 0px 0px 8px hsla(0, 0%, 0%, 0.08);
  display: grid;
  font-size: 16px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  line-height: 20px;
  padding: 16px;
  background-color: ${colors.white};
  cursor: not-allowed;
  grid-column: ${(props) => (props.fullWidth ? '1 / 7' : '7 / 11')};
`

export const Icon = styled.div`
  display: flex;
  grid-column: 1 / 2;
  justify-content: flex-start;
`

export const Status = styled.div`
  display: flex;
  align-items: flex-start;
  grid-column: 2 / 3;
  justify-content: flex-end;
`

export const Text = styled.div`
  display: flex;
  grid-column: 1 / 2;
  justify-content: flex-start;
`

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
`

export const Title = styled(Paragraph)`
  color: ${(props) => props.colorTitle};
`

export const SubTitle = styled(Span)`
  color: ${(props) => props.colorTitle};

  @media only screen and (max-width: 768px) {
    display: none;
  }
`

export const Button = styled.div`
  display: flex;
  align-items: flex-start;
  grid-column: 2 / 3;
  justify-content: flex-end;
  align-items: center;
`
