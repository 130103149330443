import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
  height: 100%;
  background: hsla(0, 0%, 0%, 0.4);
  position: fixed;
  z-index: 9;
  left: 0px;
  top: 77px;
  display: grid;
  grid-template-columns: 1fr auto;
`

export const Content = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr;
  background-color: hsla(0, 0%, 100%, 1);
  overflow-x: auto;
  padding: 30px 20px;
  width: 415px;
  height: calc(100% - 77px);

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

export const Background = styled.div`
  @media only screen and (max-width: 768px) {
    display: none;
  }
`
