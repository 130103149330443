import React, { useState } from 'react'

import { Input } from '@/components/Presentational/Inputs'
import InputCEP from '@/components/Presentational/Inputs/CEP'
import Loader from '@/components/Presentational/Loader'
import { useAuth } from '@/contexts/Authentication'
import { useDebounce } from '@/hooks/useDebounce'
import useToast from '@/hooks/useToast'
import { updateAddressUser } from '@/services/APIService/Users'
import { getAddressByZipCode } from '@/services/APIService/Utilities'

const MyAddress = () => {
  const { user } = useAuth()
  const premium = user.profile.subscription.premium
  const { toastrMessage } = useToast()
  const [loader, setLoader] = useState(false)
  const [disabled, setDisabled] = useState(true)

  const [formData, setFormData] = useState({
    zipCode: user.profile.zipcode,
    address: user.profile.address,
    number: user.profile.address_number,
    complement: user.profile.address_complement,
    city: user.profile.city,
    state: user.profile.state,
  })

  const getAddress = useDebounce(async (cep) => {
    const response = await getAddressByZipCode(cep)

    if (!response.status) {
      setLoader(false)
      return
    }

    setFormData({
      ...formData,
      zipCode: response.data.zipCode,
      address: response.data.address,
      complement: response.data.complement,
      state: response.data.state,
      city: response.data.city,
    })
  }, 1000)

  const onChange = (e) => {
    const { name, value } = e.target
    setDisabled(false)

    setFormData({
      ...formData,
      [name]: value,
    })

    if (name === 'zipCode' && value.length === 9) {
      getAddress(value)
    }
  }

  const evFormSubmit = async (e) => {
    e.preventDefault()

    setLoader(true)

    const updateAddressUserResponse = await updateAddressUser(
      user.profile.msisdn,
      {
        zipcode: formData.zipCode,
        state: formData.state,
        city: formData.city,
        neighborhood: '',
        address: formData.address,
        address_number: formData.number,
        address_complement: formData.complement,
      }
    )

    if (!updateAddressUserResponse.status) {
      setLoader(false)
      toastrMessage(
        'error',
        'Desculpe, ocorreu um erro. Por favor tente novamente!'
      )
      return
    }

    toastrMessage('success', 'Seu endereço foi alterado com sucesso!')
  }

  return (
    <React.Fragment>
      <div className='title-section'>
        <div className='group'>
          <p>Endereço</p>
        </div>
      </div>

      <form
        id='address'
        onSubmit={evFormSubmit}
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {loader && <Loader />}

        <label htmlFor='cep'>CEP</label>

        <InputCEP
          type='text'
          name='zipCode'
          value={formData.zipCode}
          onChange={onChange}
          disabled={!premium ? 'disabled' : ''}
        />

        <br />

        <label htmlFor='logradouro'>Logradouro</label>
        <Input
          type='text'
          value={formData.address}
          disabled={!premium ? 'disabled' : ''}
          name='address'
          onChange={onChange}
          id='logradouro'
        />

        <br />

        <div
          style={{
            display: 'flex',
            gap: '20px',
          }}
        >
          <div>
            <label htmlFor='numero'>Número</label>
            <Input
              type='text'
              value={formData.number}
              disabled={!premium ? 'disabled' : ''}
              name='number'
              onChange={onChange}
              id='numero'
            />
          </div>

          <div>
            <label htmlFor='complemento'>Complemento</label>
            <Input
              type='text'
              value={formData.complement}
              disabled={!premium ? 'disabled' : ''}
              name='complement'
              onChange={onChange}
              id='complemento'
            />
          </div>
        </div>

        <br />

        <div
          style={{
            display: 'flex',
            gap: '20px',
          }}
        >
          <div>
            <label htmlFor='cidade'>Cidade</label>
            <Input
              type='text'
              value={formData.city}
              name='city'
              onChange={onChange}
              disabled
            />
          </div>

          <div>
            <label htmlFor='estado'>Estado</label>
            <Input
              type='text'
              value={formData.state}
              name='state'
              onChange={onChange}
              disabled
            />
          </div>
        </div>

        <br />
        <br />
        {premium && (
          <button
            id='updateData'
            type='submit'
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '20px 0',
            }}
            disabled={disabled}
          >
            Salvar endereço
          </button>
        )}
      </form>
    </React.Fragment>
  )
}
export default MyAddress
