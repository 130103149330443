import React from 'react'

import CardAction from '@/components/Presentational/Cards/Action'
import CardDisabled from '@/components/Presentational/Cards/Disabled'
import IconBlock from '@/components/Presentational/Icons/IconBlock'
import IconCheckIn from '@/components/Presentational/Icons/IconCheckIn'
import IconCheckOut from '@/components/Presentational/Icons/IconCheckOut'
import IconLoader from '@/components/Presentational/Icons/IconLoader'
import LocationIcon from '@/components/Presentational/Icons/LocationIcon'
import colors from '@/styles/colors'

const Card = ({ status, spinner, disabled, onClick }) => {
  if (disabled) {
    return (
      <CardDisabled
        dataTestId='card-disabled-location'
        type={'full-width'}
        title={'Atualizar localização'}
        iconLeft={<IconBlock color={colors.disabled2} />}
      />
    )
  }

  return (
    <CardAction
      style={{ width: '40%' }}
      dataTestId='card-location'
      type={'full-width'}
      status={spinner === null || !status ? null : false}
      onClick={onClick}
      title={
        spinner === null || spinner
          ? 'Atualizando localização'
          : 'Atualizar localização'
      }
      iconLeft={<LocationIcon />}
      iconRight={
        spinner === null || spinner ? (
          <IconLoader />
        ) : status ? (
          <IconCheckIn />
        ) : (
          <IconCheckOut />
        )
      }
    />
  )
}

export default Card
