import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAuth } from '@/contexts/Authentication'
import { useLoader } from '@/contexts/Loader'
import useToast from '@/hooks/useToast'
import { logAnalyticsEvent } from '@/services/Analytics'
import { deleteAccountUser } from '@/services/APIService/Users'

import StepFour from './atoms/StepFour'
import StepOne from './atoms/StepOne'
import StepThree from './atoms/StepThree'
import StepTwo from './atoms/StepTwo'
import styles from './styles.module.css'

const DeleteAccount = () => {
  const { setLoader } = useLoader()
  const navigate = useNavigate()
  const {
    user: {
      profile: { email },
    },
  } = useAuth()
  const { toastrMessage } = useToast()
  const [formData, setFormData] = useState({
    feedback: '',
    clearData: '',
  })
  const [checkedItems, setCheckedItems] = useState({
    reason1: '',
    reason2: '',
    reason3: '',
    reason4: '',
  })
  const [display, setDisplay] = useState({
    stepOne: true,
    stepTwo: false,
    stepThree: false,
    stepFour: false,
  })

  const handleChange = (e) => {
    const { name, checked, value } = e.target

    if (name === 'feedback' || name === 'clearData') {
      setFormData((data) => ({
        ...data,
        [name]: value,
      }))
      return
    }

    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [name]: checked ? value : '',
    }))
  }

  const handleSubmitFormFour = async () => {
    setLoader(true)

    const deleteAccountResponse = await deleteAccountUser(email)

    if (!deleteAccountResponse.status) {
      setLoader(false)
      toastrMessage('error', deleteAccountResponse.message)
      logAnalyticsEvent('delete_account_web', {
        status: 'error',
      })
      return
    }

    logAnalyticsEvent('delete_account_reasons', {
      reason: checkedItems.reason1,
      reason_1: checkedItems.reason2,
      reason_2: checkedItems.reason3,
      reason_3: checkedItems.reason4,
      improvement_term: formData.feedback,
    })

    logAnalyticsEvent('delete_account_web', {
      status: 'success',
    })

    toastrMessage('success', 'Sua conta foi deletada com sucesso')
    setLoader(false)
    navigate('/success-delete-account')
  }

  return (
    <div className={styles.contentDelete}>
      <p style={{ marginBottom: '22px' }}>Apagar minha conta</p>

      {display.stepOne && (
        <StepOne
          onClick={() =>
            setDisplay((data) => ({
              ...data,
              stepOne: false,
              stepTwo: true,
            }))
          }
        />
      )}

      {display.stepTwo && (
        <StepTwo
          onClickPrevious={() =>
            setDisplay((data) => ({
              ...data,
              stepOne: true,
              stepTwo: false,
            }))
          }
          onClickNext={() =>
            setDisplay((data) => ({
              ...data,
              stepThree: true,
              stepTwo: false,
            }))
          }
        />
      )}

      {display.stepThree && (
        <StepThree
          feedback={formData.feedback}
          checkedItems={checkedItems}
          onClickPrevious={() =>
            setDisplay((data) => ({
              ...data,
              stepThree: false,
              stepTwo: true,
            }))
          }
          onChange={handleChange}
          onSubmit={(e) => {
            e.preventDefault()
            setDisplay((data) => ({
              ...data,
              stepThree: false,
              stepFour: true,
            }))
          }}
        />
      )}

      {display.stepFour && (
        <StepFour
          onChange={handleChange}
          clearData={formData.clearData}
          onClick={handleSubmitFormFour}
          onClickPrevious={() =>
            setDisplay((data) => ({
              ...data,
              stepThree: true,
              stepFour: false,
            }))
          }
        />
      )}
    </div>
  )
}
export default DeleteAccount
