import React from 'react'

const IconBlock = ({ onClick, color = '#004BC1' }) => {
  return (
    <svg
      width='38'
      height='52'
      viewBox='0 0 38 52'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <path
        d='M36 31.52V28C36 24.6863 33.3137 22 30 22H8C4.68629 22 2 24.6863 2 28V44C2 47.3137 4.68629 50 8 50H19H27.5H30C33.3137 50 36 47.3137 36 44V41.53'
        stroke={color}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path
        d='M29 21V12.6667C29 5.79379 24.5228 2 19 2C13.4772 2 9 5.79379 9 12.6667V21'
        stroke={color}
        strokeWidth='3'
      />
      <circle cx='19' cy='36' r='2.5' fill={color} stroke={color} />
    </svg>
  )
}

export default IconBlock
