import React from 'react'
import { OtpInput } from 'reactjs-otp-input'

import * as S from './styled'

const FormValidatePincode = ({
  email,
  pincode,
  timer,
  disabledButton,
  handleResendCode,
  onChange,
  onSubmit,
}) => {
  return (
    <S.Section>
      <S.Header>
        <h1>Digite o código enviado para o e-mail abaixo:</h1>
        <h2>{email}</h2>
        <S.ButtonResendPincode
          type='button'
          onClick={handleResendCode}
          disabled={disabledButton}
          data-testid='resend-pincode'
        >
          Reenviar código
        </S.ButtonResendPincode>
        <p>
          Não recebeu código? Garanta que o e-mail seja o mesmo cadastrado na
          plataforma. Se o problema persistir, entre em contato.
        </p>
      </S.Header>
      <S.Form onSubmit={onSubmit}>
        <p>
          Digite o código que enviamos para o e-mail para validar as informações
          e garantir a sua segurança.
        </p>
        <label htmlFor='codeOne'>
          Código de verificação
          <S.InputPincode>
            <OtpInput
              value={pincode}
              onChange={onChange}
              numInputs={6}
              separator={<div style={{ width: 10 }} />}
            />
          </S.InputPincode>
        </label>
        <S.Timer>
          <span>Reenviar código em {timer} segundos</span>
        </S.Timer>
        <button type='submit'>
          Entrar
          <svg
            width='21'
            height='20'
            viewBox='0 0 21 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M10.7439 17.2559C10.4185 16.9305 10.4185 16.4028 10.7439 16.0774L15.988 10.8333L2.99984 10.8333C2.5396 10.8333 2.1665 10.4602 2.1665 10C2.1665 9.53976 2.5396 9.16667 2.99984 9.16667L15.988 9.16667L10.7439 3.92259C10.4185 3.59715 10.4185 3.06952 10.7439 2.74408C11.0694 2.41864 11.597 2.41864 11.9224 2.74408L18.5891 9.41074C18.9145 9.73618 18.9145 10.2638 18.5891 10.5893L11.9224 17.2559C11.597 17.5814 11.0694 17.5814 10.7439 17.2559Z'
              fill='white'
            />
          </svg>
        </button>
      </S.Form>
    </S.Section>
  )
}
export default FormValidatePincode
