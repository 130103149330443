import styled from 'styled-components'

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  cursor: pointer;
  margin-top: 30px;
`

export const Title = styled.p`
  margin: 0px;
  color: #333333;
  font-size: 16px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 30px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const Description = styled.div`
  margin-bottom: 20px;
`

export const ContentCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;

  > p {
    text-align: center;
  }
`
