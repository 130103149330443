import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

import MyAddress from '@/components/Presentational/Menu/partials/MyData/Address'
import { useAuth } from '@/contexts/Authentication'

const MyData = () => {
  const { user } = useAuth()
  const [display, setDisplay] = useState(false)
  const cpf = String(user.profile.document)
  const phone = String(user.profile.msisdn)
  const replaceCpf = `***.***.${cpf.slice(6, 9)}-${cpf.slice(9, 11)}`
  const replacePhone = `(${phone.slice(2, 4)}) *****-${phone.slice(9, 14)}`
  const locaSNAddress =
    localStorage.getItem('naddr') !== null
      ? JSON.parse(localStorage.getItem('naddr'))
      : ''

  const premium = user.profile.subscription.premium

  const emailMask = (email) => {
    let maskedEmail = email.replace(/([^@.])/g, '*').split('')
    let previous = ''
    for (let i = 0; i < maskedEmail.length; i++) {
      if (i <= 1 || previous === '.' || previous === '@') {
        maskedEmail[i] = email[i]
      }
      previous = email[i]
    }
    return maskedEmail.join('')
  }

  const maskEmail = emailMask(user.profile.email)

  if (display) {
    return <MyAddress />
  }

  return (
    <React.Fragment>
      <div className='title-section'>
        <div className='group'>
          <p>Meus dados</p>
        </div>
      </div>
      <ul className='list-items'>
        {premium && (
          <li>
            <p>Nome</p>
            <p>{user.profile.fullname}</p>
          </li>
        )}
        {premium ? (
          <li>
            <p>Email</p>
            <p>{maskEmail}</p>
          </li>
        ) : (
          <li>
            <p>Email</p>
            <p>{maskEmail}</p>
          </li>
        )}
        <li>
          <p>Telefone</p>
          <p>{replacePhone}</p>
        </li>
        {premium && (
          <li>
            <p>CPF</p>
            <p>{replaceCpf}</p>
          </li>
        )}
        <li
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr auto',
            gap: '10px',
          }}
        >
          {premium ? (
            <p>{`${
              locaSNAddress !== ''
                ? locaSNAddress.logradouro
                : user.profile.address
            }, ${
              locaSNAddress !== ''
                ? locaSNAddress.number
                : user.profile.address_number
            }, ${
              locaSNAddress !== ''
                ? locaSNAddress.complement
                : user.profile.address_complement
            } - ${
              locaSNAddress !== '' ? locaSNAddress.city : user.profile.city
            }, ${
              locaSNAddress !== '' ? locaSNAddress.state : user.profile.state
            }`}</p>
          ) : (
            <p></p>
          )}
          <NavLink to='/dashboard' onClick={() => setDisplay(!display)}>
            <button type='button'>{premium ? 'Editar' : 'Visualizar'}</button>
          </NavLink>
        </li>
      </ul>
    </React.Fragment>
  )
}
export default MyData
