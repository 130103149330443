import styled from 'styled-components'

export const Breadcrumbs = styled.nav`
  > ul {
    display: flex;
    gap: 10px;
    flex-direction: row;

    > li {
      display: flex;
      align-items: center;
      gap: 10px;
      list-style-type: none;
      cursor: pointer;
      font-family: 'Space Grotesk';
    }
  }

  @media screen and (max-width: 767px) {
    > ul {
      font-size: 15px;
    }
  }
`
