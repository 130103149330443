import React from 'react'

const IconClock = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        cx='12'
        cy='12'
        r='10'
        fill='#757575'
        stroke='#FCFCFC'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M11 8V13H16'
        stroke='#FCFCFC'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default IconClock
