import styled from 'styled-components'

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-bottom: 50px;
`

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;

  @media screen and (max-width: 739px) {
    display: flex;
    flex-direction: column;
  }
`

export const ContentAction = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (max-width: 739px) {
    display: none;
  }
`

export const ContentForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (max-width: 739px) {
    width: 100%;
  }
`

export const ButtonGoBack = styled.div`
  display: none;

  @media screen and (max-width: 739px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-bottom: 30px;
  }
`

export const Actions = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 2;
  background-color: #ffffff;
  padding: 20px;
  width: 100%;
  box-shadow: 0px 0px 8px 0px #00000014;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
