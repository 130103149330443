import React, { useEffect, useState } from 'react'

import { useAuth } from '@/contexts/Authentication'
import { useLoader } from '@/contexts/Loader'
import { usePermissions } from '@/contexts/Permissions'
import useToast from '@/hooks/useToast'
import { logAnalyticsEvent } from '@/services/Analytics'
import { postNotification } from '@/services/APIService/Notification'

import Card from './atoms/Card'

const Alarm = () => {
  const {
    user: {
      profile: { device_id, device_os, lastAction },
      mobile: { device_model },
    },
  } = useAuth()
  const { toastrMessage } = useToast()
  const { setLoader } = useLoader()
  const { permissions } = usePermissions()
  const [isChecked, setIsChecked] = useState(false)
  const [status, setStatus] = useState(null)

  const handleClickSendNotification = async (e) => {
    const { checked } = e.target

    if (!status) {
      toastrMessage(
        'error',
        'Ative no app a permissão de Administrador de dispositivo e de Notificações'
      )
    }

    setLoader(true)

    const notification = checked ? 'alarmOn' : 'alarmOff'
    const logSentEvent = checked ? 'alarm_send' : 'alarm_disable'
    const logConfirmEvent = checked ? 'alarm_confirm' : 'alarm_disabled'

    logAnalyticsEvent(logSentEvent, {
      device_model: device_model,
    })

    const postNotificationResponse = await postNotification(
      device_id,
      notification
    )

    if (!postNotificationResponse.status) {
      toastrMessage('error', postNotificationResponse.message)
      logAnalyticsEvent('alarm_failure', {
        device_model: device_model,
      })
      return
    }

    logAnalyticsEvent(logConfirmEvent, {
      device_model: device_model,
    })

    setLoader(false)
    setIsChecked(checked)
    toastrMessage(
      'success',
      `Alarme ${checked ? 'disparado' : 'desativado'} com sucesso`
    )
  }

  useEffect(() => {
    if (permissions) {
      setStatus(permissions.alarm)
    }
  }, [permissions])

  if (device_os === 'ios') {
    return null
  }

  return (
    <Card
      status={status}
      checked={isChecked}
      disabled={lastAction === 'CLEANED' || lastAction === 'LOGGED_OUT'}
      onChange={handleClickSendNotification}
    />
  )
}
export default Alarm
