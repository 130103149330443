import { db } from '@/firebase'
import { captureException } from '@/utils/Instrumentation/Exception'
import { translateMessage } from '@/utils/Translator'
import {
  applyActionCode,
  getAuth,
  isSignInWithEmailLink,
  signInWithCustomToken,
  signInWithEmailLink,
} from 'firebase/auth'
import { doc, updateDoc } from 'firebase/firestore'

export async function isSignInEmailLink() {
  try {
    const auth = getAuth()
    const response = await isSignInWithEmailLink(auth, window.location.href)
    console.log('[signInWithEmailLink-response]:', response)

    return {
      status: response,
    }
  } catch (error) {
    captureException({
      error,
      tag: 'FIREBASE_IS_SIGNIN_EMAIL_LINK_EXCEPTION',
    })

    console.log('[signInWithEmailLink-error]: ', error.message)
    return {
      status: false,
      message: translateMessage(error.message),
    }
  }
}

export async function signInEmailLink(email) {
  try {
    const auth = getAuth()
    const response = await signInWithEmailLink(
      auth,
      email,
      window.location.href
    )
    console.log('[signInEmailLink-response]:', response)

    return {
      status: true,
    }
  } catch (error) {
    captureException({
      error,
      tag: 'FIREBASE_SIGNIN_EMAIL_LINK_EXCEPTION',
    })

    console.log('[signInEmailLink-error]: ', error.message)
    return {
      status: false,
      message: translateMessage(error.message),
    }
  }
}

export async function verifyEmailLink(actionCode) {
  try {
    const auth = getAuth()
    const response = await applyActionCode(auth, actionCode)
    console.log('[verifyEmailLink-response]:', response)

    return {
      status: true,
    }
  } catch (error) {
    captureException({
      error,
      tag: 'FIREBASE_VERIFY_EMAIL_LINK_EXCEPTION',
    })
    console.log('[verifyEmailLink-error]: ', error.message)
    return {
      status: false,
      message: translateMessage(error.message),
    }
  }
}

export async function signInCustomTokenUser(token) {
  try {
    const auth = getAuth()
    const response = await signInWithCustomToken(auth, token)
    console.log('[signInWithCustomToken-response]:', response)

    return {
      status: true,
    }
  } catch (error) {
    captureException({
      error,
      tag: 'FIREBASE_SIGNIN_CUSTON_TOKEN_EXCEPTION',
    })
    console.log('[signInWithCustomToken-error]: ', error.message)
    return {
      status: false,
      message: translateMessage(error.message),
    }
  }
}

export async function updateActionUser({ userId, lastAction }) {
  try {
    const userRef = doc(db, 'user', userId)

    await updateDoc(userRef, {
      last_action: lastAction,
    })

    console.log('[updateActionUser-success]:', true)

    return {
      status: true,
    }
  } catch (error) {
    captureException({
      error,
      tag: 'FIREBASE_UPDATE_ACTION_USER_EXCEPTION',
      body: {
        userId,
        lastAction,
      },
    })
    console.log('[updateActionUser-error]:', error)
    return {
      status: false,
      message: translateMessage(error.message),
    }
  }
}
