export const DEFAULT_MESSAGE =
  'Ops, ocorreu um erro, tente novamente mais tarde.'

export const MESSAGES = {
  'Firebase: Error (auth/user-not-found).':
    'Não encontramos uma conta vinculada a este email.',
  'Firebase: Error (auth/wrong-password).':
    'Sua senha está errada, por favor tente novamente.',
  'Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests).':
    'Máximo de tentativas excedido. Tente novamente mais tarde.',
  'Firebase: Error (auth/invalid-action-code).':
    'Sua solicitação para login expirou ou o link já foi usado, tente login novamente.',
  'User not found': 'Ops, ocorreu um erro, tente novamente mais tarde.',
  'Request failed with status code 401': 'Token invalido',
  "TypeError - Cannot read properties of undefined (reading 'toLowerCase')":
    DEFAULT_MESSAGE,
  USER_NOT_FOUND: 'Usuário não encontrado.',
  OTP_CODE_INVALID: 'Código digitado não corresponde ao enviado por e-mail.',
  OTP_CODE_EXPIRED: 'Código expirado.',
  OTP_CODE_NOT_GENERATED: 'Nenhum código gerado para esse usuário.',
}
