import React from 'react'

import * as S from './styled'

const Card = ({ title, description, image, onClick }) => {
  return (
    <S.Card>
      <img src={image} alt='' />
      <div>
        <h5>{title}</h5>
        <p>{description}</p>
        <hr />
        <button type='button' onClick={onClick}>
          Mais informações
          <svg
            width='14'
            height='12'
            viewBox='0 0 14 12'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{ marginLeft: 10 }}
          >
            <path
              d='M7.69748 10.5977C7.42079 10.8804 7.42079 11.3387 7.69748 11.6214C7.97417 11.9041 8.42278 11.9041 8.69947 11.6214L13.2882 6.93343C13.7927 6.41796 13.7927 5.58221 13.2882 5.06674L8.69947 0.378757C8.42278 0.0960784 7.97417 0.0960784 7.69748 0.378757C7.42079 0.661436 7.42079 1.11975 7.69748 1.40243L11.4893 5.27624L1.04177 5.27624C0.650465 5.27624 0.333252 5.60031 0.333252 6.00008C0.333252 6.39985 0.650465 6.72392 1.04177 6.72392L11.4893 6.72393L7.69748 10.5977Z'
              fill='#BD2469'
            />
          </svg>
        </button>
      </div>
    </S.Card>
  )
}

export default Card
