import styled from 'styled-components'

export const Container = styled.div``

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 20px;
  border-radius: 20px;
  background: #f7f7f7;
  margin-bottom: 20px;

  > p {
    color: #333333;
  }
`
