import styled from 'styled-components'

export const ErrorMessage = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;

  > p {
    font-size: 12px;
    color: red;
    margin: 0px;
  }
`
