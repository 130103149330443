import React from 'react'

import * as S from './styled'

const StepOne = ({ onClick }) => {
  return (
    <S.Container data-testid='step-one'>
      <h3>O que você deve saber antes de excluir sua conta</h3>
      <p>
        Antes de excluir a sua conta do Proteção PIX, cancele a sua assinatura.
        Caso contrário, você continuará recebendo cobranças.
      </p>
      <p>
        Ao excluir a sua conta no Proteção PIX, todas as informações da sua
        conta ficarão anônimas de forma que não estejam mais associadas aos seus
        dados cadastrais. Além disso, todas as ações de comunicação relacionadas
        ao Proteção PIX também serão canceladas.
      </p>
      <p>
        Se a sua assinatura for cobrada diretamente pelo Proteção PIX, após o
        cancelamento, você continuará utilizando o serviço até o final do
        período de cobrança atual. A exclusão da sua conta no Proteção PIX será
        processada após o término da assinatura.
      </p>
      <p>
        Se a sua assinatura for cobrada por uma empresa parceira, você deverá
        entrar em contato diretamente com a empresa para o cancelamento da
        assinatura. Ao prosseguir com o cancelamento da conta no aplicativo você
        perderá acesso imediatamente ao serviço, mesmo se ainda não tiver
        solicitado o cancelamento da assinatura no parceiro.
      </p>
      <S.Button>
        <button
          type='button'
          onClick={onClick}
          data-testid='button-next-step-one'
        >
          Próximo
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M14.0613 22.0607C13.4755 22.6464 12.5257 22.6464 11.9399 22.0607C11.3542 21.4749 11.3542 20.5251 11.9399 19.9393L18.1115 13.7678C18.2017 13.6776 18.2835 13.5822 18.3571 13.4827L3.00159 13.4989C2.17316 13.4998 1.50088 12.829 1.5 12.0005C1.49912 11.1721 2.16998 10.4998 2.99841 10.4989L18.331 10.4827C18.2643 10.3956 18.1912 10.3119 18.1115 10.2322L11.9399 4.06066C11.3542 3.47487 11.3542 2.52513 11.9399 1.93934C12.5257 1.35355 13.4755 1.35355 14.0613 1.93934L20.2328 8.11091C22.3807 10.2588 22.3807 13.7412 20.2328 15.8891L14.0613 22.0607Z'
              fill='#FFFFFF'
            />
          </svg>
        </button>
      </S.Button>
    </S.Container>
  )
}

export default StepOne
