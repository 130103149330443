import React from 'react'
import { useNavigate } from 'react-router-dom'

import CardAction from '@/components/Presentational/Cards/Action'
import IconClaims from '@/components/Presentational/Icons/IconClaims'
import colors from '@/styles/colors'

import * as S from './styled'

const OpenSinister = () => {
  const navigate = useNavigate()

  const handleClickRedirectPage = () => {
    navigate('/claims')
  }

  return (
    <S.Header>
      <S.Title>Solicitar reembolso</S.Title>
      <CardAction
        dataTestId='card-open-sinister'
        status
        type={'full-width'}
        colorTitle={colors.white}
        backgroundColor={colors.primary}
        onClick={() => handleClickRedirectPage()}
        title={'Nova solicitação'}
        iconLeft={<IconClaims />}
        button={
          <svg
            width='25'
            height='24'
            viewBox='0 0 25 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M12.5 4L12.5 20'
              stroke='white'
              strokeWidth='3'
              strokeLinecap='round'
            />
            <path
              d='M20.5 12L4.5 12'
              stroke='white'
              strokeWidth='3'
              strokeLinecap='round'
            />
          </svg>
        }
      />
    </S.Header>
  )
}

export default OpenSinister
