import React, { useState } from 'react'

import IconClose from '@/components/Presentational/Icons/IconClose'
import IconWarning from '@/components/Presentational/Icons/IconWarning'

import * as S from './styled'

const Banner = () => {
  const [alertInfo, setAlertInfo] = useState(true)

  if (!alertInfo) {
    return null
  }

  return (
    <S.Container data-testid='banner'>
      <S.Content>
        <IconWarning />
        <S.Description>
          <S.Paragraph>Antes de pedir seu reembolso é necessário:</S.Paragraph>
          <br />
          <S.Paragraph>1. Bloquear seu celular em nosso portal Web</S.Paragraph>
          <S.Paragraph>2. Bloquear o IMEI do celular na Anatel</S.Paragraph>
        </S.Description>
      </S.Content>
      <IconClose onClick={() => setAlertInfo(false)} />
    </S.Container>
  )
}

export default Banner
