import React from 'react'

import { useForm } from '@/components/Containers/Claims/contexts/Forms'
import Collapse from '@/components/Presentational/Collapsible/Collapse'
import { useCollapse } from '@/components/Presentational/Collapsible/contexts/Collapse'
import Item from '@/components/Presentational/Collapsible/Item'
import { Small } from '@/styles/fonts'
import moment from 'moment'

import * as S from './styled'

const validateForms = (
  sinisterType,
  sinisterDate,
  sinisterTime,
  eventDescription
) => {
  const validate = [sinisterDate, sinisterTime, eventDescription].filter(
    (item) => item !== ''
  ).length

  return validate === 0 ? 'PENDING' : validate === 3 ? 'SUCCESS' : 'ANALYSIS'
}

const OccurrenceSummaryItem = () => {
  const {
    spinner,
    formData: { sinisterType, sinisterDate, sinisterTime, eventDescription },
  } = useForm()
  const { collapse, setCollapse } = useCollapse()

  return (
    <Item
      spinner={spinner}
      status={validateForms(
        sinisterType,
        sinisterDate,
        sinisterTime,
        eventDescription
      )}
      title='Resumo da ocorrência'
      dataTestId='button-occurrence-summary'
      onClick={() =>
        setCollapse((prev) => ({
          ...prev,
          occurrenceSummary: !collapse.occurrenceSummary,
        }))
      }
    />
  )
}

const OccurrenceSummaryForm = () => {
  const {
    formData: { sinisterType, sinisterDate, sinisterTime, eventDescription },
    updateFormData,
    spinner,
  } = useForm()
  const { collapse, setCollapse } = useCollapse()

  const handleChange = (e) => {
    const { name, value } = e.target
    updateFormData({ [name]: value })
  }

  return (
    <Collapse
      spinner={spinner}
      isOpen={collapse.occurrenceSummary}
      status={validateForms(
        sinisterType,
        sinisterDate,
        sinisterTime,
        eventDescription
      )}
      title='Resumo da ocorrência'
      subTitle='Para iniciar sua solicitação, precisamos entender o que aconteceu e onde aconteceu a ocorrência para garantir a segurança da operação de reembolso.'
      onClick={() =>
        setCollapse((prev) => ({
          ...prev,
          occurrenceSummary: !collapse.occurrenceSummary,
        }))
      }
    >
      <S.Form data-testid='form-occurrence-summary'>
        <div>
          <label>
            Tipo da ocorrência
            <input
              type='text'
              name='sinisterType'
              value={sinisterType}
              onChange={handleChange}
              disabled
            />
          </label>
          <Small>
            Atualmente, o reembolso é valido somente para ocorrências de
            transações financeiras
          </Small>
        </div>
        <div>
          <label>
            Data da ocorrência
            <input
              type='date'
              data-testid='sinisterDate'
              name='sinisterDate'
              value={sinisterDate}
              onChange={handleChange}
              max={moment().format('YYYY-MM-DD')}
            />
          </label>
          <Small>
            Essa é a data mapeada da última ação de bloqueio realizada. Se a
            informação estiver errada, é só alterar.
          </Small>
        </div>
        <div>
          <label>
            Hora da ocorrência
            <input
              type='time'
              data-testid='sinisterTime'
              name='sinisterTime'
              value={sinisterTime}
              onChange={handleChange}
            />
          </label>
          <Small>
            Essa é a hora mapeada da última ação de bloqueio realizada. Se a
            informação estiver errada, é só alterar.
          </Small>
        </div>
        <div>
          <label>
            Detalhes do ocorrência
            <textarea
              data-testid='eventDescription'
              value={eventDescription}
              name='eventDescription'
              placeholder='Conte-nos o que aconteceu, sem esquecer de
            citar como e onde foi, dentre outros detalhes'
              onChange={handleChange}
              cols={30}
              rows={9}
              maxLength={500}
              required
            />
          </label>
          <span>
            {eventDescription.length}/{500}
          </span>
        </div>
      </S.Form>
    </Collapse>
  )
}

export { OccurrenceSummaryItem, OccurrenceSummaryForm }
