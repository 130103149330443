import React from 'react'

import { useForm } from '@/components/Containers/Claims/contexts/Forms'
import SelectLocation from '@/components/Containers/SelectLocation'
import Collapse from '@/components/Presentational/Collapsible/Collapse'
import { useCollapse } from '@/components/Presentational/Collapsible/contexts/Collapse'
import Item from '@/components/Presentational/Collapsible/Item'
import InputGroup from '@/components/Presentational/Inputs/InputGroup'
import { useDebounce } from '@/hooks/useDebounce'
import { getAddressByZipCode } from '@/services/APIService/Utilities'

import { REPORTING_PERSONS, RELATIONS } from '../../constants'
import * as S from './styled'

const validateForms = (
  typePerson,
  ownerName,
  ownerEmail,
  ownerAddressName,
  ownerAddressNumber,
  ownerAddressState,
  ownerAddressCity,
  ownerCpf,
  ownerPhone,
  ownerAddressDistrict,
  onwerAddressZipcode,
  ownerRelationship
) => {
  const validate = [
    typePerson,
    ownerName,
    ownerEmail,
    ownerAddressName,
    ownerAddressNumber,
    ownerAddressState,
    ownerAddressCity,
    ownerCpf,
    ownerPhone,
    ownerAddressDistrict,
    onwerAddressZipcode,
    ownerRelationship,
  ].filter((item) => item !== '').length

  return validate === 0
    ? 'PENDING'
    : typePerson === 'Fisica'
      ? 'SUCCESS'
      : validate === 12
        ? 'SUCCESS'
        : 'ANALYSIS'
}

const ContactsItem = () => {
  const { collapse, setCollapse } = useCollapse()
  const {
    formData: {
      typePerson,
      ownerName,
      ownerEmail,
      ownerAddressName,
      ownerAddressNumber,
      ownerAddressState,
      ownerAddressCity,
      ownerCpf,
      ownerPhone,
      ownerAddressDistrict,
      onwerAddressZipcode,
      ownerRelationship,
    },
    spinner,
  } = useForm()

  return (
    <Item
      spinner={spinner}
      status={validateForms(
        typePerson,
        ownerName,
        ownerEmail,
        ownerAddressName,
        ownerAddressNumber,
        ownerAddressState,
        ownerAddressCity,
        ownerCpf,
        ownerPhone,
        ownerAddressDistrict,
        onwerAddressZipcode,
        ownerRelationship
      )}
      title='Contato'
      dataTestId='button-contacts'
      onClick={() =>
        setCollapse((prev) => ({
          ...prev,
          contacts: !collapse.contacts,
        }))
      }
    />
  )
}

const ContactsForm = () => {
  const { collapse, setCollapse } = useCollapse()
  const {
    formData: {
      typePerson,
      ownerName,
      ownerEmail,
      ownerAddressName,
      ownerAddressNumber,
      ownerAddressState,
      ownerAddressCity,
      ownerAddressComplement,
      ownerCpf,
      ownerPhone,
      ownerAddressDistrict,
      onwerAddressZipcode,
      ownerRelationship,
    },
    updateFormData,
    spinner,
  } = useForm()
  const getAddress = useDebounce(async (zipCode) => {
    if (zipCode.length < 8) {
      return null
    }

    const response = await getAddressByZipCode(zipCode)

    if (!response.status) {
      return
    }

    updateFormData({
      onwerAddressZipcode: response.data.zipCode,
      ownerAddressName: response.data.address,
      ownerAddressComplement: response.data.complement,
      ownerAddressDistrict: response.data.neighborhood,
      ownerAddressState: response.data.state,
      ownerAddressCity: response.data.city,
    })
  }, 1000)

  const handleChange = (e) => {
    const { name, value } = e.target

    updateFormData({ [name]: value })
    if (name === 'onwerAddressZipcode') getAddress(value)
  }

  return (
    <Collapse
      spinner={spinner}
      isOpen={collapse.contacts}
      title='Contato'
      subTitle='Precisamos de algumas informações sobre a abertura de sinistro para garantir a segurança da solicitação.'
      onClick={() =>
        setCollapse((prev) => ({
          ...prev,
          contacts: !collapse.contacts,
        }))
      }
      status={validateForms(
        typePerson,
        ownerName,
        ownerEmail,
        ownerAddressName,
        ownerAddressNumber,
        ownerAddressState,
        ownerAddressCity,
        ownerCpf,
        ownerPhone,
        ownerAddressDistrict,
        onwerAddressZipcode,
        ownerRelationship
      )}
    >
      <S.Form data-testid='form-contacts'>
        <span>Quem está abrindo o sinistro?</span>
        {REPORTING_PERSONS.map((item, key) => (
          <S.LabelItem key={key}>
            <input
              type='radio'
              name='typePerson'
              value={item.value}
              checked={typePerson === item.value}
              onChange={handleChange}
            />
            {item.label}
          </S.LabelItem>
        ))}
        {(typePerson === 'Representante Fisica' ||
          typePerson === 'Representante Juridíca') && (
          <React.Fragment>
            <label>
              Relação com a pessoa titular
              <select
                name='ownerRelationship'
                onChange={handleChange}
                data-testid='ownerRelationship'
                value={ownerRelationship}
              >
                <option value=''>Qual sua relação com o titular?</option>
                {RELATIONS.map((item) => (
                  <option key={item.id} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </label>
            <InputGroup
              label='Nome da solicitante'
              type='text'
              dataTestId='ownerName'
              name='ownerName'
              value={ownerName}
              onChange={handleChange}
            />

            <InputGroup
              label='E-mail'
              type='text'
              dataTestId='ownerEmail'
              name='ownerEmail'
              value={ownerEmail}
              onChange={handleChange}
            />
            <InputGroup
              label='CPF ou CNPJ'
              type='text'
              dataTestId='ownerCpf'
              name='ownerCpf'
              value={ownerCpf}
              onChange={handleChange}
            />
            <InputGroup
              label='Telefone'
              type='text'
              dataTestId='ownerPhone'
              name='ownerPhone'
              value={ownerPhone}
              onChange={handleChange}
            />
            <InputGroup
              label='CEP'
              type='text'
              dataTestId='onwerAddressZipcode'
              name='onwerAddressZipcode'
              value={onwerAddressZipcode}
              onChange={handleChange}
            />

            <InputGroup
              label='Endereço'
              type='text'
              dataTestId='ownerAddressName'
              name='ownerAddressName'
              value={ownerAddressName}
              onChange={handleChange}
            />
            <InputGroup
              label='Número'
              type='text'
              dataTestId='ownerAddressNumber'
              name='ownerAddressNumber'
              value={ownerAddressNumber}
              onChange={handleChange}
            />
            <InputGroup
              label='Complemento'
              type='text'
              dataTestId='ownerAddressComplement'
              name='ownerAddressComplement'
              value={ownerAddressComplement}
              onChange={handleChange}
            />
            <InputGroup
              label='Bairro'
              type='text'
              dataTestId='ownerAddressDistrict'
              name='ownerAddressDistrict'
              value={ownerAddressDistrict}
              onChange={handleChange}
            />

            <SelectLocation
              stateName='ownerAddressState'
              stateValue={ownerAddressState}
              cityName='ownerAddressCity'
              cityValue={ownerAddressCity}
              onChange={(e) => {
                updateFormData({
                  [e.name]: e.value,
                })
              }}
            />
          </React.Fragment>
        )}
      </S.Form>
    </Collapse>
  )
}

export { ContactsItem, ContactsForm }
