import React from 'react'

import { useAuth } from '@/contexts/Authentication'
import { createDate } from '@/utils/Mask/Date'

const MyPhone = () => {
  const {
    user: {
      profile: {
        imei,
        last_login: { seconds },
      },
      mobile: { device_name, device_brand, device_model },
    },
  } = useAuth()

  return (
    <React.Fragment>
      <div className='title-section'>
        <div className='group'>
          <p>Meu celular</p>
        </div>
      </div>
      <ul className='list-items'>
        {device_brand && (
          <li>
            <p>Aparelho</p>
            <p>{`${device_brand}, ${device_name}, ${device_model}`}</p>
          </li>
        )}
        {imei && (
          <li>
            <p>Código IMEI</p>
            <p>{imei}</p>
          </li>
        )}
        <li>
          <p>Último acesso no aplicativo</p>
          <p>{seconds !== null ? createDate(seconds) : '--'}</p>
        </li>
      </ul>
    </React.Fragment>
  )
}
export default MyPhone
