export const moneyMask = (value) => {
  if (!value || typeof value === 'string') {
    return ''
  }

  return Intl.NumberFormat('pt-br', {
    style: 'currency',
    currency: 'BRL',
  }).format(value)
}
