import { APP_MAPS_API_KEY, URL_GOOGLE_GEOCODER } from '@/constants'
import axios from '@/services/BaseService'
import { captureException } from '@/utils/Instrumentation/Exception'
import { translateMessage } from '@/utils/Translator'

export async function getAddress(lat, long) {
  const endpoint = `${URL_GOOGLE_GEOCODER}?latlng=${Number(lat)},${Number(
    long
  )}&key=${APP_MAPS_API_KEY}`
  try {
    const response = await axios.get(endpoint, {
      headers: {
        external: true,
      },
    })

    const { address_components } = response.results.shift()

    return { status: true, address: address_components }
  } catch (error) {
    captureException({
      error,
      tag: 'GET_ADDRESS_BY_LAT_LONG_EXCEPTION',
      endpoint,
      body: {
        lat,
        long,
      },
    })

    return {
      status: false,
      message: translateMessage(error.message),
    }
  }
}

export async function getCep(cep) {
  const endpoint = `/v1/cep/${cep}`
  try {
    const response = await axios.get(endpoint)

    return { status: true, data: response }
  } catch (error) {
    captureException({
      error,
      tag: 'GET_CEP_EXCEPTION',
      endpoint,
      body: {
        cep,
      },
    })
    return {
      status: false,
      message: translateMessage(error.message),
    }
  }
}

export async function getAddressByZipCode(zipCode) {
  const endpoint = `https://viacep.com.br/ws/${zipCode}/json/`
  try {
    const response = await axios.get(endpoint)

    const address = {
      zipCode: response.cep,
      address: response.logradouro,
      complement: response.complemento,
      neighborhood: response.bairro,
      city: response.localidade,
      state: response.uf,
      uf: response.uf,
      ddd: response.ddd,
    }

    return { status: true, data: address }
  } catch (error) {
    captureException({
      error,
      tag: 'GET_ADDRESS_BY_ZIP_CODE_EXCEPTION',
      endpoint,
      body: {
        zipCode,
      },
    })

    return { status: false }
  }
}

export async function getState() {
  const endpoint = 'https://servicodados.ibge.gov.br/api/v1/localidades/estados'

  try {
    const response = await axios.get(endpoint)
    return { status: true, data: response }
  } catch (error) {
    captureException({
      error,
      tag: 'GET_STATE_EXCEPTION',
      endpoint,
    })

    return { status: false }
  }
}

export async function getCity(state) {
  const endpoint = `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/distritos`
  try {
    const response = await axios.get(endpoint)

    return { status: true, data: response }
  } catch (error) {
    captureException({
      error,
      tag: 'GET_CITY_BY_STATE_EXCEPTION',
      endpoint,
    })

    return { status: false }
  }
}
