import colors from '@/styles/colors'

import styled from 'styled-components'

export const Ul = styled.ul`
  display: flex;
  flex-direction: row;

  li {
    list-style-type: none;
    margin-right: 10px;
  }
`

export const Li = styled.li`
  list-style-type: none;
  margin-right: 10px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 5px;
`

export const Paragraph = styled.p`
  font-size: 14px;
`

export const Link = styled.small`
  font-size: 14px;
  color: ${colors.primary};
  cursor: pointer;
  text-decoration: underline;
`

export const Dropdown = styled.div`
  position: absolute;
  top: 70px;
  width: 400px;
  right: 20px;
  border-radius: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  z-index: 99999999;
`

export const DropdownContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`

export const Icon = styled.div`
  position: absolute;
  top: -22px;
  right: 132px;
`

export const Main = styled.main`
  margin-top: 20px;
  gap: 30px;
`
