export const UPLOAD_FIELS = [
  {
    label: 'CPF',
    field: 'cpfFiles',
    title:
      'Você pode anexar apenas a CNH e, caso opte pelo RG, é necessário anexar uma imagem do CPF também.',
  },
  {
    label: 'Comprovante de residência atualizado',
    field: 'residencyProof',
  },
  {
    label: 'Boletim de ocorrência policial',
    field: 'policeReport',
    title:
      'Para solicitação de reembolso, você precisa anexar o documento do boletim de ocorrência relacionado.',
  },
  {
    label: 'RG ou CNH',
    field: 'cnhFiles',
    title:
      'Você pode anexar apenas a CNH e, caso opte pelo RG, é necessário anexar uma imagem do CPF também.',
  },
  {
    label: 'Comprovante(s) da(s) tranferência(s)',
    field: 'invoiceFiles',
    title:
      'Anexe aqui prints dos comprovantes das transações fraudulentas realizadas nas suas contas.',
  },
  {
    label: 'Print do bloqueio no iCloud',
    field: 'iCloudScreen',
  },
]

export const REPORTING_PERSONS = [
  {
    id: 1,
    value: 'Fisica',
    label: 'A pessoa titular (o seguro está em meu nome)',
  },
  {
    id: 2,
    value: 'Representante Fisica',
    label: 'Não sou titular (Represento uma Pessoa Física)',
  },
  {
    id: 3,
    value: 'Representante Juridíca',
    label: 'Não sou titular (Represento Pessoa Jurídica)',
  },
]

export const RELATIONS = [
  { id: 1, value: 'Advogado(a)', label: 'Advogado(a)' },
  { id: 2, value: 'Amigo(a)', label: 'Amigo(a)' },
  { id: 3, value: 'Cônjuge', label: 'Cônjuge' },
  { id: 4, value: 'Cunhado(a)', label: 'Cunhado(a)' },
  { id: 5, value: 'Filho(a)', label: 'Filho(a)' },
  { id: 6, value: 'Genro', label: 'Genro' },
  { id: 7, value: 'Irmão(ã)', label: 'Irmão(ã)' },
  { id: 8, value: 'Mãe', label: 'Mãe' },
  { id: 9, value: 'Neto(a)', label: 'Neto(a)' },
  { id: 10, value: 'Nora', label: 'Nora' },
  { id: 11, value: 'Pai', label: 'Pai' },
  { id: 12, value: 'Procurador(a)', label: 'Procurador(a)' },
  { id: 13, value: 'Secretária', label: 'Secretária' },
  { id: 14, value: 'Sobrinho(a)', label: 'Sobrinho(a)' },
  { id: 15, value: 'Sogro(a)', label: 'Sogro(a)' },
  { id: 16, value: 'Tio(a)', label: 'Tio(a)' },
]

export const ACCOUNT_TYPE = [
  { id: 1, value: 'ContaCorrente', label: 'Conta Corrente' },
  { id: 2, value: 'Poupanca', label: 'Poupança' },
]
