import React from 'react'
import { useNavigate } from 'react-router-dom'

import CardAction from '@/components/Presentational/Cards/Action'
import CardDisabled from '@/components/Presentational/Cards/Disabled'
import IconArrow from '@/components/Presentational/Icons/IconArrow'
import IconCheckIn from '@/components/Presentational/Icons/IconCheckIn'
import IconClaims from '@/components/Presentational/Icons/IconClaims'
import IconClock from '@/components/Presentational/Icons/IconClock'
import IconDraw from '@/components/Presentational/Icons/IconDraw'
import IconLoader from '@/components/Presentational/Icons/IconLoader'
import IconPencil from '@/components/Presentational/Icons/IconPencil'
import colors from '@/styles/colors'

const Card = ({ deviceOS, disabled, isLoader, isDraft, onClick }) => {
  const navigate = useNavigate()

  const handleClickRedirectPage = () => {
    navigate('/claims')
  }

  if (disabled) {
    return (
      <CardDisabled
        dataTestId='card-disabled-request-refund'
        type={deviceOS === 'ios' ? 'full-width' : 'no-full-width'}
        title={'Solicitar reembolso'}
        iconLeft={<IconClaims color={colors.disabled2} />}
        iconRight={<IconClock />}
        button={<IconArrow color={colors.disabled2} />}
      />
    )
  }

  if (isDraft) {
    return (
      <CardAction
        dataTestId='card-draft-request-refund'
        status={isLoader ? null : false}
        title={'Reembolso'}
        subTitle={'Rascunho'}
        type={deviceOS === 'ios' ? 'full-width' : 'no-full-width'}
        colorTitle={colors.text}
        backgroundColor={colors.green}
        onClick={() => handleClickRedirectPage()}
        iconLeft={<IconDraw />}
        iconRight={isLoader && <IconLoader />}
        button={<IconPencil />}
      />
    )
  }

  return (
    <CardAction
      dataTestId='card-request-refund'
      status={isLoader ? null : false}
      title={'Solicitar reembolso'}
      type={deviceOS === 'ios' ? 'full-width' : 'no-full-width'}
      colorTitle={colors.white}
      backgroundColor={colors.primary}
      onClick={onClick}
      iconLeft={<IconClaims />}
      iconRight={isLoader ? <IconLoader /> : <IconCheckIn />}
      button={<IconArrow color={colors.white} />}
    />
  )
}

export default Card
