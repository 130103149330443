import styled from 'styled-components'

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  gap: 30px;

  > small {
    font-size: 13px;
  }

  > label {
    font-size: 16px;

    > input {
      margin-right: 10px;
    }
  }
`
