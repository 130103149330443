import React from 'react'

const IconChevronUp = ({
  onClick,
  width = 24,
  height = 24,
  color = '#121212',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      cursor='pointer'
      data-testid='icon-chevron-up'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.7321 15.7666C18.3898 16.0918 17.8513 16.0751 17.5294 15.7294L11.9968 9.78857L6.47089 15.7277C6.14908 16.0736 5.6106 16.0905 5.26817 15.7654C4.92574 15.4404 4.90902 14.8965 5.23083 14.5506L10.8661 8.49393C11.4787 7.83556 12.5141 7.83532 13.1269 8.49341L18.7689 14.5518C19.0909 14.8975 19.0744 15.4414 18.7321 15.7666Z'
        fill={color}
      />
    </svg>
  )
}

export default IconChevronUp
