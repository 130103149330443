import colors from './colors'

import styled from 'styled-components'

const Paragraph = styled.p`
  color: ${colors.text};
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
`

const Title = styled.h4`
  color: ${colors.text};
  font-size: 18px;
  font-family: Lexend;
`

const Small = styled.small`
  color: ${colors.text};
  font-size: 12px;
`

const Span = styled.span`
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: ${colors.gray2};
`

const Strong = styled.strong`
  color: ${colors.text};
  font-size: 16px;
  font-family: Lexend-Bold;
`

export { Paragraph, Title, Small, Span, Strong }
