import React from 'react'

import * as S from './styled'

const CardDisabled = ({
  dataTestId,
  iconLeft,
  type,
  iconRight,
  title,
  subTitle,
  button,
}) => {
  return (
    <S.Container type={type} data-testid={dataTestId}>
      <S.Icon>{iconLeft}</S.Icon>
      <S.Status>{iconRight}</S.Status>
      <S.Text>
        <S.Description>
          {subTitle && <S.SubTitle>{subTitle}</S.SubTitle>}
          <S.Title>{title}</S.Title>
        </S.Description>
      </S.Text>
      <S.Button>{button}</S.Button>
    </S.Container>
  )
}

export default CardDisabled
