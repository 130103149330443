import React from 'react'

import * as S from './styled'

const CardAction = ({
  dataTestId,
  backgroundColor,
  colorTitle,
  type,
  status,
  onClick,
  iconLeft,
  iconRight,
  title,
  subTitle,
  button,
  style,
}) => {
  return (
    <S.Container
      data-testid={dataTestId}
      status={status}
      onClick={onClick}
      type={type}
      backgroundColor={backgroundColor}
      style={style}
    >
      <S.Icon>{iconLeft}</S.Icon>
      <S.Status>{iconRight}</S.Status>
      <S.Text>
        <S.Description>
          {subTitle && <S.SubTitle>{subTitle}</S.SubTitle>}
          <S.Title colorTitle={colorTitle}>{title}</S.Title>
        </S.Description>
      </S.Text>
      <S.Button>{button}</S.Button>
    </S.Container>
  )
}

export default CardAction
