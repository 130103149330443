import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
`

export const Items = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  border: 1px solid hsla(0, 0%, 90%, 1);
  border-radius: 24px;
  box-shadow: 0px 0px 8px hsla(0, 0%, 0%, 0.08);

  > h3 {
    font-size: 1rem;
    margin-bottom: 0px;
    color: #121212;
  }

  > p {
    font-size: 14px;
    margin-bottom: 0px;
    color: #383838;
  }
`

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`
