export function phoneMask(item) {
  if (!item) return ''

  let phone = item.replace(/[^0-9]+/g, '')

  if (phone.length > 11) return ''

  phone = phone.replace(/\D/g, '')
  phone = phone.replace(/(\d{2})(\d)/, '($1) $2')
  phone = phone.replace(/(\d)(\d{4})$/, '$1-$2')
  return phone
}
