import React from 'react'

import Grid from '@/components/Presentational/Grid'

import * as S from './styled'

const Drawer = ({ children, onClick }) => {
  return (
    <Grid>
      <S.Container>
        <S.Button type='button' onClick={onClick} data-testid='button-go-back'>
          <svg
            data-test
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M9.93934 22.0607C10.5251 22.6464 11.4749 22.6464 12.0607 22.0607C12.6464 21.4749 12.6464 20.5251 12.0607 19.9393L5.88909 13.7678C5.80416 13.6828 5.72662 13.5933 5.65646 13.5L21 13.5C21.8284 13.5 22.5 12.8284 22.5 12C22.5 11.1716 21.8284 10.5 21 10.5L5.65646 10.5C5.72661 10.4067 5.80416 10.3172 5.88909 10.2322L12.0607 4.06066C12.6465 3.47487 12.6465 2.52513 12.0607 1.93934C11.4749 1.35355 10.5251 1.35355 9.93935 1.93934L3.76777 8.11091C1.61988 10.2588 1.61988 13.7412 3.76777 15.8891L9.93934 22.0607Z'
              fill='#1F1F1F'
            />
          </svg>
          Voltar
        </S.Button>
      </S.Container>
      <S.Title>
        <svg
          width='24'
          height='24'
          viewBox='0 0 56 56'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M42 21.875V42C42 45.866 38.866 49 35 49H21C17.134 49 14 45.866 14 42V21.875'
            stroke='#004BC1'
            strokeWidth={5}
            strokeLinecap='round'
          />
          <path
            d='M46.375 14H28M9.625 14H28M28 14V7'
            stroke='#004BC1'
            strokeWidth={5}
            strokeLinecap='round'
          />
        </svg>
        <p>Apagar todos os dados</p>
      </S.Title>
      <S.Description>{children}</S.Description>
    </Grid>
  )
}

export default Drawer
