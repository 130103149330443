import React from 'react'

const IconSearch = ({
  onClick,
  width = 25,
  height = 24,
  color = '#7B7B7B',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      cursor='pointer'
      onClick={onClick}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.8342 14.7489C12.7592 15.5356 11.4336 16 9.99951 16C6.40966 16 3.49951 13.0899 3.49951 9.5C3.49951 5.91015 6.40966 3 9.99951 3C13.5894 3 16.4995 5.91015 16.4995 9.5C16.4995 10.9341 16.0351 12.2597 15.2484 13.3347L21.2066 19.2929C21.5971 19.6834 21.5971 20.3166 21.2066 20.7071C20.8161 21.0976 20.1829 21.0976 19.7924 20.7071L13.8342 14.7489ZM14.4995 9.5C14.4995 11.9853 12.4848 14 9.99951 14C7.51423 14 5.49951 11.9853 5.49951 9.5C5.49951 7.01472 7.51423 5 9.99951 5C12.4848 5 14.4995 7.01472 14.4995 9.5Z'
        fill={color}
      />
    </svg>
  )
}

export default IconSearch
