import React from 'react'

import * as S from './styled'

const Information = () => {
  return (
    <S.Container data-testid='information'>
      <S.Divider />
      <S.Icon>
        <svg
          width='24'
          height='25'
          viewBox='0 0 24 25'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M12 23.5C5.92487 23.5 1 18.5751 1 12.5C1 6.42487 5.92487 1.5 12 1.5C18.0751 1.5 23 6.42487 23 12.5C23 18.5751 18.0751 23.5 12 23.5ZM12.0003 5.87778C11.448 5.87778 11.0003 6.32549 11.0003 6.87778V6.99997C11.0003 7.55216 11.4479 7.99983 12.0001 7.99997L12.1218 8C12.3871 8.00006 12.6415 7.89473 12.8291 7.70719C13.0167 7.51965 13.1221 7.26526 13.1221 7V6.87778C13.1221 6.32549 12.6744 5.87778 12.1221 5.87778H12.0003ZM13.0609 11C13.0609 10.4477 12.6132 10 12.0609 10C11.5086 10 11.0609 10.4477 11.0609 11V18.5C11.0609 19.0523 11.5086 19.5 12.0609 19.5C12.6132 19.5 13.0609 19.0523 13.0609 18.5V11Z'
            fill='#0069C2'
          />
        </svg>
      </S.Icon>
      <S.Content>
        <S.Text>
          Para usar as funcionalidades à distância, <br />
          faça login ou instale novamente o app <br />
          Proteção Pix no seu celular.
        </S.Text>
      </S.Content>
    </S.Container>
  )
}

export default Information
