import React from 'react'

import Spinner from '@/components/Presentational/Spinner'

import * as S from './styled'

const Loader = () => {
  return (
    <React.Fragment>
      <div />
      <S.Spinner>
        <Spinner />
      </S.Spinner>
    </React.Fragment>
  )
}

export default Loader
