import styled from 'styled-components'

const handleStatusType = (status) => {
  switch (status) {
    case 'pending':
      return '#FF3724'
    case 'analysis':
      return '#FFD43B'
    case 'info':
      return '#E3E3E3'
    default:
      return '#B8FF06'
  }
}

export const Status = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
  border: 1px solid hsla(0, 0%, 90%, 1);
  border-radius: 24px;
  box-shadow: 0px 0px 8px hsla(0, 0%, 0%, 0.08);
  cursor: pointer;
`

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid hsla(201, 13%, 88%, 1);

  > div > h3 {
    font-size: 1rem;
    margin-bottom: 0px;
    color: #121212;
  }

  > div > p {
    font-size: 13px;
    margin-top: 5px;
    margin-bottom: 0px;
  }
`

export const Label = styled.label`
  padding: 0px 10px;
  color: #000000;
  background-color: ${({ status }) => handleStatusType(status)};
  border-radius: 20px;
  font-size: 12px;
  width: 85px;
`
