import React from 'react'

import { useForm } from '@/components/Containers/Claims/contexts/Forms'
import IconLoader from '@/components/Presentational/Icons/IconLoader'
import moment from 'moment'

import * as S from './styled'

const AutoSave = () => {
  const { isSaving } = useForm()

  if (isSaving === null) {
    return null
  }

  if (isSaving) {
    return (
      <S.Header>
        <IconLoader />
      </S.Header>
    )
  }

  return (
    <S.Header data-testid='auto-save'>
      <S.Title>
        <span>{moment().format('[Salvo às] HH:mm [em] DD/MM/YYYY')}</span>
        <S.LabelStatus>
          <p>Salvo</p>
          <svg
            width='15'
            height='8'
            viewBox='0 0 11 8'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M9.98646 0.818919C10.2153 1.03252 10.2278 1.39128 10.0142 1.62021L5.03124 6.96222C4.55975 7.46769 3.75482 7.45479 3.29975 6.93449L0.973464 4.27469C0.767353 4.03903 0.791257 3.68086 1.02686 3.4747C1.26245 3.26853 1.62053 3.29245 1.82664 3.52811L4.15293 6.1879C4.16593 6.20276 4.18893 6.20313 4.2024 6.18869L9.18537 0.846679C9.39892 0.617742 9.75758 0.605314 9.98646 0.818919Z'
              fill='#FDFDFD'
            />
          </svg>
        </S.LabelStatus>
      </S.Title>
    </S.Header>
  )
}

export default AutoSave
