import React from 'react'

import loading from '@/assets/img/loading.gif'
import IconChevronRight from '@/components/Presentational/Icons/IconChevronRight'

import * as S from './styled'

const StepTwo = ({
  items,
  onClick,
  onClickGoBack,
  onClickTakePicture,
  onClickDeletePhone,
}) => {
  return (
    <React.Fragment>
      <S.Container>
        <S.Button type='button' onClick={onClickGoBack}>
          <svg
            data-test
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M9.93934 22.0607C10.5251 22.6464 11.4749 22.6464 12.0607 22.0607C12.6464 21.4749 12.6464 20.5251 12.0607 19.9393L5.88909 13.7678C5.80416 13.6828 5.72662 13.5933 5.65646 13.5L21 13.5C21.8284 13.5 22.5 12.8284 22.5 12C22.5 11.1716 21.8284 10.5 21 10.5L5.65646 10.5C5.72661 10.4067 5.80416 10.3172 5.88909 10.2322L12.0607 4.06066C12.6465 3.47487 12.6465 2.52513 12.0607 1.93934C11.4749 1.35355 10.5251 1.35355 9.93935 1.93934L3.76777 8.11091C1.61988 10.2588 1.61988 13.7412 3.76777 15.8891L9.93934 22.0607Z'
              fill='#1F1F1F'
            />
          </svg>
          Voltar
        </S.Button>
      </S.Container>
      <S.Title>
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect x='10' y='6' width='4' height='13' fill='#FCFCFC' />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M18.0618 22H5.93748H5.93732C3.66385 22 2.52726 22 1.87012 21.5226C1.29691 21.1061 0.927089 20.4659 0.853028 19.7613C0.76812 18.9534 1.33635 17.9691 2.47314 16.0001L8.5353 5.50012L8.53678 5.49754L8.53679 5.49754C9.67258 3.53029 10.2406 2.54638 10.9824 2.21614C11.6296 1.92795 12.3691 1.92795 13.0163 2.21614C13.7584 2.54653 14.327 3.53115 15.4638 5.50012L21.526 16.0001C22.6628 17.9691 23.231 18.9534 23.1461 19.7613C23.072 20.4659 22.7022 21.1061 22.129 21.5226C21.4718 22 20.3354 22 18.062 22H18.0618ZM13.0615 8.25005C13.0615 7.69776 12.6137 7.25005 12.0615 7.25005C11.5092 7.25005 11.0615 7.69776 11.0615 8.25005V13.25C11.0615 13.8023 11.5092 14.25 12.0615 14.25C12.6137 14.25 13.0615 13.8023 13.0615 13.25V8.25005ZM11.9992 16C11.4469 16 10.9992 16.4477 10.9992 17V17.125C10.9992 17.6772 11.4468 18.1248 11.999 18.125L12.1235 18.125C12.3887 18.1251 12.6431 18.0198 12.8307 17.8322C13.0183 17.6447 13.1237 17.3903 13.1237 17.125V17C13.1237 16.4477 12.676 16 12.1237 16H11.9992Z'
            fill='#CF7100'
          />
        </svg>
        <p>Botão de pânico</p>
      </S.Title>
      <S.Description>
        <section
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <p>
              O modo de segurança foi ativado. Abaixo você pode identificar as
              proteções ativas.
            </p>
            <ul
              style={{
                display: 'flex',
                flexDirection: 'column',
                listStyleType: 'none',
                gap: 30,
                marginTop: 60,
              }}
            >
              {items.map((item, key) => (
                <li
                  key={key}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginLeft: 0,
                  }}
                >
                  <strong>{item.title}</strong>

                  {item.checked && !item.chevronRight ? (
                    <svg
                      width='20'
                      height='20'
                      viewBox='0 0 25 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <circle
                        cx='12.5'
                        cy='12'
                        r='10.75'
                        fill='#123382'
                        stroke='#FCFCFC'
                        strokeWidth='1.5'
                      />
                      <path
                        d='M17.25 10L12.3321 14.9179C11.9416 15.3084 11.3084 15.3084 10.9179 14.9179L8.5 12.5'
                        stroke='#FDFDFD'
                        strokeWidth='2'
                        strokeLinecap='round'
                      />
                    </svg>
                  ) : item.checked === null ? (
                    <img
                      src={loading}
                      alt='loading'
                      style={{ width: '25px' }}
                    />
                  ) : item.checked && item.chevronRight ? (
                    <S.Button
                      type='button'
                      onClick={onClickDeletePhone}
                      data-testid='modal-clear-data'
                    >
                      <IconChevronRight />
                    </S.Button>
                  ) : (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='20'
                      height='20'
                      viewBox='0 0 24 24'
                      fill='none'
                    >
                      <circle
                        cx='12'
                        cy='12'
                        r='10.75'
                        fill='#C42137'
                        stroke='#FCFCFC'
                        strokeWidth='1.5'
                      />
                      <path
                        d='M11.9941 16L11.9987 16'
                        stroke='#FCFCFC'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M12 8L12 13.3333'
                        stroke='#FCFCFC'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  )}
                </li>
              ))}
            </ul>
          </div>

          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                marginBottom: 10,
              }}
            >
              <button
                onClick={onClickGoBack}
                type='button'
                style={{
                  background: 'none',
                  color: '#004BC1',
                  border: 'none',
                }}
              >
                Acessar rastreio
              </button>
              <button
                onClick={onClickTakePicture}
                type='button'
                style={{
                  background: 'none',
                  color: '#004BC1',
                  border: 'none',
                }}
              >
                Acessar fotos
              </button>
            </div>
            <button
              type='button'
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                border: '2px solid black',
                background: '#B3B3B3',
                color: 'black',
              }}
              onClick={onClick}
            >
              Desativar modo de segurança
              <svg
                width='25'
                height='24'
                viewBox='0 0 25 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M12.3922 2.00342C12.464 1.99886 12.536 1.99886 12.6078 2.00342C14.4817 2.12235 16.2333 2.72906 19.0867 3.9191C19.6356 4.14801 20.0251 4.64689 20.1138 5.23481C20.519 7.91918 20.6092 10.2392 20.3661 12.2505C20.0585 14.7954 19.2168 16.8453 17.8148 18.5018C16.6401 19.8897 15.0969 20.9717 13.2144 21.8427C12.7612 22.0524 12.2388 22.0524 11.7856 21.8427C9.9031 20.9717 8.35985 19.8897 7.18517 18.5018C5.78317 16.8453 4.9415 14.7954 4.6339 12.2505C4.39078 10.2391 4.48097 7.91918 4.88619 5.23481C4.97494 4.64689 5.36438 4.14801 5.91326 3.9191C8.76673 2.72906 10.5183 2.12235 12.3922 2.00342ZM12.5 20.2994C10.7822 19.5046 9.46052 18.5575 8.48367 17.4033C7.3305 16.0408 6.59719 14.3183 6.32261 12.0465C6.10341 10.2331 6.17772 8.07491 6.56814 5.48858C9.42289 4.29801 10.9393 3.79962 12.5 3.70057C14.0607 3.79962 15.5771 4.29801 18.4319 5.48858C18.8223 8.07491 18.8966 10.2331 18.6774 12.0465C18.4028 14.3183 17.6695 16.0408 16.5163 17.4033C15.5395 18.5575 14.2178 19.5046 12.5 20.2994Z'
                  fill='black'
                />
              </svg>
            </button>
          </div>
        </section>
      </S.Description>
    </React.Fragment>
  )
}

export default StepTwo
