import React from 'react'

import Items from '../Items'
import * as S from './styled'

const Pending = ({ title, protocol, notes, status, onChange, onSubmit }) => {
  return (
    <S.Container onSubmit={onSubmit} data-testid='item-pending'>
      <S.Content>
        <Items infoPending title={title} status={status} />
        <S.Items>
          <h3>Documentos pendentes</h3>
          {notes.map((item, key) => (
            <S.Documents key={key}>
              <span>{item}</span>
              <input
                data-testid='input-file'
                type='file'
                id={item}
                name={item}
                accept={['.jpg', '.png', '.pdf']}
                onChange={onChange}
                required
              />
            </S.Documents>
          ))}
        </S.Items>
        <S.Items>
          <h3>{protocol}</h3>
          <p>Protocolo</p>
        </S.Items>
        <S.Button type='submit'>Enviar documentos</S.Button>
      </S.Content>
    </S.Container>
  )
}

export default Pending
