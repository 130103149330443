import styled from 'styled-components'

export const Main = styled.main`
  background-color: #004bc1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  min-height: 100vh;
`

export const Row = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 30px;
`

export const Footers = styled.div`
  width: 100%;
`

export const Container = styled.div`
  max-width: 460px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 4px;
  padding: 30px;

  @media screen and (max-width: 460px) {
    max-width: 100%;
    width: auto;
  }
`

export const Logo = styled.img`
  max-width: 200px;
  width: 100%;
  margin-bottom: 40px;
`
