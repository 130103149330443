import React from 'react'

import * as S from './styled'

const Items = ({ title, status, infoPending, iconRight, onClick }) => {
  return (
    <S.Status onClick={onClick} data-testid='list-item'>
      <S.Title>
        <div>
          <h3>{title}</h3>
          {infoPending && (
            <p>
              Ainda faltam algumas informações e <br />
              anexos para seguir com a solicitação
            </p>
          )}
        </div>
        {iconRight && (
          <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M4.89057 13.8047C4.59181 13.5444 4.59182 13.1223 4.89058 12.8619L10.4698 8.00011L4.89057 3.13807C4.59181 2.87771 4.59182 2.4556 4.89058 2.19526C5.18934 1.93491 5.67373 1.93492 5.97249 2.19527L11.5517 7.05732C12.1492 7.57802 12.1492 8.42224 11.5517 8.94293L5.97247 13.8047C5.67371 14.0651 5.18932 14.0651 4.89057 13.8047Z'
              fill='#004BB8'
            />
          </svg>
        )}
      </S.Title>
      {status === 'PENDING' ? (
        <S.Label status='pending'>Pendente</S.Label>
      ) : status === 'ANALYSIS' ? (
        <S.Label status='analysis'>Em análise</S.Label>
      ) : status === 'REFUSED' ? (
        <S.Label status='info'>Reprovada</S.Label>
      ) : (
        <S.Label status='success'>Aprovada</S.Label>
      )}
    </S.Status>
  )
}

export default Items
