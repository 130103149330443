import React from 'react'

import IconLoader from '../../Icons/IconLoader'
import { ButtonContainer } from './styled'

const Button = ({
  dataTestId,
  name,
  type = 'button',
  onClick,
  style,
  disabled = false,
  loader = false,
  active = false,
  children,
}) => {
  return (
    <ButtonContainer
      data-testid={dataTestId}
      type={type}
      onClick={onClick}
      style={style}
      disabled={disabled}
      active={active}
    >
      {name} {loader && <IconLoader />} {children}
    </ButtonContainer>
  )
}

export default Button
