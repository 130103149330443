import axios from '@/services/BaseService'
import { captureException } from '@/utils/Instrumentation/Exception'
import { translateMessage } from '@/utils/Translator'

export async function postNotification(deviceId, notification) {
  const endpoint = `/v1/notification/${deviceId}?notification=${notification}`
  try {
    const response = await axios.post(endpoint)
    return {
      status: true,
      postNotification: response,
    }
  } catch (error) {
    captureException({
      error,
      tag: 'SEND_NOTIFICATION_EXCEPTION',
      endpoint,
      body: {
        deviceId,
        notification,
      },
    })

    return {
      status: false,
      message: translateMessage(error.message),
    }
  }
}
