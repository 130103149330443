import { toast } from 'react-toastify'

export default function useToast() {
  const toastrMessage = (type, message, position) => {
    toast[type](message, {
      position,
    })
  }

  return { toastrMessage }
}
