import { useState } from 'react'

export default function useCountDownTimer() {
  const [timer, setTimer] = useState(90)
  const [activeItem, setActiveItem] = useState(true)

  const countDownTimer = () => {
    let timer = 91
    let countdown = setInterval(() => {
      if (timer <= 0) {
        clearInterval(countdown)
        setActiveItem(false)
      } else {
        setTimer((timer -= 1))
      }
    }, 1000)
  }

  return { timer, setTimer, setActiveItem, countDownTimer, activeItem }
}
