const colors = {
  primary: '#004BC1',
  secondary: 'rgb(244,123,56)',
  disabled: '#858585',
  disabled2: '#999999',
  white: '#FFFFFF',
  alert: '#C70059',
  text: '#333333',
  pink: '#C70059',
  blue_dark: '#004BC1',
  green: '#9BD900',
  grey: '#555555',
  grey_clear: '#B3B3B3',
  grey2: '#696969',
  grey3: 'hsla(0, 0%, 90%, 1)',
}

export default colors
