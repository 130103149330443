import styled from 'styled-components'

const handleStatusType = (status) => {
  switch (status) {
    case 0:
      return '#f0f0f0'
    case 101:
      return '#c42137'
    default:
      return '#9bd900'
  }
}

export const Container = styled.div`
  align-items: center;
  border-radius: 99px;
  background-color: #f0f0f0;
  display: flex;
  height: 40px;
  justify-content: center;
  position: relative;
  width: 100%;
  cursor: pointer;

  > p {
    position: relative;
    color: ${({ status }) => (status === 101 ? '#ffffff' : '#000000')};
  }

  > svg {
    position: relative;
  }

  > div {
    width: ${({ status }) => `${status}%`};
    background-color: ${({ status }) => handleStatusType(status)};
    border-radius: ${({ status }) =>
      status === 100 || status === 101 ? ' 99px' : ' 99px 0 0 99px'};
    height: 100%;
    left: 0;
    position: absolute;
  }
`

export const Content = styled.ul`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  border-radius: 32px;
  padding: 20px 32px 20px;
  position: absolute;
  top: 50px;
  z-index: 1;
  width: 80%;

  > li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style-type: none;
    font-size: 16px;
    padding: 20px 0;

    h1 {
      font-size: 20px;
      line-height: 24px;
    }
  }

  > li + li {
    border-top: 1px solid #f0f0f0;
  }
`
