import styled from 'styled-components'

export const Section = styled.section`
  align-items: flex-start;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  justify-content: center;
  margin: 0 auto;
  max-width: 1000px;
  width: 100vw;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
    grid-template-rows: max-content;
    height: max-content;
    max-width: 100%;
    width: 100%;
  }
`

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0;

  h1 {
    color: hsla(217, 100%, 38%, 1);
    font-family: 'Space Grotesk', sans-serif;
    font-weight: 500;
    font-size: 2.225rem;
    line-height: 50.4px;
    letter-spacing: -5%;
  }

  h2 {
    color: hsla(0, 0%, 33%, 1);
    font-family: 'Space Grotesk', sans-serif;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 38.4px;
    letter-spacing: -3%;
    margin-top: 20px;
  }

  a {
    font-family:
      Space Grotesk,
      sans-serif;
    font-weight: 400;
    margin-top: 30px;
    color: #b3b3b3;
    pointer-events: none;
  }

  p {
    color: #666;
    font-family:
      Space Grotesk,
      sans-serif;
    font-size: 0.8rem;
    font-weight: 400;
  }

  @media screen and (max-width: 1000px) {
    h1 {
      color: hsla(217, 100%, 38%, 1);
      font-size: 1.8rem;
      line-height: 40.4px;
      letter-spacing: -5%;
    }

    h2 {
      color: hsla(0, 0%, 33%, 1);
      font-size: 1.2rem;
      line-height: 30.4px;
      letter-spacing: -3%;
    }
  }
`

export const Form = styled.form`
  background-color: hsla(0, 0%, 98%, 1);
  border: 1px solid hsla(0, 0%, 90%, 1);
  border-radius: 24px;
  display: grid;
  grid-template-areas:
    'title title'
    'label label'
    'link link'
    '. button';
  padding: 40px;

  > p {
    color: hsla(0, 0%, 33%, 1);
    font-family: 'Space Grotesk', sans-serif;
    font-size: 1rem;
    grid-area: title;
    letter-spacing: -3%;
    line-height: 25.6px;
    margin-bottom: 30px;
  }

  > label {
    grid-area: label;

    input[type='email'] {
      background-color: transparent;
      border: none;
      font-size: 0.9rem;
    }
  }

  > button {
    background-color: #bd2469;
    border-radius: 16px;
    border: none;
    font-size: 0.9rem;
    grid-area: button;
    margin-top: 30px;
    padding: 16px 40px;
  }

  button:disabled {
    background-color: #bd2469;
    color: #fff;
    opacity: 0.2;
  }
`

export const InputPincode = styled.div`
  display: grid !important;
  grid-gap: 10px;
  gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(30px, 1fr)) !important;

  input {
    background-color: initial;
    border-radius: 0;
    border: none;
    border-bottom: 2px solid #b3b3b3;
    text-align: center;
    width: 100% !important;
  }
`

export const Timer = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  justify-content: space-between;
  margin-top: 10px;
  grid-area: link;

  > span {
    font-family:
      Space Grotesk,
      sans-serif;
    font-size: 0.7rem;
    text-decoration: none;
  }
`

export const ButtonResendPincode = styled.button`
  background: none;
  border: none;
  color: #b3b3b3;
  text-decoration: underline;
  padding: 0;
  margin-top: 30px;
`
