import { URL_BACKEND } from '@/constants'
import axios from 'axios'

axios.interceptors.request.use((config) => {
  config.baseURL = URL_BACKEND

  if (config.headers.external) {
    delete config.headers.external
    return config
  }

  let token = localStorage.getItem('st')

  if (token) {
    config.headers.Authorization = token
    config.headers['Content-Type'] = 'application/json'
  }

  return config
})

axios.interceptors.response.use(
  (response) => {
    console.log(`[SUCCESS] | ENDPOINT: ${response.config.url}`, response.data)
    return response.data
  },
  (error) => {
    console.error(`[ERROR] | ENDPOINT: ${error.config.url}`, error)

    if (error.response && error.response.data) {
      return Promise.reject(error.response.data)
    }

    return Promise.reject(error.message)
  }
)

export default axios
