import React, { useState } from 'react'

import { useAuth } from '@/contexts/Authentication'
import { useLoader } from '@/contexts/Loader'
import useCountDownTimer from '@/hooks/useCountDownTimer'
import useToast from '@/hooks/useToast'
import {
  sendPincode,
  validatePincode,
} from '@/services/APIService/Authentication'
import { getUserData } from '@/services/APIService/Users'
import { signInCustomTokenUser } from '@/services/Firebase'

import FormSendEmail from './atoms/FormSendEmail'
import FormValidatePincode from './atoms/FormValidatePincode'

const AuthenticationEmail = () => {
  const { setLoader } = useLoader()
  const { signIn } = useAuth()
  const { activeItem, setActiveItem, timer, countDownTimer } =
    useCountDownTimer()
  const { toastrMessage } = useToast()
  const [display, setDisplay] = useState({
    stepOne: true,
    stepTwo: false,
  })
  const [formData, setFormData] = useState({
    email: '',
    pincode: '',
  })

  const handleResendCode = async (e) => {
    e.preventDefault()
    setLoader(true)

    const response = await sendPincode(formData.email)

    if (!response.status) {
      toastrMessage('error', response.message)
      setLoader(false)
      return
    }

    setActiveItem(true)
    setLoader(false)
    countDownTimer()
  }

  const onSubmitSendPincode = async (e) => {
    e.preventDefault()
    setLoader(true)

    const response = await sendPincode(formData.email)

    if (!response.status) {
      toastrMessage('error', response.message)
      setLoader(false)
      return
    }

    countDownTimer()
    setLoader(false)
    setDisplay({
      stepOne: false,
      stepTwo: true,
    })
  }

  const onSubmitValidatePincode = async (e) => {
    e.preventDefault()

    setLoader(true)

    const validatePincodeResponse = await validatePincode(
      formData.email,
      formData.pincode
    )

    if (!validatePincodeResponse.status || !validatePincodeResponse.token) {
      toastrMessage('error', validatePincodeResponse.message)
      setLoader(false)
      return
    }

    const userCustomToken = await signInCustomTokenUser(
      validatePincodeResponse.token
    )

    if (!userCustomToken.status) {
      toastrMessage('error', userCustomToken.message)
      setLoader(false)
      return
    }

    const getUserDataResponse = await getUserData(formData.email)

    if (!getUserDataResponse.status) {
      toastrMessage('error', getUserDataResponse.message)
      setLoader(false)
      return
    }

    signIn(getUserDataResponse.userData)
  }

  return (
    <React.Fragment>
      {display.stepOne && (
        <FormSendEmail
          email={formData.email}
          onChange={(e) =>
            setFormData((data) => ({
              ...data,
              email: e.target.value,
            }))
          }
          onSubmit={onSubmitSendPincode}
        />
      )}

      {display.stepTwo && (
        <FormValidatePincode
          timer={timer}
          disabledButton={activeItem}
          email={formData.email}
          pincode={formData.pincode}
          handleResendCode={handleResendCode}
          onChange={(value) =>
            setFormData((data) => ({
              ...data,
              pincode: value,
            }))
          }
          onSubmit={onSubmitValidatePincode}
        />
      )}
    </React.Fragment>
  )
}

export default AuthenticationEmail
