import { useAuth } from '@/contexts/Authentication'
import moment from 'moment'

export default function useFormattedData() {
  const {
    user: {
      profile: {
        device_os,
        imei,
        device_id,
        subscription: { plan_id },
        msisdn,
        fullname,
        email,
        document,
      },
    },
  } = useAuth()

  const formattedData = (formData, id, status) => {
    let typePerson = ''
    let eventDescription = ''
    let bankName = ''
    let bankCode = ''
    let bankAgencyNumber = ''
    let bankAccount = ''
    let bankAccountType = ''
    let flagTerms = ''
    let isInsured = false
    let dateSinister = `${moment().format('YYYY-MM-DD')} ${moment().format('HH:mm')}`
    let ownerName = ''
    let ownerRelationship = ''
    let ownerCpf = ''
    let ownerEmail = ''
    let ownerPhone = ''
    let ownerAddressName = ''
    let ownerAddressNumber = ''
    let ownerAddressComplement = ''
    let ownerAddressDistrict = ''
    let ownerAddressCity = ''
    let ownerAddressState = ''
    let onwerAddressZipcode = ''
    let documents = [
      {
        name_document: 'invoiceFiles',
        data_document: '',
      },
      {
        name_document: 'policeReport',
        data_document: '',
      },
      {
        name_document: 'residencyProof',
        data_document: '',
      },
      {
        name_document: 'cnhFiles',
        data_document: '',
      },
      {
        name_document: 'cpfFiles',
        data_document: '',
      },
    ]

    if (Object.keys(formData).length > 0) {
      isInsured = formData.typePerson === 'Fisica' ? true : false
      dateSinister = `${formData.sinisterDate} ${formData.sinisterTime}`
      typePerson = formData.typePerson
      ownerEmail =
        formData.typePerson === 'Fisica' ? email : formData.ownerEmail
      ownerPhone =
        formData.typePerson === 'Fisica' ? msisdn : formData.ownerPhone
      ownerName =
        formData.typePerson === 'Fisica' ? fullname : formData.ownerName
      ownerRelationship = formData.ownerRelationship
      ownerCpf = formData.ownerCpf
      eventDescription = formData.eventDescription
      bankCode = formData.bankCode
      bankAgencyNumber = formData.bankAgencyNumber
      bankAccount = formData.bankAccount
      bankAccountType = formData.bankAccountType
      flagTerms = formData.flagTerms
      ownerAddressName = formData.ownerAddressName
      ownerAddressNumber = formData.ownerAddressNumber
      ownerAddressComplement = formData.ownerAddressComplement
      ownerAddressDistrict = formData.ownerAddressDistrict
      ownerAddressCity = formData.ownerAddressCity
      ownerAddressState = formData.ownerAddressState
      onwerAddressZipcode = formData.onwerAddressZipcode

      documents = [
        {
          name_document: 'invoiceFiles',
          data_document:
            formData.invoiceFiles['base64'] || formData.invoiceFiles.file || '',
        },
        {
          name_document: 'policeReport',
          data_document:
            formData.policeReport['base64'] || formData.policeReport.file || '',
        },
        {
          name_document: 'residencyProof',
          data_document:
            formData.residencyProof['base64'] ||
            formData.residencyProof.file ||
            '',
        },
        {
          name_document: 'cnhFiles',
          data_document:
            formData.cnhFiles['base64'] || formData.cnhFiles.file || '',
        },
        {
          name_document: 'cpfFiles',
          data_document:
            formData.cpfFiles['base64'] || formData.cpfFiles.file || '',
        },
      ]

      if (device_os === 'ios') {
        documents.push({
          name_document: 'iCloudScreen',
          data_document:
            formData.iCloudScreen['base64'] || formData.iCloudScreen.file || '',
        })
      }
    }

    return {
      id: id || '',
      status: status || 'PENDENTE',
      imei: imei,
      cpf_cnpj: document,
      certificate_number: '7900000171',
      communication: false,
      subscription_id: plan_id,
      device_id: device_id,
      bank_cpfCnpj: document,
      coverage_id: '1',
      cause_type_id: '1',
      type_communication: '',
      sinister_type: '',
      location_info: '',
      block_request: '',
      event_date: '',
      is_insured: isInsured,
      date_sinister: dateSinister,
      email: ownerEmail,
      telefone: ownerPhone,
      name: ownerName,
      owner_relationship: ownerRelationship,
      owner_cpf: ownerCpf,
      type_person: typePerson,
      event_description: eventDescription,
      bank_name: bankName,
      bank_code: bankCode,
      bank_agency: bankAgencyNumber,
      bank_account: bankAccount,
      bank_account_type: bankAccountType,
      flag_terms: flagTerms,
      documents: documents,
      address: {
        address_name: ownerAddressName,
        address_number: ownerAddressNumber,
        address_complement: ownerAddressComplement,
        address_district: ownerAddressDistrict,
        address_city: ownerAddressCity,
        address_state: ownerAddressState,
        address_zipcode: onwerAddressZipcode,
      },
    }
  }

  return { formattedData }
}
