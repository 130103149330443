import React from 'react'

import CardAction from '@/components/Presentational/Cards/Action'
import CardDisabled from '@/components/Presentational/Cards/Disabled'
import IconArrow from '@/components/Presentational/Icons/IconArrow'
import IconCheckIn from '@/components/Presentational/Icons/IconCheckIn'
import IconClock from '@/components/Presentational/Icons/IconClock'
import IconLoader from '@/components/Presentational/Icons/IconLoader'
import IconSecurity from '@/components/Presentational/Icons/IconSecurity'
import colors from '@/styles/colors'

const Card = ({ status, disabled, activeCard, onClick }) => {
  if (disabled) {
    return (
      <CardDisabled
        dataTestId='card-disabled-panic'
        type={'full-width'}
        title={'Celular roubado'}
        subTitle={'Botão do pânico'}
        iconLeft={<IconSecurity color={colors.disabled2} />}
        iconRight={<IconClock />}
        button={<IconArrow color={colors.disabled2} />}
      />
    )
  }

  return (
    <CardAction
      dataTestId='card-panic'
      type={'full-width'}
      backgroundColor={activeCard ? colors.green : colors.white}
      status={status}
      onClick={onClick}
      title={'Celular roubado'}
      subTitle={'Botão do pânico'}
      iconLeft={<IconSecurity />}
      iconRight={status === null ? <IconLoader /> : <IconCheckIn />}
      button={<IconArrow />}
    />
  )
}

export default Card
