export const LIST_CARDS_1 = [
  {
    title: 'Localize o aparelho em tempo real',
    description: 'Localização ao vivo por mapa e GPS',
    url_redirect: '/faq',
  },
  {
    title: 'Faça bloqueio do celular à distância',
    description: 'Bloqueie o aparelho remotamente.',
    url_redirect: '/faq',
  },
  {
    title: 'Apague todos os seus dados remotamente',
    description: 'Apague todo conteúdo do seu celular.',
    url_redirect: '/faq',
  },
  {
    title: 'Descubra quem está usando',
    description: 'Fotografe com a câmera frontal.',
    url_redirect: '/faq',
  },
]

export const LIST_CARDS_2 = [
  {
    title: 'Rastreie e dispare alarme de segurança',
    description:
      'Encontramos seu celular em tempo real e emitimos um alarme que não pode ser desligado.',
  },
  {
    title: 'Bloqueie seu aparelho com facilidade',
    description:
      'Bloqueamos o acesso a tudo para que ninguém consiga fazer nada com seu aparelho.',
  },
  {
    title: 'Limpe todos os dados do seu aparelho celular',
    description:
      'Apague seus dados remotamente e proteja suas informações mais sensíveis e importantes.',
  },
  {
    title: 'Seguro Pix contra transações indevidas',
    description:
      'Receba até R$ 10.000,00 em casos de movimentações indevidas na sua conta de forma fácil, 100% online.',
  },
]

export const LIST_CARDS_3 = [
  {
    title: 'Phising',
    description:
      'É uma forma que os criminosos usam para roubar seus dados, como suas informações pessoais, senhas, número do cartão e outros, por meio de mensagens duvidosas por e-mail e outros meios.',
    buttonTitle: 'Como se proteger',
    urlRedirect: '/faq',
  },
  {
    title: 'Smishing',
    description:
      'Um SMS falando da necessidade de atualização de conta bancária com urgência chega ao telefone. É solicitado que a pessoa faça uma ligação e informe os dados para regularizar.',
    buttonTitle: 'Como se proteger',
    urlRedirect: '/faq',
  },
  {
    title: 'Apps fraudulentos',
    description:
      'Aplicativos falsos desenvolvidos com a mesma identidade de bancos, corretoras e fintechs com o objetivo de coletar seus dados. Muitas vezes clonando aplicativos reais.',
    buttonTitle: 'Como se proteger',
    urlRedirect: '/faq',
  },
  {
    title: 'Whatsapp',
    description:
      'Geralmente, o golpe começa com uma pessoa se passando por atendentes de lojas e solicitam o código de segurança do aplicativo e, quando fornecido, conseguem ter acesso à conta do usuário.',
    buttonTitle: 'Como se proteger',
    urlRedirect: '/faq',
  },
]
