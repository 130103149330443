import React from 'react'

import ICONEMAIL from '@/assets/img/icon-email.svg'

import * as S from './styled'

const FormSendEmail = ({ email, onChange, onSubmit }) => (
  <S.Section>
    <S.Header>
      <h1>Digite seu e-mail cadastrado</h1>
      <h2>Certifique-se de usar o mesmo e-mail cadastrado anteriormente.</h2>
    </S.Header>
    <S.Form onSubmit={onSubmit}>
      <p>
        Digite seu e-mail cadastrado para receber o código de acesso ao Portal
        de Proteção.
      </p>
      <label htmlFor='email'>
        E-mail
        <div>
          <input
            type='email'
            name='email'
            data-testid='email'
            onChange={onChange}
            value={email}
            placeholder='Digite seu e-mail cadastrado'
            required
          />
          <img src={ICONEMAIL} alt='Ícone E-mail' width='20' height='20' />
        </div>
      </label>
      <button type='submit'>
        Enviar código
        <svg
          width='21'
          height='20'
          viewBox='0 0 21 20'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M10.7439 17.2559C10.4185 16.9305 10.4185 16.4028 10.7439 16.0774L15.988 10.8333L2.99984 10.8333C2.5396 10.8333 2.1665 10.4602 2.1665 10C2.1665 9.53976 2.5396 9.16667 2.99984 9.16667L15.988 9.16667L10.7439 3.92259C10.4185 3.59715 10.4185 3.06952 10.7439 2.74408C11.0694 2.41864 11.597 2.41864 11.9224 2.74408L18.5891 9.41074C18.9145 9.73618 18.9145 10.2638 18.5891 10.5893L11.9224 17.2559C11.597 17.5814 11.0694 17.5814 10.7439 17.2559Z'
            fill='white'
          />
        </svg>
      </button>
    </S.Form>
  </S.Section>
)

export default FormSendEmail
