import styled from 'styled-components'

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 15px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  > label {
    display: flex;
    color: #004bb8;
    border: 2px solid #004bb8;
    border-radius: 17px;
    padding: 10px;
    width: 100px;
    cursor: pointer;

    input {
      display: none;
    }
  }
`

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  > p {
    font-size: 12px;
  }
`
export const SelectFile = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`

export const Label = styled.div`
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: baseline;
  background-color: #e3e3e3;
  padding: 5px 15px;
  border-radius: 15px;

  > div {
    display: flex;
    flex-direction: row;
    gap: 5px;

    > span {
      font-size: 12px;
    }
  }
`
