import React, { useEffect, useState } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import Loader from '@/components/Presentational/Loader'
import { useAuth } from '@/contexts/Authentication'
import { db } from '@/firebase'
import { getDeviceInfo } from '@/services/APIService/Device'
import { getToken } from '@/utils/Token'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import {
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore'

function PrivateRoute() {
  const [loader, setLoader] = useState(true)
  const [isLoggedIn, setIsLoggedIn] = useState(true)
  const { user, setUser } = useAuth()

  const isAuthorizated = async () => {
    try {
      let user = null
      let emailVerified = false

      const {
        status,
        profile: { email },
      } = getToken()

      if (!status) {
        localStorage.removeItem('st')
        setIsLoggedIn(false)
        setLoader(false)
        return
      }

      const q = query(collection(db, 'user'), where('email', '==', email))

      const querySnapshot = await getDocs(q)

      querySnapshot.forEach((doc) => {
        const { deleted_at } = doc.data()

        if (deleted_at === null) {
          user = doc.data()
        }
      })

      if (!user) {
        localStorage.removeItem('st')
        setIsLoggedIn(false)
        setLoader(false)
        return
      }

      console.log('[getUserData-response]:', user)

      const auth = getAuth()

      onAuthStateChanged(auth, (data) => {
        if (data) {
          emailVerified = data.emailVerified
        }
      })

      let deviceInfo = {
        device_name: '',
        device_brand: '',
        device_model: '',
        device_os: '',
        device_tac: '',
        image_link: '',
      }

      const getDeviceInfoResponse = await getDeviceInfo(user.imei)

      if (getDeviceInfoResponse.status) {
        deviceInfo = getDeviceInfoResponse.deviceInfo
      }

      user.emailVerified = emailVerified
      user.lastAction = user.last_action

      setUser({
        profile: user,
        mobile: deviceInfo,
      })

      setLoader(false)
      return
    } catch (e) {
      setIsLoggedIn(false)
      setLoader(false)
      return
    }
  }

  useEffect(() => {
    if (user) {
      const q = query(
        collection(db, 'user'),
        where('email', '==', user.profile.email)
      )
      const unsubscribe = onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const { deleted_at } = change.doc.data()
          if (deleted_at === null) {
            if (change.type === 'modified') {
              isAuthorizated()
            }
          }
        })
      })

      return () => unsubscribe()
    }
  }, [user])

  useEffect(() => {
    isAuthorizated()
  }, [])

  if (loader) {
    return <Loader />
  }

  return isLoggedIn ? <Outlet /> : <Navigate to='/' />
}

export default PrivateRoute
