import React, { useState } from 'react'

import DeleteAccount from '@/components/Containers/DeleteAccount'
import { useAuth } from '@/contexts/Authentication'
import { createDate } from '@/utils/Mask/Date'

const MyPlan = () => {
  const {
    user: {
      profile: {
        subscription: {
          subscription_date: { seconds },
        },
      },
    },
  } = useAuth()
  const [display, setDisplay] = useState(false)

  if (display) {
    return <DeleteAccount />
  }

  return (
    <React.Fragment>
      <div className='title-section'>
        <div className='group'>
          <p>Meu plano</p>
        </div>
      </div>
      <ul className='list-items'>
        <li>
          <p>Nome do plano</p>
          <p>Proteção PIX</p>
        </li>
        <li>
          <p>Data de aquisição</p>
          <p>{createDate(seconds)}</p>
        </li>
        <li>
          <p
            data-testid='delete-account'
            onClick={() => setDisplay(!display)}
            style={{ cursor: 'pointer' }}
          >
            Apagar minha conta
          </p>
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M14.0613 22.0607C13.4755 22.6464 12.5257 22.6464 11.9399 22.0607C11.3542 21.4749 11.3542 20.5251 11.9399 19.9393L18.1115 13.7678C18.2017 13.6776 18.2835 13.5822 18.3571 13.4827L3.00159 13.4989C2.17316 13.4998 1.50088 12.829 1.5 12.0005C1.49912 11.1721 2.16998 10.4998 2.99841 10.4989L18.331 10.4827C18.2643 10.3956 18.1912 10.3119 18.1115 10.2322L11.9399 4.06066C11.3542 3.47487 11.3542 2.52513 11.9399 1.93934C12.5257 1.35355 13.4755 1.35355 14.0613 1.93934L20.2328 8.11091C22.3807 10.2588 22.3807 13.7412 20.2328 15.8891L14.0613 22.0607Z'
              fill='#1F1F1F'
            />
          </svg>
        </li>
      </ul>
    </React.Fragment>
  )
}
export default MyPlan
