import React from 'react'

import { phoneMask } from '@/utils/Mask/PhoneNumber'

import * as S from './styled'

const ListData = ({ items }) => {
  return (
    <React.Fragment>
      <S.Container>
        <S.Title>
          <p>Contatos salvos</p>
          <S.LabelInformation>{items.length} de 3</S.LabelInformation>
        </S.Title>
        <S.List>
          {items.map((item, key) => (
            <S.Item key={key}>
              <S.Content>
                <S.Description>
                  <svg
                    width='32'
                    height='32'
                    viewBox='0 0 32 32'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M12.0123 7.1048C11.8098 6.59857 11.3196 6.26667 10.7745 6.26667H7.4736C6.8069 6.26667 6.26667 6.80696 6.26667 7.47344C6.26667 17.558 14.4419 25.7333 24.5261 25.7333C25.1922 25.7333 25.7327 25.1932 25.7328 24.5261L25.7333 21.2248C25.7332 20.6798 25.4015 20.1895 24.8955 19.987L21.7318 18.722C21.2775 18.5402 20.7598 18.6219 20.3836 18.935L19.5675 19.6156C18.1896 20.7639 16.1631 20.6715 14.896 19.4055L12.5962 17.1035C11.3294 15.836 11.2352 13.8107 12.383 12.4332L13.0639 11.6167C13.3779 11.2399 13.46 10.7215 13.2784 10.2675L12.0123 7.1048ZM14.8052 13.0678L14.1244 13.8842C13.7278 14.3601 13.7593 15.061 14.199 15.5007L16.4981 17.802C16.9367 18.2402 17.6387 18.2724 18.1157 17.875L18.9318 17.1943C19.9478 16.3476 21.346 16.1264 22.5734 16.6173L25.7371 17.8823C27.1039 18.4291 28 19.7528 28 21.2248L27.9994 24.5262C27.9993 26.4444 26.4447 28 24.5261 28C13.19 28 4 18.8098 4 7.47344C4 5.55481 5.55536 4 7.4736 4H10.7745C12.2465 4 13.5702 4.89622 14.1169 6.263L15.383 9.42573C15.8745 10.6544 15.651 12.0528 14.8052 13.0678Z'
                      fill='#121212'
                    />
                  </svg>
                  <div>
                    <S.Text>{phoneMask(item.phoneNumber.substring(2))}</S.Text>
                    <S.SubTitle>{item.name}</S.SubTitle>
                  </div>
                </S.Description>
              </S.Content>
            </S.Item>
          ))}
        </S.List>
        <S.Information>
          Você pode cadastrar até 3 contatos de confiança.
        </S.Information>
      </S.Container>
      <div>
        <S.Information>
          Para gerenciar, adicionar ou excluir seus contatos de confiança,
          acesse o aplicativo Proteção Pix.
        </S.Information>
      </div>
    </React.Fragment>
  )
}

export default ListData
