import styled from 'styled-components'

export const Input = styled.label`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 2px solid #1f1f1f;
    border-radius: 8px;
    padding: 10px;

    > input {
      border: none;
      padding: 0px;
    }
  }
`
