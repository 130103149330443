import React, { useEffect, useState } from 'react'

import { useForm } from '@/components/Containers/Claims/contexts/Forms'
import Collapse from '@/components/Presentational/Collapsible/Collapse'
import { useCollapse } from '@/components/Presentational/Collapsible/contexts/Collapse'
import Item from '@/components/Presentational/Collapsible/Item'
import { SelectSearch } from '@/components/Presentational/Inputs'
import InputGroup from '@/components/Presentational/Inputs/InputGroup'
import { useAuth } from '@/contexts/Authentication'
import { getBanks } from '@/services/APIService/Banks'

import { ACCOUNT_TYPE } from '../../constants'
import * as S from './styled'

const validateForms = (
  bankCode,
  bankAgencyNumber,
  bankAccountType,
  bankAccount
) => {
  const validate = [
    bankCode,
    bankAgencyNumber,
    bankAccountType,
    bankAccount,
  ].filter((item) => item !== '').length

  return validate === 0 ? 'PENDING' : validate === 4 ? 'SUCCESS' : 'ANALYSIS'
}

const BankDataItem = () => {
  const {
    formData: { bankCode, bankAgencyNumber, bankAccountType, bankAccount },
    spinner,
  } = useForm()
  const { collapse, setCollapse } = useCollapse()

  return (
    <Item
      spinner={spinner}
      status={validateForms(
        bankCode,
        bankAgencyNumber,
        bankAccountType,
        bankAccount
      )}
      title='Dados bancários'
      dataTestId='button-bank-data'
      onClick={() =>
        setCollapse((prev) => ({
          ...prev,
          bankData: !collapse.bankData,
        }))
      }
    />
  )
}

const BankDataForm = () => {
  const {
    formData: { bankCode, bankAgencyNumber, bankAccountType, bankAccount },
    updateFormData,
    spinner,
  } = useForm()
  const {
    user: {
      profile: { document, fullname },
    },
  } = useAuth()
  const { collapse, setCollapse } = useCollapse()
  const [banks, setBanks] = useState([])

  const handleChange = (e) => {
    const { name, value } = e.target
    updateFormData({ [name]: value })
  }

  const listBanks = async () => {
    const response = await getBanks()

    if (!response.status) {
      return
    }

    const rows = response.banks.map((row, index) => ({
      id: index,
      value: String(row.code),
      label: row.name,
    }))

    setBanks(rows)
  }

  useEffect(() => {
    listBanks()
  }, [])

  return (
    <Collapse
      spinner={spinner}
      isOpen={collapse.bankData}
      title='Dados bancários'
      subTitle='Insira as informações bancárias do beneficiado para receber o reembolso das transações fraudulentas.'
      onClick={() =>
        setCollapse((prev) => ({
          ...prev,
          bankData: !collapse.bankData,
        }))
      }
      status={validateForms(
        bankCode,
        bankAgencyNumber,
        bankAccountType,
        bankAccount
      )}
    >
      <S.Form data-testid='form-bank-data'>
        <InputGroup
          label='Nome da pessoa beneficiária'
          type='text'
          value={fullname}
          disabled
        />
        <InputGroup label='CPF ou CNPJ' type='text' value={document} disabled />
        <label>
          Instituição financeira
          <SelectSearch
            id='bankCode'
            name='bankCode'
            options={banks}
            value={banks.filter((option) => option.value === bankCode)}
            onChange={(e) => updateFormData({ bankCode: e.value })}
          />
        </label>
        <label>
          Tipo de conta
          <select
            name='bankAccountType'
            onChange={handleChange}
            data-testid='bankAccountType'
            value={bankAccountType}
          >
            <option value=''>Selecione</option>
            {ACCOUNT_TYPE.map((item) => (
              <option key={item.id} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        </label>
        <label>
          Agência (sem dígito)
          <input
            type='text'
            data-testid='bankAgencyNumber'
            name='bankAgencyNumber'
            value={bankAgencyNumber}
            onChange={handleChange}
          />
        </label>
        <label>
          Conta com dígito
          <input
            type='text'
            data-testid='bankAccount'
            name='bankAccount'
            value={bankAccount}
            onChange={handleChange}
          />
        </label>
      </S.Form>
    </Collapse>
  )
}

export { BankDataItem, BankDataForm }
