import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
`

export const Shimmer = styled.div`
  animation: shimmer 2s linear infinite;
  background: #f0f0f0
    linear-gradient(
      to right,
      transparent 0%,
      rgba(0, 0, 0, 0.2) 25%,
      transparent 50%
    );
  background-size: 200% 100%;
  width: 100%;
  padding: 5px;
  border-radius: 20px;

  @keyframes shimmer {
    from {
      background-position: 200% 0;
    }
    to {
      background-position: calc(200% * -1) 0;
    }
  }
`
