import React from 'react'

import assinatura from '@/assets/img/assinatura-certificado.png'
import banner from '@/assets/img/banner.png'
import moment from 'moment'

import styles from './styles.module.css'

const Certificate = ({ item }) => {
  return (
    <div className={styles.certificate}>
      <main>
        <header>
          <img alt='' src={banner} />
        </header>

        <section>
          <br />
          <header>
            <h2>Olá,</h2>
            <h1>{item.fullName}</h1>
          </header>

          <p>
            Este é o certificado do seu <strong>Seguro Proteção PIX</strong>.
            Aqui você encontra informações importantes sobre o seu contrato.
          </p>
          <p>Leia com atenção e consulte sempre que precisar.</p>
          <p>
            As Condições completas do seguro poderão ser consultadas no sítio
            eletrônico da SUSEP (
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://www.susep.gov.br'
            >
              www.susep.gov.br
            </a>
            ), através do numero de processo 15414.004716/2004-23
          </p>
        </section>

        <hr />

        <section>
          <header>
            <h1>Coberturas</h1>
            <h2>Transações Eletrônicas mediante roubo do aparelho celular:</h2>
          </header>

          <p>
            A Seguradora garantirá, até o Limite Máximo de Indenização (LMI)
            contratado para esta cobertura ao Segurado, os prejuízos decorrentes
            de transações financeiras, ocorridas mediante o Roubo do Aparelho
            Segurado - Transações financeiras realizadas via PIX, DOC (Documento
            de Ordem de Crédito), TED (Transferência Eletrônica disponível), TEF
            (Transação Eletrônica de Fundos), Boleto, e que tenha sido utilizado
            o saldo em conta corrente ou o limite da conta corrente (cheque
            especial), através de Internet banking ou aplicativos de celular,
            realizados no período de cobertura ratificado no Certificado de
            Seguros.
          </p>

          <p>
            Estarão cobertas as Transações Financeiras realizadas até o Limite
            de 48 (quarenta e oito) horas imediatamente posteriores ao Roubo do
            Aparelho celular.
          </p>

          <p>
            Para fins de cobertura, Transações Financeiras se limitam a: Saques,
            Compras e Transações Bancárias, ocorridas nas condições mencionadas.
          </p>
        </section>

        <hr />

        <section>
          <header>
            <h2>COBERTURAS CONTRATADAS E VALORES MÁXIMOS DE INDENIZAÇÃO</h2>
          </header>

          <div className={styles.dataList}>
            <div>
              <p>
                <strong>Coberturas:</strong> Transações Eletrônicas{' '}
              </p>
            </div>

            <div>
              <p>
                <strong>Limite Máximo de Indenização:</strong> Até R$ 10.000,00
              </p>
            </div>
            <div style={{ width: '100%' }}>
              <p>
                <strong>FORMA DE CUSTEIO:</strong> Não-Contributário, ou seja, o
                responsável pelo pagamento é a Estipulante.
              </p>
            </div>

            <div>
              <p>
                <strong>PERÍODICIDADE DE PAGAMENTO:</strong> Mensal
              </p>
            </div>

            <div>
              <p>
                <strong>FORMA DE ADESÃO:</strong> Compulsória
              </p>
            </div>

            <div>
              <p>
                <strong>FRANQUIAS E CARÊNCIAS:</strong> Não Há
              </p>
            </div>

            <div style={{ width: '100%' }}>
              <p>
                <strong>BENEFICIÁRIOS:</strong> O Beneficiário do Seguro será
                sempre o Segurado.
              </p>
            </div>
          </div>
        </section>
        <hr />
        <section>
          <header>
            <h2>Dados do Seguro</h2>
          </header>

          <div className={styles.dataList}>
            <div>
              <p>
                <strong>Nome e Código do Ramo:</strong> Riscos Diversos/71
              </p>
            </div>

            <div>
              <p>
                <strong>Processo SUSEP Nº:</strong> 15414.004716/2004-23
              </p>
            </div>

            <div>
              <p>
                <strong>N° Apólice:</strong> {item.policyNumber}
              </p>
            </div>

            <div>
              <p>
                <strong>Nº Certificado:</strong> {item.certificateNumber}
              </p>
            </div>

            <div style={{ width: '30%' }}>
              <p>
                <strong>Início de Vigência às 24h do dia:</strong>{' '}
                {item.validityStart}
              </p>
            </div>

            <div style={{ width: '30%' }}>
              <p>
                <strong>Término de Vigência às 24h do dia:</strong>{' '}
                {item.validityEnd}
              </p>
            </div>
          </div>
        </section>

        <hr />

        <section>
          <header>
            <h2>Dados da Seguradora</h2>
          </header>

          <div className={styles.dataList}>
            <div style={{ width: '50%' }}>
              <p>
                <strong>Nome:</strong> MAPFRE Seguros Gerais S.A
              </p>
            </div>

            <div style={{ width: '40%' }}>
              <p>
                <strong>CNPJ:</strong> 61.074.175/0001-38
              </p>
            </div>

            <div>
              <p>
                <strong>Endereço:</strong> Av das Nações Unidas, 14.261{' '}
              </p>
            </div>

            <div>
              <p>
                <strong>Bairro:</strong> Vila Gertrudes{' '}
              </p>
            </div>

            <div>
              <p>
                <strong>Cidade:</strong> São Paulo
              </p>
            </div>

            <div>
              <p>
                <strong>Estado:</strong> São Paulo
              </p>
            </div>

            <div>
              <p>
                <strong>UF:</strong> SP
              </p>
            </div>

            <div>
              <p>
                <strong>CEP:</strong> 04794-000
              </p>
            </div>
          </div>
        </section>

        <hr />

        <section>
          <header>
            <h2>Dados do Estipulante</h2>
          </header>

          <div className={styles.dataList}>
            <div>
              <p>
                <strong>Nome:</strong> EXA SERVIÇOS DE TECNOLOGIA S.A.
              </p>
            </div>

            <div>
              <p>
                <strong>CNPJ:</strong> 46.270.650/0001-58
              </p>
            </div>

            <div style={{ width: '100%' }}>
              <p>
                <strong>Endereço:</strong> Avenida Leonardo de Carvalho Castelo
                Branco, 5895 – salas 03 e 04{' '}
              </p>
            </div>

            <div>
              <p>
                <strong>Bairro:</strong> São Judas Tadeu
              </p>
            </div>

            <div>
              <p>
                <strong>Cidade:</strong> Parnaíba
              </p>
            </div>

            <div>
              <p>
                <strong>Estado:</strong> Piauí
              </p>
            </div>

            <div>
              <p>
                <strong>UF:</strong> PI
              </p>
            </div>

            <div>
              <p>
                <strong>CEP:</strong> 64206-260
              </p>
            </div>
          </div>
        </section>

        <hr />

        <section>
          <header>
            <h2>Dados do Corretor</h2>
          </header>

          <div className={styles.dataList}>
            <div style={{ width: '100%' }}>
              <p>
                <strong>Nome:</strong> FS Insurance Admnistradora e Corretora de
                Seguros LTDA.
              </p>
            </div>

            <div>
              <p>
                <strong>SUSEP Nº:</strong> 100288497
              </p>
            </div>

            <div>
              <p>
                <strong>Comissão:</strong> 0,01%
              </p>
            </div>
          </div>
        </section>

        <hr />

        <section>
          <header>
            <h2>Dados do Segurado</h2>
          </header>

          <div className={styles.dataList}>
            <div style={{ width: '50%' }}>
              <p>
                <strong>Nome:</strong> {item.fullName}
              </p>
            </div>
            <div style={{ width: '20%' }}>
              <p>
                <strong>CPF:</strong> {item.cpf}
              </p>
            </div>
            <div style={{ width: '20%' }}>
              <p>
                <strong>Nascimento:</strong> {item.dateOfBirth}
              </p>
            </div>
            <div style={{ width: '100%' }}>
              <p>
                <strong>Nome Social:</strong> {item.socialName}
              </p>
            </div>
            <div style={{ width: '100%' }}>
              <p>
                <strong>Endereço:</strong> {item.addressRisk}
              </p>
            </div>
            <div>
              <p>
                <strong>Bairro:</strong> {item.neighborhood}
              </p>
            </div>
            <div>
              <p>
                <strong>Cidade:</strong> {item.city}
              </p>
            </div>
            <div>
              <p>
                <strong>Estado:</strong> {item.uf}
              </p>
            </div>
            <div>
              <p>
                <strong>CEP:</strong> {item.cep}
              </p>
            </div>
            <div>
              <p>
                <strong>Telefone:</strong> {item.phone}
              </p>
            </div>
          </div>
        </section>

        <hr />

        <section>
          <header>
            <h2 className={styles.msgImportant}>IMPORTANTE SABER</h2>
          </header>

          <ul
            style={{
              display: 'flex',
              gap: '14px',
              flexDirection: 'column',
            }}
          >
            <li>Seguro de vigência anual com renovação automática.</li>

            <li>
              A falta de pagamento pode resultar na não cobertura e em seu
              cancelamento, conforme Condições da Apólice.
            </li>

            <li>
              Para este seguro, são riscos excluídos os eventos relacionados
              e/ou ocorridos em consequência de: <br />
              <br />
              a) quaisquer transações que não tenham ocorrido roubo do aparelho;
              <br />
              b) transações originadas de contas/cartões virtuais não vinculados
              ao segurado.
              <br />
              c) aparelhos celulares, smartphones, tablets ou qualquer
              equipamento de propriedade do segurado utilizado para realização
              da transação indevida, salvo se contratada cobertura específica.
              <br />
              d) atos ilícitos dolosos ou por culpa grave equiparável ao dolo
              praticados pelo segurado, pelo beneficiário ou pelo representante
              legal, de um ou de outro, em se tratando de segurado pessoa
              jurídica, a exclusão aqui estabelecida aplica-se aos sócios
              controladores, aos seus dirigentes, beneficiários e respectivos
              administradores e representantes legais;
              <br />
              e) atos praticados por ação ou omissão do segurado, causados por
              má-fé;
              <br />
              f) perdas decorrentes de fraude eletrônica, independente da
              origem;
              <br />
              g) atos de autoridade pública, salvo para evitar propagação de
              danos cobertos pela apólice/certificado de seguro;
              <br />
              h) perdas decorrentes de atos de terrorismo, guerra, “lockout”,
              rebelião, revoltas populares, sabotagem, insurreição, revolução,
              pilhagem ou atos similares, treinamento militar e operações
              bélicas, atos de hostilidade ou de autoridades, tais como
              confisco, nacionalização, destruição ou requisição, e quaisquer
              perturbações da ordem pública, decorrentes de qualquer ato de
              autoridade de fato ou de direito;
              <br />
              i) saques ou despesas realizados após o efetivo bloqueio da conta
              e/ou cartão virtual;
              <br />
              j) furto simples;
              <br />
              k) perdas ou erros de informações ocasionadas por falha de
              sistema;
              <br />
              l) danos morais;
              <br />
              m) danos corporais;
              <br />
              n) lucros cessantes
              <br />
              o) qualquer tipo de furto dos objetos deixados no interior de
              automóveis, salvo se ocorrer o furto total do veículo.perdas,
              danos, despesas ou custos classificadas como engenharia social
              dentro do contexto de segurança da informação, como por exemplo e
              não limitadas a manipulação psicológica com o objetivo de obter
              ganho ilícito;
              <br />
              p) perdas, danos, despesas ou custos classificadas como extorsão
              eletrônica como por exemplo e não limitadas links maliciosos com o
              objetivo de obter ganho ilícito;
              <br />
              Além dos riscos excluídos fica entendido e acordado que o
              pagamento da indenização do seguro pela seguradora está
              condicionado:
              <br />
              a) à instalação no aparelho celular do segurado de aplicativo
              indicado pela exa serviços de tecnologia s.a, ativação da
              assinatura e das <br />
              permissões indicadas como obrigatórias pela exa serviços de
              tecnologia s.a e execução de comandos remotos à época do sinistro,
              tudo em conformidade com as instruções da exa serviços de
              tecnologia s.a; e<br />
              b) única e exclusivamente ao roubo do celular do segurado, sem
              prejuízo do disposto acima e outras condições acordadas pelas
              partes. O segurado poderá perder o direito a qualquer indenização
              se não cumprida as seguintes condições no prazo de 48 (quarenta e
              oito) horas do roubo do celular:
              <br />
              a) a funcionalidade de location/localização não estiver ativada,
              independentemente da razão, quando à execução de tal
              funcionalidade à época do sinistro for tecnicamente viável;
              <br />
              b) não acionar o bloqueio do aparelho, quando à execução de tal
              funcionalidade à época do sinistro for tecnicamente viável; e
              <br />
              c) não tenha realizado o bloqueio do imei junto a anatel e/ou abr
              telecom.
            </li>

            <li>
              O Segurado poderá consultar o “MANUAL DO CLIENTE” no portal do
              PROTEÇÃO PIX ou{' '}
              <a href='https://protecaopix.com.br'>CLIQUE AQUI ACESSAR</a>.
            </li>
          </ul>
        </section>

        <hr />

        <section>
          <header>
            <h2 className={styles.msgImportant}>EM CASO DE SINISTRO</h2>
          </header>

          <ul
            style={{
              display: 'flex',
              gap: '14px',
              flexDirection: 'column',
            }}
          >
            <li>
              Acesse o site{' '}
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://protecaopix.com.br'
              >
                protecaopix.com.br
              </a>
            </li>

            <li>
              Ligue em sua Operadora de telefonia Móvel e faça o Bloqueio do
              IMEI do aparelho celular na ANATEL;
            </li>

            <li>Faça o Boletim de Ocorrência;</li>

            <li>
              Efetue os comandos de Bloqueio do Aparelho e Localização dentro do
              portal do{' '}
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://protecaopix.com.br'
              >
                protecaopix.com.br
              </a>
              ;
            </li>

            <li>
              Preencha todos os campos do AVISO de SINISTRO, detalhando as
              transações eletrônicas que foram realizadas;
            </li>

            <li>
              Envie Cópia do RG ou Carteira de Habilitação; ilitação; Boletim de
              Ocorrência e todos os extratos que comprovam as transações
              financeiras feitas com o roubo do aparelho celular.
            </li>

            <li>Boletim de Ocorrência;</li>

            <li>
              Todos os extratos que comprovam as transações ﬁnanceiras indevidas
              e contestadas mediante o roubo do aparelho celular.
            </li>
          </ul>
        </section>

        <hr />

        <section>
          <header>
            <h2>DECLARAÇÕES E AUTORIZAÇÕES</h2>
          </header>

          <p style={{ fontSize: '0.875rem' }}>
            Autorizo minha inclusão na apólice indicada acima, concedendo ao
            Estipulante o direito de agir em meu nome no cumprimento ou
            alterações de todas as cláusulas das condições gerais, especiais e
            contrato da apólice, a quem devo encaminhar todas as comunicações ou
            avisos inerentes ao contrato, que para tal ﬁm, ﬁca investido dos
            poderes de representação. Entretanto, os poderes de representação
            ora outorgados, não lhe dão o direito de cancelar o seguro aqui
            proposto, no decorrer de sua vigência, e nem reduzir minha
            importância segurada sem o meu expresso consentimento. Declaro: (1)
            Ter conhecimento prévio do conteúdo integral das Condições
            Contratuais do Seguro, estando ciente de seu teor, não possuindo
            qualquer dúvida que prejudique a exata compreensão do produto; (2)
            Que as informações por mim prestadas são verdadeiras e completas e
            que de acordo com o artigo 766 do Código Civil se eu ﬁzer
            declarações inexatas ou omitir circunstâncias que possam inﬂuir na
            aceitação da proposta ou na taxa do prêmio, perderei o direito à
            garantia e ﬁcarei obrigado ao pagamento do prêmio vencido. (3) Que
            as declarações que prestei fazem parte integrante do(s) contrato(s)
            de seguro(s) a ser celebrado(s) com a seguradora, ﬁcando a mesma
            autorizada a utilizá-las em qualquer época, no amparo e na defesa de
            seus direitos, sem que tal autorização implique ofensa ao sigilo
            proﬁssional. (4) Ter conhecimento de que a vigência do seguro será a
            partir das 24 (vinte e quatro) horas das datas especiﬁcadas nos
            campos de início e término de vigência expressos neste documento(5)
            Que a apólice poderá deixar de ser renovada em seu aniversário por
            decisão do estipulante ou da seguradora.
          </p>
        </section>

        <hr />

        <section>
          <header>
            <h2>DISPOSIÇÕES FINAIS</h2>
          </header>

          <p style={{ fontSize: '0.875rem' }}>
            Este certificado de seguro apresenta um resumo das principais
            condições gerais do produto. O registro do plano deste seguro na
            SUSEP Superintendência de Seguros Privados - autarquia federal
            responsável pela fiscalização, normatização e controle de mercados
            de seguro, previdência complementar aberta, capitalização, resseguro
            e corretagem de seguros, não implica, por parte da referida
            autarquia, incentivo ou recomendação à sua comercialização. As
            condições gerais do seguro na integra estão disponíveis para
            consulta no site:{' '}
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://www2.susep.gov.br/safe/menumercado/REP2/Produto.aspx/Consultar'
            >
              consultar
            </a>{' '}
            Este seguro é por prazo determinado tendo a seguradora a faculdade
            de não renovar á apólice na data de vencimento, sem devolução dos
            prêmios pagos nos termos da apólice. Telefone gratuito da SUSEP:
            0800 021 8484. Em atendimento a lei 12741/12 informamos que incidem
            as alíquotas de 0,65 de PIS/Pasep e de 4% de COFINS sobre os prêmios
            de seguro, deduzindo do estabelecido em legislação específica. A
            ouvidoria poderá ser acionada para atuar na defesa dos direitos dos
            consumidores, para prevenir, esclarecer e solucionar conflitos não
            atendidos pelos canais de atendimento habituais. Registro de
            Reclamação para o Consumidor{' '}
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://www.consumidor.gov.br'
            >
              www.consumidor.gov.br
            </a>
            . Seguem especificações do seguro: prêmio líquido: um real e setenta
            e nove centavos (IOF de quatorze centavos) - prêmio total com
            I.O.F.: um real e noventa e três centavos - forma de custeio:
            não-contributário, ou seja, o responsável pelo pagamento é o
            Estipulante.
          </p>
          <br />
        </section>

        <hr />

        <section>
          <header>
            <h2>Quer falar com a MAPFRE?</h2>
          </header>

          <p>
            SAC Serviço de Atendimento ao Consumidor 0800 775 4545 todos os
            dias, 24 horas.{' '}
          </p>
          <p>Deficientes auditivos e fala 0800 775 5045. </p>
          <p>
            Ouvidoria: 0800 775 1079 - De 2ª a 6ª feira, das 08h às 20h, exceto
            feriados nacionais. Deficientes Auditivos e Fala: 0800 962 7373.
          </p>
          <p>São Paulo, {moment().format('LL')}</p>
        </section>

        <div className={styles.footerCertificate}>
          <section className={styles.signature}>
            <figure>
              <img alt='signature' src={assinatura} />
            </figure>
          </section>
        </div>
      </main>
    </div>
  )
}

export default Certificate
