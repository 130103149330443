import fernet from 'fernet'

export function encodeData(data) {
  let secret = new fernet.Secret('r6TvBs0vQ8CUHujGElZArOL3D8lJUEyuOagGk1BmVPc=')
  let token = new fernet.Token({
    secret: secret,
    ttl: 0,
  })

  return token.encode(JSON.stringify(data))
}

export function decodeData(code) {
  let secret = new fernet.Secret('r6TvBs0vQ8CUHujGElZArOL3D8lJUEyuOagGk1BmVPc=')
  let token = new fernet.Token({
    secret: secret,
    token: code,
    ttl: 0,
  })

  return JSON.parse(token.decode())
}
