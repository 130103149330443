import React from 'react'

import styles from './styles.module.css'

const Toggle = ({ onChange, checked, dataTestId }) => {
  return (
    <label className={styles.switch}>
      <input
        type='checkbox'
        onChange={onChange}
        checked={checked}
        data-testid={dataTestId}
        name='checkbox'
        value={checked}
      />
      <i className={styles.slider}></i>
    </label>
  )
}

export default Toggle
