import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Loader from '@/components/Presentational/Loader'
import { getCertificate } from '@/services/APIService/Certificate'
import queryString from 'query-string'

import Template from './atoms/Template'

const Certificate = () => {
  const navigate = useNavigate()
  const [loader, setLoader] = useState(true)
  const [certificate, setCertificate] = useState({})

  const listCertificate = async (extref) => {
    const getCertificateResponse = await getCertificate(extref)

    if (!getCertificateResponse.status) {
      setLoader(false)
      navigate('/page-not-found')
      return
    }

    const response = getCertificateResponse.certificate.shift()

    setCertificate({
      socialName: response.social_name,
      certificateNumber: response.extref,
      policyNumber: response.insurance_policy_number,
      validityStart: response.incio_vigencia.split('-').reverse().join('/'),
      validityEnd: response.final_vigencia.split('-').reverse().join('/'),
      fullName: response.fullname,
      cpf: response.cpf,
      dateOfBirth: response.birth_date.split('-').reverse().join('/'),
      phone: response.phone,
      city: response.address.neighborhood.city.description,
      uf: response.address.neighborhood.city.uf.code,
      addressRisk: `${response.address.description}, ${response.address_number}`,
      neighborhood: response.address.neighborhood.description,
      cep: response.address.cep,
    })

    setLoader(false)
  }

  useEffect(() => {
    const { extref } = queryString.parse(window.location.search)

    if (extref === null || extref === undefined) {
      setLoader(false)
      navigate('/page-not-found')
      return
    }

    listCertificate(extref)
  }, [])

  return (
    <React.Fragment>
      {loader ? <Loader /> : <Template item={certificate} />}
    </React.Fragment>
  )
}

export default Certificate
