import React, { useEffect, useState } from 'react'

import Mapbox from '@/components/Presentational/Mapbox'
import { useAuth } from '@/contexts/Authentication'
import { usePermissions } from '@/contexts/Permissions'
import useToast from '@/hooks/useToast'
import { getLocation } from '@/services/APIService/Device'
import { postNotification } from '@/services/APIService/Notification'
import { getAddress } from '@/services/APIService/Utilities'
import { decodeData } from '@/utils/Fernet'

import Card from './atoms/Card'
import DeviceInfo from './atoms/DeviceInfo'
import * as S from './styled'

const Location = () => {
  const {
    user: {
      profile: { legacy_user_id, device_id, lastAction },
    },
  } = useAuth()
  const { permissions } = usePermissions()
  const { toastrMessage } = useToast()
  const [limitRequest, setLimitRequest] = useState(0)
  const [status, setStatus] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [spinner, setSpinner] = useState(false)
  const [deviceInfo, setDeviceInfo] = useState({
    latitude: 0,
    longitude: 0,
    battery: 0,
    locationOn: '',
    address: '',
  })

  const listLocation = async (isOpenDeviceInfo = true) => {
    try {
      setIsOpen(false)
      setSpinner(true)

      await postNotification(device_id, 'location')

      await new Promise((resolve) => setTimeout(resolve, 2000))

      const getLocationResponse = await getLocation(legacy_user_id)

      const locationData = await decodeData(getLocationResponse.location)

      const getAddressResponse = await getAddress(
        Number(locationData.latitude),
        Number(locationData.longitude)
      )

      const address = getAddressResponse.address[1].long_name
      const neighborhood = getAddressResponse.address[2].long_name
      const country = getAddressResponse.address[4].short_name

      setDeviceInfo({
        latitude: Number(locationData.latitude),
        longitude: Number(locationData.longitude),
        locationOn: locationData.locationOn,
        battery: locationData.battery,
        address: `${address}, ${neighborhood}, ${country}`,
      })

      setIsOpen(isOpenDeviceInfo)
      setSpinner(false)
    } catch (error) {
      setSpinner(false)
      toastrMessage('error', error.message)
    }
  }

  useEffect(() => {
    if (limitRequest > 0) {
      const interval = setInterval(() => {
        listLocation(limitRequest === 1 ? true : false)
        setLimitRequest((prevState) => prevState - 1)
      }, 120000)

      return () => clearInterval(interval)
    }
  }, [limitRequest])

  useEffect(() => {
    if (permissions === null) {
      setSpinner(true)
      return
    }

    if (!permissions.location) {
      setStatus(permissions.location)
      setSpinner(false)
      return
    }
    

    if (permissions.location && lastAction === 'PANIC_BUTTON') {
      setStatus(permissions.location)
      setLimitRequest(4)
      return
    }

    if (permissions.location) {
      setStatus(permissions.location)
      listLocation()
      return
    }
  }, [permissions, lastAction])

  return (
    <S.Container className='map' data-testid='map-location'>
      <Mapbox latitude={deviceInfo.latitude} longitude={deviceInfo.longitude} />
      <S.Content>
        <Card
          status={status}
          spinner={spinner}
          disabled={lastAction === 'CLEANED' || lastAction === 'LOGGED_OUT'}
          onClick={() => listLocation()}
        />
        {isOpen && (
          <DeviceInfo
            battery={deviceInfo.battery}
            date={deviceInfo.locationOn}
            time={deviceInfo.locationOn}
            address={deviceInfo.address}
            onClose={() => setIsOpen(false)}
          />
        )}
      </S.Content>
    </S.Container>
  )
}
export default Location
