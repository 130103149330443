import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import ClaimsContainer from '@/components/Containers/Claims'
import { FormProvider } from '@/components/Containers/Claims/contexts/Forms'
import { CollapseProvider } from '@/components/Presentational/Collapsible/contexts/Collapse'
import { useAuth } from '@/contexts/Authentication'
import Layout from '@/layout'

const Claims = () => {
  const { user } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    !user.profile.subscription.premium && navigate('/dashboard')
  }, [])

  return (
    <Layout bottomSize>
      <CollapseProvider>
        <FormProvider>
          <ClaimsContainer />
        </FormProvider>
      </CollapseProvider>
    </Layout>
  )
}
export default Claims
