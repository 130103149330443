import styled from 'styled-components'

export const Footer = styled.footer`
  background-color: hsla(0, 0%, 96%, 1);
  border-top: 1px solid hsla(0, 0%, 89%, 1);
  padding: 30px 0;

  > ul {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1300px;
    width: 100vw;
    list-style-type: none;

    > li {
      color: hsla(0, 0%, 53%, 1);
      font-size: 0.8rem;

      > a {
        text-decoration: none;
        color: hsla(0, 0%, 53%, 1);
      }
    }
  }

  @media screen and (max-width: 767px) {
    > ul {
      flex-direction: column;
      gap: 20px;
    }
  }
`
