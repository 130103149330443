import styled from 'styled-components'

export const Details = styled.details`
  padding: 20px 0px;
  border-bottom: ${({ index }) =>
    index !== 4 ? ' 1px solid hsla(0, 0%, 89%, 1)' : ''};

  > div {
    padding-top: 20px;

    > ul {
      margin-left: 40px;
    }
  }
`

export const Summary = styled.summary`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style: none;
  cursor: pointer;
  font-weight: bold;
  width: 100%;

  details[open] &:after {
    border-style: solid;
    border-width: 0.2em 0.2em 0 0;
    content: '';
    display: inline-block;
    height: 0.45em;
    position: relative;
    top: 0.15em;
    vertical-align: top;
    width: 0.45em;
    left: 0;
    transform: rotate(45deg);
    color: #004ac2;
  }

  &::after {
    border-style: solid;
    border-width: 0.2em 0.2em 0 0;
    content: '';
    display: inline-block;
    height: 0.45em;
    position: relative;
    top: 0.15em;
    vertical-align: top;
    width: 0.45em;
    top: 0;
    transform: rotate(135deg);
    color: #004ac2;
  }

  > h5 {
    font-family: 'Space Grotesk';
    font-size: 24px;
    font-weight: 500;
    color: #121212;
  }
`
