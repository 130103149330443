import React from 'react'
import Select from 'react-select'

const SelectSearch = ({
  id,
  name,
  value,
  options,
  placeholder = 'Selecione',
  onChange,
  loader = false,
  required = false,
  disabled,
}) => {
  return (
    <Select
      id={id}
      name={name}
      isSearchable
      isClearable
      value={value}
      isLoading={loader}
      onChange={(e) => onChange({ ...e, name: name })}
      required={required}
      disabled={disabled}
      options={options}
      placeholder={placeholder}
      noOptionsMessage={() => 'Nenhum registro encontrado.'}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          backgroundColor: 'hsla(0, 0%, 100%, 1)',
          border: '2px solid #1f1f1f',
          borderRadius: '8px',
          color: 'var(--color-font-grey-clear)',
          fontSize: 16,
          lineHeight: '20px',
          width: '100%',
        }),
      }}
    />
  )
}

export default SelectSearch
