import React from 'react'

import * as S from './styled'

const ImageGallery = ({
  images,
  onSelecting,
  checkedCards,
  onClickRemove,
  isChecked,
  onChange,
  modal,
}) => {
  return (
    <div className='flow'>
      <div className='gallery'>
        <S.Actions>
          <S.Items>
            <input
              type='checkbox'
              id='check'
              name='check'
              value='true'
              checked={isChecked}
              onChange={onChange}
            />
            Selecionar tudo
          </S.Items>
          <S.Items>
            <p style={{ margin: 0 }}>
              {checkedCards.length > 1
                ? `${checkedCards.length} seleções`
                : `${checkedCards.length} seleção`}
            </p>
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              style={{ cursor: 'pointer' }}
              onClick={() => onClickRemove()}
            >
              <path
                d='M5 11C5 11 6.25 9.5 7 8.6C7.25 8.3 7.56338 8 8 8C8.43662 8 8.75 8.3 9 8.6C9.75 9.5 11 11 11 11'
                stroke='#004BC1'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M11 5C11 5 9.75 6.5 9 7.4C8.75 7.7 8.43662 8 8 8C7.56338 8 7.25 7.7 7 7.4C6.25 6.5 5 5 5 5'
                stroke='#004BC1'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <circle
                cx='8'
                cy='8'
                r='7.25'
                stroke='#004BC1'
                strokeWidth='1.5'
              />
            </svg>
          </S.Items>
        </S.Actions>
        <S.Container>
          {Object.keys(images).map((date) => (
            <div
              key={date}
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 5,
                marginBottom: 20,
              }}
            >
              <span style={{ fontSize: 12, marginBottom: 10 }}>{date}</span>
              <ul className='list-photo'>
                {images[date].map((img, idx) => (
                  <li key={idx}>
                    <input
                      name='check-image'
                      type='checkbox'
                      data-testid={`input-${img.id}`}
                      value={img.id}
                      checked={checkedCards.includes(img.id)}
                      onChange={onSelecting}
                    />
                    <img src={img.download_url} onClick={modal} alt='' />
                    <a
                      href={img.download_url}
                      className='btDownload'
                      download='imagem.jpg'
                    >
                      <svg
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M3 15C3.00765 17.1501 3.068 18.336 3.54386 19.27C4.02322 20.2108 4.78813 20.9757 5.72894 21.455C6.79849 22 8.19863 22 10.9989 22H12.9993C15.7995 22 17.1997 22 18.2692 21.455C19.21 20.9757 19.9749 20.2108 20.4543 19.27C20.9302 18.336 20.9905 17.1501 20.9982 15H18.9981C18.9948 15.902 18.9826 16.5733 18.9378 17.1211C18.8798 17.8312 18.7772 18.1561 18.6723 18.362C18.3847 18.9265 17.9257 19.3854 17.3612 19.673C17.1554 19.7779 16.8305 19.8805 16.1204 19.9385C15.387 19.9984 14.4324 20 12.9993 20H10.9989C9.56575 20 8.61119 19.9984 7.87776 19.9385C7.16768 19.8805 6.84276 19.7779 6.63692 19.673C6.07243 19.3854 5.61349 18.9265 5.32587 18.362C5.22099 18.1561 5.11838 17.8312 5.06037 17.1211C5.0156 16.5733 5.00341 15.902 5.00011 15H3Z'
                          fill='#004BC1'
                        />
                        <path
                          d='M5 9L6.79367 10.5374C8.64154 12.1213 9.56547 12.9133 10.6016 13.2151C11.5149 13.4811 12.4851 13.4811 13.3984 13.2151C14.4345 12.9133 15.3585 12.1213 17.2063 10.5374L19 9'
                          stroke='#004BC1'
                          strokeWidth={2}
                          strokeLinecap='round'
                        />
                        <path
                          d='M12 13V2'
                          stroke='#004BC1'
                          strokeWidth={2}
                          strokeLinecap='round'
                        />
                      </svg>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </S.Container>
      </div>
    </div>
  )
}

export default ImageGallery
