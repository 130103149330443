import React from 'react'

import IconChevronRight from '@/components/Presentational/Icons/IconChevronRight'

import * as S from './styled'

const Breadcrumbs = ({ display, category, handleClickButtonGoBack }) => {
  return (
    <S.Breadcrumbs>
      <ul>
        <li onClick={() => handleClickButtonGoBack('stepOne')}>
          Início <IconChevronRight />
        </li>
        <li onClick={() => handleClickButtonGoBack('stepOne')}>Ajuda</li>
        {display.stepFive && (
          <li onClick={() => handleClickButtonGoBack('stepFive')}>
            <IconChevronRight />
            Perguntas sobre {category[0].toLowerCase() + category.slice(1)}
          </li>
        )}
        {display.stepThree && (
          <li onClick={() => handleClickButtonGoBack('stepThree')}>
            <IconChevronRight />
            Todas as perguntas
          </li>
        )}
      </ul>
    </S.Breadcrumbs>
  )
}

export default Breadcrumbs
