import React, { useEffect, useState } from 'react'

import { remoteConfig } from '@/firebase'
import Layout from '@/layout/External'
import { fetchAndActivate, getAll } from 'firebase/remote-config'

import AllAnswers from './atoms/AllAnswers'
import Breadcrumbs from './atoms/Breadcrumbs'
import Card from './atoms/Card'
import Details from './atoms/Details'
import ShimmerEffect from './atoms/ShimmerEffect'
import Title from './atoms/Title'
import { CARDS, CATEGORY_DEFAULT } from './constants'
import * as S from './styled'

remoteConfig.settings.minimumFetchIntervalMillis = 3600000

const Faq = () => {
  const [shimmer, setShimmer] = useState(true)
  const [faq, setFaq] = useState([])
  const [search, setSearch] = useState('')
  const [searchData, setSearchData] = useState([])
  const [category, setCategory] = useState(CATEGORY_DEFAULT)
  const [display, setDisplay] = useState({
    stepOne: true,
    stepTwo: false,
    stepThree: false,
    stepFour: true,
    stepFive: false,
    showInput: false,
  })

  const searchItem = (query) => {
    setSearch(query)

    if (!query) {
      setDisplay({
        stepOne: false,
        stepTwo: false,
        stepThree: true,
        stepFour: false,
        showInput: true,
      })
      return
    }

    setDisplay({
      stepOne: false,
      stepTwo: true,
      stepThree: false,
      stepFour: true,
      showInput: true,
    })

    const searchData = faq.filter(({ question }) =>
      question.toLowerCase().includes(query.toLowerCase())
    )

    setSearchData(searchData)
  }

  const handleClickButtonCard = (categoryType) => {
    setCategory(categoryType)
    setDisplay({
      ...display,
      showInput: false,
      stepFive: true,
    })
    window.scrollTo(0, 0)
  }

  const handleClickButtonGoBack = (type) => {
    if (type === 'stepOne') {
      setCategory(CATEGORY_DEFAULT)
      setDisplay({
        ...display,
        stepOne: true,
        stepThree: false,
        stepFour: true,
        stepFive: false,
        showInput: false,
      })
      return
    }

    if (type === 'stepFive') {
      setDisplay({
        ...display,
        stepOne: true,
        stepFive: true,
        stepFour: true,
      })
      return
    }

    setDisplay({
      ...display,
      stepOne: false,
      stepThree: true,
    })
  }

  useEffect(() => {
    fetchAndActivate(remoteConfig)
      .then(() => {
        const config = getAll(remoteConfig)
        console.log('[getFaq-response]:', JSON.parse(config.faq._value))
        setShimmer(false)
        setFaq(JSON.parse(config.faq._value))
      })
      .catch(() => {
        setShimmer(false)
      })
  }, [])

  return (
    <Layout>
      <Breadcrumbs
        display={display}
        category={category}
        handleClickButtonGoBack={handleClickButtonGoBack}
      />
      <Title
        title={
          display.stepThree
            ? 'Todas as perguntas'
            : display.stepFive
              ? `Sobre ${category.toLowerCase()}`
              : 'Ajuda com Proteção Pix'
        }
        description='Quer saber mais sobre Proteção Pix e nossos produtos? Aqui você
            encontra respostas para as dúvidas mais comuns.'
        placeholder='Escreva sua dúvida ou o que procura. Ex.: Como funciona o acesso remoto?'
        search={search}
        onChange={(e) => searchItem(e.target.value)}
        onClearData={() => searchItem('')}
        showInput={display.showInput}
      />
      <S.Answers>
        {display.stepOne && (
          <React.Fragment>
            {!display.stepFive && <h3>Perguntas frequentes</h3>}
            {shimmer ? (
              <ShimmerEffect />
            ) : (
              faq
                .filter((item) => item.category.name === category)
                .map((item, key) => (
                  <Details
                    key={key}
                    question={item.question}
                    answer={item.answer}
                  />
                ))
            )}

            {!display.stepFive && (
              <S.Button
                type='button'
                onClick={() =>
                  setDisplay({
                    stepOne: false,
                    stepTwo: false,
                    stepThree: true,
                    stepFour: false,
                    showInput: true,
                  })
                }
              >
                Ir para todas as perguntas
              </S.Button>
            )}
          </React.Fragment>
        )}

        {display.stepTwo && (
          <React.Fragment>
            <h3>Resultado da busca</h3>
            {searchData.length > 0 ? (
              searchData.map((item, key) => (
                <Details
                  key={key}
                  question={item.question}
                  answer={item.answer}
                />
              ))
            ) : (
              <p>Nenhum registro encontrado.</p>
            )}
          </React.Fragment>
        )}

        {display.stepThree && <AllAnswers faq={faq} />}
      </S.Answers>
      {display.stepFour && (
        <S.HelpCenter>
          <h3>Central de ajuda</h3>
          <div>
            {CARDS.map((item, index) => (
              <Card
                key={index}
                title={item.title}
                description={item.description}
                image={item.image}
                onClick={() => handleClickButtonCard(item.category.name)}
              />
            ))}
          </div>
        </S.HelpCenter>
      )}
    </Layout>
  )
}

export default Faq
