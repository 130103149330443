import styled from 'styled-components'

export const Section = styled.footer`
  display: grid;
  grid-column: 1/-1;
  background-color: var(--cl_grey-clear);
  padding: 62px 16px 32px 16px;
  margin-top: 1px solid var(--cl_grey-clear1);
`

export const Container = styled.div`
  display: grid;
  grid-template-areas:
    'doubt usefulLinks'
    'copyUseFullLinks copyUseFullLinks';
  grid-template-columns: repeat(2, 1fr);
  row-gap: 130px;
  margin: 0 auto;
  max-width: 1366px;
  width: 100%;

  @media only screen and (min-width: 148px) and (max-width: 719px) {
    grid-template-areas:
      'doubt'
      'usefulLinks'
      'copyUseFullLinks';
    grid-template-columns: 1fr;
    row-gap: 70px;
  }
`

export const Content = styled.div`
  grid-area: doubt;

  h2 {
    color: var(--cl_blue);
    font-family: 'Space Grotesk', sans-serif;
    font-size: 42px;
    font-weight: 500;
    letter-spacing: -5%;
    line-height: 120%;
    margin-bottom: 40px;
  }

  a {
    color: var(--cl_blue);
    font-family: 'Space Grotesk', sans-serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -2%;
    line-height: 130%;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  @media only screen and (min-width: 148px) and (max-width: 719px) {
    margin: auto 10px;

    h2 {
      font-size: 28px;
    }
  }
`

export const UsefulLinks = styled.div`
  grid-area: usefulLinks;

  ul {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  li {
    align-items: center;
    display: flex;
    gap: 20px;
    list-style-type: none;
    justify-content: flex-start;
  }

  li a {
    color: var(--cl_blue);
    font-family: 'Space Grotesk', sans-serif;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -3%;
    line-height: 130%;
    text-decoration: none;
  }

  li a:hover {
    text-decoration: underline;
  }

  li:hover img {
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
`

export const CopyUseFullLinks = styled.div`
  display: grid;
  grid-area: copyUseFullLinks;

  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: space-between;
  }

  ul :where(li, a) {
    color: var(--cl_grey-dark);
    font-family: 'Space Grotesk', sans-serif;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -2%;
    line-height: 130%;
    list-style-type: none;
    text-decoration: none;
  }

  li a:hover {
    text-decoration: underline;
  }
`
