export default function useEvents() {
  function listEvent(eventName, listener) {
    document.addEventListener(eventName, listener)
  }

  function removeEvent(eventName, listener) {
    document.removeEventListener(eventName, listener)
  }

  function customEvent(eventName, data) {
    const event = new CustomEvent(eventName, { detail: data })
    document.dispatchEvent(event)
  }

  return { customEvent, listEvent, removeEvent }
}
