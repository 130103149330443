import { getAnalytics, logEvent, setUserId } from 'firebase/analytics'

export const setAnalyticsUid = (user_id) => {
  const analytics = getAnalytics()
  setUserId(analytics, user_id)
}

/**
 * Function Click Analytics Event
 */
export function logAnalyticsEvent(eventName, data) {
  const analytics = getAnalytics()
  return logEvent(analytics, eventName, data)
}
