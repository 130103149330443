import axios from '@/services/BaseService'
import { decodeData, encodeData } from '@/utils/Fernet'
import { captureException } from '@/utils/Instrumentation/Exception'
import { translateMessage } from '@/utils/Translator'

export async function getUserData(email) {
  const endpoint = `/v1/user/search/email/${email}`
  try {
    const response = await axios.get(endpoint)

    return {
      status: true,
      userData: decodeData(response),
    }
  } catch (error) {
    captureException({
      error,
      tag: 'USER_GET_DATA_EXCEPTION',
      endpoint,
      body: {
        email,
      },
    })

    return {
      status: false,
      message: translateMessage(error.message),
    }
  }
}

export async function getUserCapturedImages(userId) {
  const endpoint = `/v1/images/download?userId=${userId}`
  try {
    const response = await axios.get(endpoint)

    return {
      status: true,
      images: response.downloadLinks,
    }
  } catch (error) {
    captureException({
      error,
      tag: 'USER_GET_CAPTURED_IMAGES_EXCEPTION',
      endpoint,
      body: {
        userId,
      },
    })

    return {
      status: false,
      message: translateMessage(error.message),
    }
  }
}

export async function updateAddressUser(
  msisdn,
  {
    zipcode,
    state,
    city,
    neighborhood,
    address,
    address_number,
    address_complement,
  }
) {
  const endpoint = `/v1/user/update_address/${msisdn}`

  try {
    const body = encodeData({
      msisdn: msisdn,
      address: address,
      address_complement: address_complement,
      address_number: address_number,
      neighborhood: neighborhood,
      city: city,
      zipcode: zipcode,
      state: state,
    })

    await axios.post(endpoint, body, {
      headers: { 'Content-Type': 'text/plain' },
    })

    return {
      status: true,
    }
  } catch (error) {
    captureException({
      error,
      tag: 'USER_UPDATE_ADDRESS_EXCEPTION',
      endpoint,
      body: {
        msisdn,
        zipcode,
        state,
        city,
        neighborhood,
        address,
        address_number,
        address_complement,
      },
    })

    return {
      status: false,
      message: translateMessage(error.message),
    }
  }
}

export async function deleteAccountUser(email) {
  const endpoint = `/v1/user/delete/${email}`
  try {
    await axios.delete(endpoint)

    return {
      status: true,
    }
  } catch (error) {
    captureException({
      error,
      tag: 'USER_DELETE_ACCOUNT_EXCEPTION',
      endpoint,
      body: {
        email,
      },
    })

    return {
      status: false,
      message: translateMessage(error.message),
    }
  }
}

export async function deleteUserImages(userId, deleteId) {
  const endpoint = '/v1/images/delete'
  try {
    await axios.delete(endpoint, {
      data: {
        userId: userId,
        deleteId: deleteId,
      },
    })

    return {
      status: true,
    }
  } catch (error) {
    captureException({
      error,
      tag: 'USER_DELETE_CAPTURED_IMAGES_EXCEPTION',
      endpoint,
      body: {
        userId,
        deleteId,
      },
    })

    return {
      status: false,
      message: translateMessage(error.message),
    }
  }
}
