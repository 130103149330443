import React, { useCallback, useEffect, useRef, useState } from 'react'

import { LOGOUT_TIME, SESSION_SECOND_TIME, SESSION_TIME } from '@/constants'
import { useAuth } from '@/contexts/Authentication'

import styles from './styles.module.css'

const RESET_TIMER = 0

const SessionTime = () => {
  const { signOut } = useAuth()
  const timeoutId = useRef(null)
  const logoutId = useRef(null)
  const [logoutTime, setLogoutTime] = useState(RESET_TIMER)
  const [sessionTime, setSessionTime] = useState(() => {
    const savedItem = sessionStorage.getItem('_su')
    return savedItem || SESSION_TIME
  })

  const updateSessionTime = () => {
    setLogoutTime(RESET_TIMER)
    setSessionTime(SESSION_SECOND_TIME)
    sessionStorage.setItem('_su', SESSION_SECOND_TIME)
  }

  const countTimer = useCallback(() => {
    if (sessionTime <= 0) {
      setLogoutTime(LOGOUT_TIME)
      sessionStorage.clear('_su')
    } else {
      setSessionTime(sessionTime - 1)
      sessionStorage.setItem('_su', sessionTime - 1)
    }
  }, [sessionTime])

  const countTimerLogout = useCallback(() => {
    if (logoutTime === 1) {
      signOut()
    } else {
      setLogoutTime(logoutTime - 1)
    }
  }, [logoutTime])

  useEffect(() => {
    timeoutId.current = window.setTimeout(countTimer, 1000)
    return () => window.clearTimeout(timeoutId.current)
  }, [sessionTime, countTimer])

  useEffect(() => {
    if (logoutTime > 0) {
      logoutId.current = window.setTimeout(countTimerLogout, 1000)
      return () => window.clearTimeout(logoutId.current)
    }
  }, [logoutTime, countTimerLogout])

  if (sessionTime === 0) {
    return (
      <div className={styles['overlay']}>
        <div id='present-user' className={styles['present-user']}>
          <p>Você ainda está usando nosso portal?</p>
          <button onClick={() => updateSessionTime()}>
            <svg
              width={24}
              height={24}
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M14.0613 22.0607C13.4755 22.6464 12.5257 22.6464 11.9399 22.0607C11.3542 21.4749 11.3542 20.5251 11.9399 19.9393L18.1115 13.7678C18.2017 13.6776 18.2835 13.5822 18.3571 13.4827L3.00159 13.4989C2.17316 13.4998 1.50088 12.829 1.5 12.0005C1.49912 11.1721 2.16998 10.4998 2.99841 10.4989L18.331 10.4827C18.2643 10.3956 18.1912 10.3119 18.1115 10.2322L11.9399 4.06066C11.3542 3.47487 11.3542 2.52513 11.9399 1.93934C12.5257 1.35355 13.4755 1.35355 14.0613 1.93934L20.2328 8.11091C22.3807 10.2588 22.3807 13.7412 20.2328 15.8891L14.0613 22.0607Z'
                fill='#FFFFFF'
              />
            </svg>
          </button>
        </div>
      </div>
    )
  }

  return null
}

export default SessionTime
