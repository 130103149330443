import React from 'react'

import * as S from './styled'

const EmptyData = () => {
  return (
    <React.Fragment>
      <p>
        Descubra quem está usando seu aparelho!
        <br />
        <br />
        <br />
        <S.Span>Você ainda não possui nenhuma foto na galeria.</S.Span>
      </p>
    </React.Fragment>
  )
}

export default EmptyData
