import React, { useEffect, useState } from 'react'

import ICON_CHAT from '@/assets/img/icon-balao-chatbot.png'
import { URL_CHATBOT } from '@/constants'
import { useAuth } from '@/contexts/Authentication'
import { logAnalyticsEvent } from '@/services/Analytics'

import * as Styled from './styled'

const Chatbot = ({ bottomSize = false }) => {
  const {
    mobile: { mDeviceMarketName },
  } = useAuth()
  const [isOpenChatbot, setIsOpenChatbot] = useState(false)

  useEffect(() => {
    const url = window.location.href
    const verifyParams = url.split('?').includes('show-bot')
    setIsOpenChatbot(verifyParams)
  }, [])

  if (isOpenChatbot) {
    return (
      <Styled.ChatbotContainer>
        <Styled.ChatbotClose onClick={() => setIsOpenChatbot(false)}>
          Fechar
        </Styled.ChatbotClose>
        <Styled.Iframe
          id='iframe'
          src={`${URL_CHATBOT}?p=protecao-pix`}
          title='Chatbot'
          frameBorder='0'
          width='400'
        />
      </Styled.ChatbotContainer>
    )
  }

  return (
    <Styled.Chatbot id='chat-icon'>
      <Styled.ChatbotImage
        bottomSize={bottomSize}
        id='chat-image'
        onClick={() => {
          logAnalyticsEvent('chatbot', {
            device_model: mDeviceMarketName,
          })
          setIsOpenChatbot(true)
        }}
        alt='Chatbot'
        src={`${ICON_CHAT}`}
      />
    </Styled.Chatbot>
  )
}

export default Chatbot
