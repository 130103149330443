import React from 'react'

import Chatbot from '@/components/Containers/Chatbot'
import SessionTime from '@/components/Containers/SessionTime'
import Footer from '@/components/Presentational/Footer'
import Header from '@/components/Presentational/Header'
import Menu from '@/components/Presentational/Menu'
import MenuHelp from '@/components/Presentational/MenuHelp'
import { PermissionsProvider } from '@/contexts/Permissions'
import { SinistersProvider } from '@/contexts/Sinisters'
import { ToastProvider } from '@/contexts/Toast'

const Layout = ({ bottomSize, children }) => {
  return (
    <PermissionsProvider>
      <SinistersProvider>
        <ToastProvider>
          <div className='app'>
            <Header />
            <main className='main'>
              <div className='content-center'>{children}</div>
              <Menu />
              <MenuHelp />
            </main>
            <Footer />
            <Chatbot bottomSize={bottomSize} />
            <SessionTime />
          </div>
        </ToastProvider>
      </SinistersProvider>
    </PermissionsProvider>
  )
}

export default Layout
