import React from 'react'

import { InputContainer } from './styled'

const Input = ({
  type,
  placeholder,
  name,
  value,
  required = false,
  onChange,
  disabled,
  onFocus,
  onBlur,
  max,
  min,
}) => {
  return (
    <InputContainer
      type={type}
      placeholder={placeholder}
      name={name}
      required={required}
      onChange={onChange}
      value={value}
      disabled={disabled}
      onFocus={onFocus}
      onBlur={onBlur}
      max={max}
      min={min}
    />
  )
}

export default Input
