import React from 'react'

const IconCheckIn = ({ color = '#123382' }) => {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      data-testid='icon-check-in'
    >
      <circle
        cx='12.5'
        cy='12'
        r='10.75'
        fill={color}
        stroke='#FCFCFC'
        strokeWidth='1.5'
      />
      <path
        d='M17.25 10L12.3321 14.9179C11.9416 15.3084 11.3084 15.3084 10.9179 14.9179L8.5 12.5'
        stroke='#FDFDFD'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default IconCheckIn
