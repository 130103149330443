import React from 'react'
import { useNavigate } from 'react-router-dom'

import BT_AS from '@/assets/img/bt-apple-store.svg'
import BT_PS from '@/assets/img/bt-play-store.svg'
import CIRCLEEXACT from '@/assets/img/circle-exact.svg'
import DRAWWOMAN from '@/assets/img/draw-woman.svg'
import EXAMPLEPORTAL from '@/assets/img/example-portal.png'
import DRAWMAN from '@/assets/img/homem-computador.svg'
import IC_ARROWRIGHT from '@/assets/img/ic-out-arrow-right.svg'
import TINNSIDE from '@/assets/img/logo_tiinside.png'
import ACESSOWIFI from '@/assets/img/logo-acessowifi.png'
import LOGOGOSAFE from '@/assets/img/logo-gosafe.svg'
import LPNEWS from '@/assets/img/logo-lpnews.png'
import MINHAOPERADORA from '@/assets/img/logo-minha-operadora.png'
import LOGOPROTECAOPIX from '@/assets/img/logo-protecao-pix.svg'
import SEGS from '@/assets/img/logo-segs.png'
import SHIELDWOMAN from '@/assets/img/mulher-escudo.svg'
import PERSOCEL from '@/assets/img/personagem-celular.svg'
import SUSEP from '@/assets/img/susep.svg'
import Chatbot from '@/components/Containers/Chatbot'
import { URL_APP_STORE, URL_GOOGLE_PLAY, URL_UNIFICADA } from '@/constants'
import { SettingsProvider } from '@/contexts/Settings'

import Doubts from './atoms/Doubts'
import { LIST_CARDS_1, LIST_CARDS_2, LIST_CARDS_3 } from './constants'
import styles from './styles.module.css'

const Home = () => {
  const navigate = useNavigate()

  return (
    <SettingsProvider>
      <div className={styles.grid} data-testid='home'>
        <div className={styles.main}>
          <section className={styles.headerTopo}>
            <div className={styles.layerOne}>
              <svg
                viewBox='0 0 961 800'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M503.498 316C74 464.657 0.998047 800 0.998047 800H960.998V0C960.998 0 932.996 167.343 503.498 316Z'
                  fill='white'
                />
              </svg>
            </div>

            <div className={styles._content}>
              <div className={styles.layerContent}>
                <div className={styles.headerDefault}>
                  <a href='/'>
                    <img src={LOGOPROTECAOPIX} alt='Logo Proteção PIX' />
                  </a>

                  <button
                    onClick={() => {
                      navigate('/login')
                    }}
                    className={styles.actionButton}
                    data-testid='login'
                  >
                    Acessar Portal
                    <img src={IC_ARROWRIGHT} alt='Seta direita' />
                  </button>
                </div>

                <section className={styles.__content}>
                  <header>
                    <h1>
                      Proteção Pix: <br /> segurança completa em um único
                      aplicativo
                    </h1>
                    <p>
                      Proteção contra transações financeiras em caso de roubo do
                      celular. Com o Proteção Pix, você tem controle completo do
                      seu dispositivo mesmo à distância.
                    </p>
                    <img
                      src={PERSOCEL}
                      alt='Personagem ao celular'
                      className={styles.hide_desk}
                    />
                    <br />
                    <p>Faça o download agora:</p>
                    <ul>
                      <li>
                        <a href={URL_APP_STORE}>
                          <img src={BT_AS} alt='Botão Apple Store' />
                        </a>
                      </li>

                      <li>
                        <a href={URL_GOOGLE_PLAY}>
                          <img src={BT_PS} alt='Botão Play Store' />
                        </a>
                      </li>
                    </ul>

                    <div className={styles.work}>
                      <img src={LOGOGOSAFE} alt='Logo EXA' />
                    </div>
                  </header>
                  <div className={styles._image}>
                    <img src={PERSOCEL} alt='Personagem ao celular' />
                  </div>
                </section>
              </div>
            </div>
          </section>

          <section className={styles.newInfosPortal}>
            <div className={styles.layerOne}>
              <svg
                viewBox='0 0 960 800'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M457.5 484C886.998 335.343 960 0 960 0H6.10352e-05V800C6.10352e-05 800 28.002 632.657 457.5 484Z'
                  fill='#004BC1'
                />
              </svg>
            </div>

            <div className={styles._content}>
              <section className={styles.__content}>
                <div>
                  <img src={EXAMPLEPORTAL} alt='Exemplo portal' />
                </div>

                <section className={styles._infos}>
                  <header className={styles.title_h2}>
                    <h2>Celular perdido ou roubado?</h2>

                    <p>
                      Com nosso aplicativo instalado, você mantém seu aparelho
                      configurado e seguro.
                    </p>
                    <p>
                      E com nosso portal de proteção, você acessa seu celular de
                      qualquer lugar.
                    </p>
                  </header>
                </section>
              </section>

              <section className={styles.listCommands}>
                <ul>
                  {LIST_CARDS_1.map((items, key) => (
                    <li key={key}>
                      <h3>{items.title}</h3>
                      <p>{items.description}</p>
                      <hr />
                      <a href={items.url_redirect}>Quero saber mais</a>
                    </li>
                  ))}
                </ul>
              </section>

              <section className={styles.downloadInfo}>
                <header className={styles.title_h2}>
                  <h2>Controle tudo a qualquer hora e de qualquer lugar</h2>
                  <a
                    href={URL_UNIFICADA}
                    target='__blank'
                    style={{
                      background: '#d03d7f',
                      textDecoration: 'none',
                      color: '#fff',
                      padding: 20,
                      borderRadius: 10,
                      width: '20%',
                      textAlign: 'center',
                    }}
                  >
                    Baixar Proteção Pix
                  </a>
                </header>
              </section>
            </div>
          </section>

          <div className={styles.theftInfo}>
            <div className={styles._content}>
              <div className={styles._image}>
                <img
                  src={SHIELDWOMAN}
                  alt='Mulher vendo tablet dentro de um escudo'
                />
              </div>

              <section className={styles._infos}>
                <header className={styles.title_h2}>
                  <h2>
                    Seu celular foi roubado e fizeram transferências em seu
                    nome?
                  </h2>

                  <p>Não se preocupe.</p>
                  <p>
                    Com Proteção Pix, você tem a segurança de receber seu
                    dinheiro de volta e mais tranquilidade no seu dia a dia.
                  </p>
                </header>

                <ul>
                  <li>
                    <img src={CIRCLEEXACT} alt='Ícone circulo certo' />
                    <p>
                      Seguro contra transações financeiras: <br /> Pix, TED, TEF
                      e boletos.
                    </p>
                  </li>

                  <li>
                    <img src={CIRCLEEXACT} alt='Ícone circulo certo' />
                    <p>Indenizações de até R$ 10.000,00</p>
                  </li>
                </ul>

                <div className={styles.susep}>
                  <img src={SUSEP} alt='Logo SUSEP' />
                  <p>
                    Nosso seguro é regulado pela SUSEP, orgão <br />
                    fiscalizador da industria de seguros brasileira
                  </p>
                </div>
              </section>
            </div>
          </div>

          <div className={styles._content}>
            <section className={styles.protect}>
              <header className={styles.title_h2}>
                <h2>
                  Proteção para sua vida <br />
                  digital
                </h2>

                <p>
                  Sua privacidade e seu dinheiro protegidos em uma única
                  solução.
                </p>
                <p>
                  Gostou? Então não deixe para depois, baixe o app e contrate
                  agora a sua proteção!
                </p>

                <ul className={styles.list_buttons}>
                  <li>
                    <a href={URL_APP_STORE}>
                      <img src={BT_AS} alt='Botão para acessar a Apple Store' />
                    </a>
                  </li>

                  <li>
                    <a href={URL_GOOGLE_PLAY}>
                      <img src={BT_PS} alt='Botão para acessar a Play Store' />
                    </a>
                  </li>
                </ul>
              </header>

              <div className={styles.drawman}>
                <img src={DRAWMAN} alt='Desenho homem usando computador' />
              </div>

              <div className={styles.cards}>
                <ul>
                  {LIST_CARDS_2.map((items, key) => (
                    <li key={key}>
                      <h4>{items.title}</h4>
                      <p>{items.description}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </section>
          </div>

          <section className={styles.tips}>
            <div className={styles._content}>
              <header className={styles.title_h2}>
                <h2>
                  Dicas de segurança <br />
                  contra fraudes
                </h2>
                <p>
                  Saiba mais sobre os problemas de segurança digital e como se
                  proteger.
                </p>
              </header>

              <div className={styles.drawWoman}>
                <img
                  src={DRAWWOMAN}
                  alt='Desenho de uma mulher apontando com relógio no pulso'
                />
              </div>

              <div className={styles._list}>
                <ul>
                  {LIST_CARDS_3.map((items, key) => (
                    <li key={key}>
                      <h4>{items.title}</h4>
                      <p>{items.description}</p>
                      <hr />
                      <a
                        href={items.urlRedirect}
                        target='_blank'
                        rel='noopener noreferrer'
                        style={{ textDecoration: 'none', fontSize: 14 }}
                      >
                        {items.buttonTitle}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </section>

          <div className={styles._content}>
            <section className={styles.press}>
              <header className={styles.title_h2}>
                <h2>
                  Proteção Pix na <br />
                  imprensa
                </h2>
              </header>

              <div>
                <ul className={styles.pressList}>
                  <li>
                    <a
                      href='https://tiinside.com.br/06/05/2022/tim-e-fs-criam-empresa-de-ciberseguranca-e-lancam-solucao-de-protecao-para-pix/'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <img src={TINNSIDE} alt='Teletime' />
                    </a>
                  </li>

                  <li>
                    <a
                      href='https://acessowi-fi.com/tim-e-fs-criam-a-exa-empresa-de-seguranca-digital-para-operacoes-via-pix/?doing_wp_cron=1667512265.1189270019531250000000'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <img src={ACESSOWIFI} alt='Teletime' />
                    </a>
                  </li>

                  <li>
                    <a
                      href='https://ipnews.com.br/tim-e-fs-criam-empresa-de-ciberseguranca-e-lancam-solucao-de-protecao-para-pix/'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <img src={LPNEWS} alt='Teletime' />
                    </a>
                  </li>

                  <li>
                    <a
                      href='https://www.minhaoperadora.com.br/2022/05/tim-e-fs-criam-a-exa-empresa-de-seguranca-digital-para-operacoes-via-pix.html'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <img src={MINHAOPERADORA} alt='Teletime' />
                    </a>
                  </li>

                  <li>
                    <a
                      href='https://www.segs.com.br/info-ti/345190-tim-e-fs-criam-empresa-inedita-no-brasil-de-servicos-digitais-com-foco-em-seguranca-e-privacidade-de-dados'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <img src={SEGS} alt='Teletime' />
                    </a>
                  </li>
                </ul>
              </div>
            </section>

            <section className={styles.appInfo}>
              <header className={styles.title_h2}>
                <h2>
                  Baixe o app Proteção Pix e deixe <br /> seu celular mais
                  seguro!
                </h2>
              </header>

              <div className={styles.buttons}>
                <ul>
                  <li>
                    <a href={URL_APP_STORE}>
                      <img src={BT_AS} alt='Botão para acessar a Apple Store' />
                    </a>
                  </li>

                  <li>
                    <a href={URL_GOOGLE_PLAY}>
                      <img src={BT_PS} alt='Botão para acessar a Play Store' />
                    </a>
                  </li>
                </ul>
              </div>

              <div className={styles.optionList}>
                <ul>
                  <li>
                    <h3>
                      01 <br /> Baixe o app
                    </h3>
                    <p>Disponível na Apple Store ou Play Store.</p>
                  </li>

                  <li>
                    <h3>
                      02 <br /> Cadastre-se pelo app
                    </h3>
                    <p>
                      É só se cadastrar com seu e-mail e seguir o passo a passo
                      do aplicativo.
                    </p>
                  </li>

                  <li>
                    <h3>
                      03 <br /> Pronto!
                    </h3>
                    <p>
                      Agora, você tem acesso ao Portal de Proteção e a proteção
                      ativa no celular.
                    </p>
                    <p>
                      É só manter o aplicativo ativo que a gente cuida de tudo.
                    </p>
                  </li>
                </ul>
              </div>
            </section>
          </div>
        </div>
        <Doubts />
        <Chatbot bottomSize={false} />
      </div>
    </SettingsProvider>
  )
}

export default Home
