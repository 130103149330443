import React from 'react'

import Grid from '@/components/Presentational/Grid'

import * as S from './styled'

const Drawer = ({ children, onClick }) => {
  return (
    <Grid>
      <S.Container>
        <S.Button type='button' onClick={onClick} data-testid='button-go-back'>
          <svg
            data-test
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M9.93934 22.0607C10.5251 22.6464 11.4749 22.6464 12.0607 22.0607C12.6464 21.4749 12.6464 20.5251 12.0607 19.9393L5.88909 13.7678C5.80416 13.6828 5.72662 13.5933 5.65646 13.5L21 13.5C21.8284 13.5 22.5 12.8284 22.5 12C22.5 11.1716 21.8284 10.5 21 10.5L5.65646 10.5C5.72661 10.4067 5.80416 10.3172 5.88909 10.2322L12.0607 4.06066C12.6465 3.47487 12.6465 2.52513 12.0607 1.93934C11.4749 1.35355 10.5251 1.35355 9.93935 1.93934L3.76777 8.11091C1.61988 10.2588 1.61988 13.7412 3.76777 15.8891L9.93934 22.0607Z'
              fill='#1F1F1F'
            />
          </svg>
          Voltar
        </S.Button>
      </S.Container>
      <S.Title>
        <svg
          width='24'
          height='24'
          viewBox='0 0 32 32'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M24.5 18V17C24.5 14.7908 22.7092 13 20.5 13H11.5C9.29086 13 7.5 14.7908 7.5 17V23C7.5 25.2092 9.29086 27 11.5 27H16H20.25H20.75C22.821 27 24.5 25.321 24.5 23.25'
            stroke='#004BC1'
            strokeWidth='3'
            strokeLinecap='round'
          />
          <path
            d='M22 14V11C22 7.13401 19.3137 5 16 5C12.6863 5 10 7.13401 10 11V14'
            stroke='#004BC1'
            strokeWidth='3'
          />
          <path
            d='M16 22C17.1046 22 18 21.1046 18 20C18 18.8954 17.1046 18 16 18C14.8954 18 14 18.8954 14 20C14 21.1046 14.8954 22 16 22Z'
            fill='#004BC1'
          />
        </svg>
        <p>Bloqueio</p>
      </S.Title>
      <S.Description>{children}</S.Description>
    </Grid>
  )
}

export default Drawer
