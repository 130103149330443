import { Paragraph } from '@/styles/fonts'

import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-start;
  border-top: 1px solid #b5d1fc;
  border-right: 1px solid #b5d1fc;
  border-bottom: 1px solid #b5d1fc;
  border-radius: 20px;
  width: 100%;
`

export const Divider = styled.div`
  background: rgb(181, 209, 252);
  width: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
`
export const Icon = styled.div`
  display: flex;
  align-items: center;
`

export const Content = styled.div`
  padding: 10px;
`

export const Text = styled(Paragraph)`
  margin: 0px;
`
