import React from 'react'

import IconClose from '@/components/Presentational/Icons/IconClose'
import IconSearch from '@/components/Presentational/Icons/IconSearch'

import * as S from './styled'

const InputIcon = ({
  type,
  placeholder,
  name,
  value,
  required = false,
  onChange,
  onClearData,
  disabled,
  onFocus,
  onBlur,
  max,
  min,
}) => {
  return (
    <S.Container>
      <input
        type={type}
        placeholder={placeholder}
        name={name}
        required={required}
        onChange={onChange}
        value={value}
        disabled={disabled}
        onFocus={onFocus}
        onBlur={onBlur}
        max={max}
        min={min}
      />
      {value ? <IconClose onClick={onClearData} /> : <IconSearch />}
    </S.Container>
  )
}

export default InputIcon
