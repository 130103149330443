import React from 'react'

const IconCheckOut = ({ color = '#C42137' }) => {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      data-testid='icon-check-out'
    >
      <circle
        cx='12.5'
        cy='12'
        r='10.75'
        fill={color}
        stroke='#FCFCFC'
        strokeWidth='1.5'
      />
      <path
        d='M12.4941 16L12.4987 16'
        stroke='#FCFCFC'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.5 8L12.5 13.3333'
        stroke='#FCFCFC'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default IconCheckOut
