import { useAuth } from '@/contexts/Authentication'
import { convertBase64 } from '@/utils/ConvertBase64'

export default function useFormattedData() {
  const {
    user: {
      profile: {
        device_os,
        imei,
        device_id,
        subscription: { plan_id },
        msisdn,
        fullname,
        email,
        document,
      },
    },
  } = useAuth()

  const formattedData = async (formData, draftId, status) => {
    const isInsured = formData.typePerson === 'Fisica' ? true : false
    const ownerEmail =
      formData.typePerson === 'Fisica' ? email : formData.ownerEmail
    const ownerPhone =
      formData.typePerson === 'Fisica' ? msisdn : formData.ownerPhone
    const ownerName =
      formData.typePerson === 'Fisica' ? fullname : formData.ownerName

    const documents = await Promise.all(
      [
        'invoiceFiles',
        'policeReport',
        'residencyProof',
        'cnhFiles',
        'cpfFiles',
        'iCloudScreen',
      ].map(async (fileName) => {
        if (device_os === 'android' && fileName === 'iCloudScreen') {
          return null
        }

        if (formData[fileName] && formData[fileName].lastModified) {
          const base64Data = await convertBase64(formData[fileName])
          return {
            name_document: fileName,
            data_document: base64Data,
          }
        } else if (formData[fileName] && formData[fileName].file) {
          return {
            name_document: fileName,
            data_document: formData[fileName].file,
          }
        } else {
          return {
            name_document: fileName,
            data_document: '',
          }
        }
      })
    )

    const filteredDocuments = documents.filter((item) => item)

    return {
      id: draftId,
      status: status,
      imei: imei,
      cpf_cnpj: document,
      certificate_number: '7900000171',
      communication: false,
      subscription_id: plan_id,
      device_id: device_id,
      bank_cpfCnpj: document,
      coverage_id: '1',
      cause_type_id: '1',
      type_communication: '',
      sinister_type: '',
      location_info: '',
      block_request: '',
      event_date: '',
      is_insured: isInsured,
      date_sinister: `${formData.sinisterDate} ${formData.sinisterTime}`,
      email: ownerEmail,
      telefone: ownerPhone,
      name: ownerName,
      owner_relationship: formData.ownerRelationship,
      owner_cpf: formData.ownerCpf,
      type_person: formData.typePerson,
      event_description: formData.eventDescription,
      bank_name: '',
      bank_code: formData.bankCode,
      bank_agency: formData.bankAgencyNumber,
      bank_account: formData.bankAccount,
      bank_account_type: formData.bankAccountType,
      flag_terms: formData.flagTerms,
      documents: filteredDocuments,
      address: {
        address_name:
          formData.typePerson === 'Fisica' ? '' : formData.ownerAddressName,
        address_number:
          formData.typePerson === 'Fisica' ? '' : formData.ownerAddressNumber,
        address_complement:
          formData.typePerson === 'Fisica'
            ? ''
            : formData.ownerAddressComplement,
        address_district:
          formData.typePerson === 'Fisica' ? '' : formData.ownerAddressDistrict,
        address_city:
          formData.typePerson === 'Fisica' ? '' : formData.ownerAddressCity,
        address_state:
          formData.typePerson === 'Fisica' ? '' : formData.ownerAddressState,
        address_zipcode:
          formData.typePerson === 'Fisica' ? '' : formData.onwerAddressZipcode,
      },
    }
  }

  return { formattedData }
}
