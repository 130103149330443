import styled from 'styled-components'

export const Answers = styled.div`
  display: flex;
  flex-direction: column;

  > h3 {
    font-family: 'Space Grotesk';
    font-size: 42px;
    line-height: 45px;
    font-weight: 500;
    color: #bd2469;
  }

  @media screen and (max-width: 767px) {
    > h3 {
      font-size: 32px;
    }
  }
`

export const Button = styled.button`
  font-family: 'Space Grotesk';
  font-size: 16px;
  background-color: #bd2469;
  border: none;
  padding: 15px 70px;
  margin: 40px auto;
`

export const HelpCenter = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  > h3 {
    font-family: 'Space Grotesk';
    font-size: 42px;
    line-height: 45px;
    font-weight: 500;
    color: #bd2469;
    margin-bottom: 40px;
  }

  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 30px;
  }

  @media screen and (max-width: 767px) {
    > h3 {
      font-size: 32px;
    }

    > div {
      display: flex;
      flex-direction: column;
    }
  }
`
