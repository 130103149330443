import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import IMAGE2 from '@/assets/img/atencao.png'
import IMAGE1 from '@/assets/img/sucesso.png'
import { useLoader } from '@/contexts/Loader'
import { verifyEmailLink } from '@/services/Firebase'
import queryString from 'query-string'

import * as S from './styled'

const EmailActions = () => {
  const navigate = useNavigate()
  const { setLoader } = useLoader()
  const [status, setStatus] = useState(false)

  const getVerifyEmail = async (oobCode) => {
    setLoader(true)

    const verifyEmailLinkResponse = await verifyEmailLink(oobCode)

    if (!verifyEmailLinkResponse.status) {
      setLoader(false)
      setStatus(false)
      return
    }

    setLoader(false)
    setStatus(true)
  }

  useEffect(() => {
    const { mode, oobCode } = queryString.parse(window.location.search)

    if (mode === 'verifyEmail') {
      getVerifyEmail(oobCode)
    } else {
      navigate('/')
    }
  }, [])

  return (
    <S.Main>
      <S.Container>
        {status ? (
          <>
            <S.Content>
              <h1>E-mail validado com sucesso!</h1>
              <img src={IMAGE1} alt='Sucesso' />
            </S.Content>
            <S.Description>
              <b>Falta pouco!</b>
              <br />
              <p>
                Retorne ao aplicativo Proteção PIX para finalizar o seu
                cadastro.
              </p>
            </S.Description>
          </>
        ) : (
          <>
            <S.Content>
              <h1>Validação de e-mail</h1>
              <img src={IMAGE2} alt='Atenção' />
            </S.Content>
            <S.Description>
              <b>Verifique seu e-mail novamente</b>
              <br />
              <p>
                Sua solicitação para verificar o e-mail expirou ou o link já foi
                usado.
              </p>
            </S.Description>
          </>
        )}
      </S.Container>
    </S.Main>
  )
}

export default EmailActions
