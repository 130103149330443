import React from 'react'

import { URL_ICLOUD_APPLE } from '@/constants'

const HelpBlockIOS = () => {
  return (
    <div className='content-flow'>
      <section className='infos_help_ios'>
        <p>
          Todos os dispositivos da Apple contam com bloqueio do celular já
          incluso no aparelho.{' '}
        </p>

        <p style={{ fontSize: '16px', marginTop: '20px' }}>
          <strong>
            Siga o passo o passo a passo para bloquear o dispositivo
            remotamente:
          </strong>
        </p>

        <ul>
          <li>1. Acesse o iCloud, selecione a aba “Dispositivos”.</li>
          <li>2. Selecione o dispositivo roubado ou perdido.</li>
          <li>
            3. Verifique a localização do Iphone no mapa e selecione o “Modo
            perdido”, então insira um número de telefone que você tenha acesso e
            conclua o processo.
          </li>
          <li>
            4. Se você for usar outro dispositivo da Apple, procure pelo
            aplicativo ”Buscar”, selecione o dispositivo e toque em ”Ativar”
            dentro da opção ”Marcar como perdido”.
          </li>
        </ul>

        <a
          href={URL_ICLOUD_APPLE}
          target='_blank'
          rel='noreferrer'
          style={{
            backgroundColor: 'hsla(217, 100%, 38%, 1)',
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
            color: '#ffffff',
            padding: '10px 20px',
            marginTop: 20,
            borderRadius: 20,
          }}
        >
          Acessar iCloud
          <svg
            width='31'
            height='31'
            viewBox='0 0 31 31'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M19.5291 23.3603C19.0338 23.2276 18.7399 22.7185 18.8726 22.2232L21.0111 14.2422L8.47942 21.4774C8.03536 21.7338 7.46754 21.5816 7.21116 21.1375C6.95478 20.6935 7.10692 20.1257 7.55099 19.8693L20.0827 12.6341L12.1016 10.4956C11.6064 10.3629 11.3124 9.85378 11.4451 9.35849C11.5779 8.8632 12.087 8.56927 12.5822 8.70198L22.7284 11.4206C23.2236 11.5533 23.5176 12.0624 23.3849 12.5577L20.6662 22.7038C20.5335 23.1991 20.0244 23.4931 19.5291 23.3603Z'
              fill='#FDFDFD'
            />
          </svg>
        </a>
      </section>
    </div>
  )
}
export default HelpBlockIOS
