import React from 'react'

import { InputIcon } from '@/components/Presentational/Inputs'

import * as S from './styled'

const Title = ({
  title,
  search,
  description,
  placeholder,
  showInput,
  onChange,
  onClearData,
}) => {
  return (
    <S.Title>
      <h1>{title}</h1>
      <p>{description}</p>
      {showInput && (
        <InputIcon
          placeholder={placeholder}
          value={search}
          onChange={onChange}
          onClearData={onClearData}
        />
      )}
    </S.Title>
  )
}

export default Title
