import styled from 'styled-components'

export const Container = styled.div`
  height: 400px;
  overflow: scroll;

  @media only screen and (min-width: 1024px) {
    height: 580px;
  }
`

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
`

export const Items = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
`
