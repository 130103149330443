import styled from 'styled-components'

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 15px;
`

export const LabelItem = styled.label`
  font-size: 16px;

  > input {
    margin-right: 10px;
  }
`
