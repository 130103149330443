import styled from 'styled-components'

export const Main = styled.main``

export const Header = styled.header`
  background-color: hsla(0, 0%, 99%, 1);
  border-bottom: 1px solid hsla(210, 13%, 88%, 1);
  padding: 20px 30px;
  display: flex;

  > a > img {
    max-height: 32px;
    max-width: 200px;
  }
`
export const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 60px;
  margin: 20px auto;
  max-width: 1080px;
  width: 100vw;
  min-height: 0px;

  @media screen and (max-width: 767px) {
    padding: 20px;
  }
`
