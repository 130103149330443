import React from 'react'

import ButtonPrimary from '@/components/Presentational/Buttons/Primary'

const Form = ({ deletePhone, onChange, onSubmit }) => {
  return (
    <form onSubmit={onSubmit}>
      <div>
        <p>
          Atenção! Esta ação apagará todos os dados do seu celular e não pode
          ser desfeita.
        </p>
        <br />
        <p>
          Ao confirmar a ação, você perde acesso às funcionalidades do Portal.
        </p>
        <br />
        <p>Para prosseguir digite a palavra “APAGAR” no campo abaixo:</p>
        <input
          type='text'
          placeholder='APAGAR'
          data-testid='input-clear-data'
          name='clear-data'
          value={deletePhone}
          onChange={onChange}
          style={{
            outline: 'none',
            border: '1px solid #C4C4C4',
            borderRadius: '6px',
            width: '100%',
            padding: '14px',
            textAlign: 'center',
            marginTop: '20px',
            textTransform: 'uppercase',
          }}
        />

        <br />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 30,
          }}
        >
          <ButtonPrimary
            dataTestId='button-submit'
            type='submit'
            name='Apagar dados'
            disabled={deletePhone.toUpperCase() === 'APAGAR' ? false : true}
          />
        </div>
      </div>
    </form>
  )
}
export default Form
