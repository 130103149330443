import React from 'react'

const IconPencil = ({ onClick, color = '#004BC1' }) => {
  return (
    <svg
      width='17'
      height='20'
      viewBox='0 0 17 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.8777 0.597925C12.2952 -0.475729 10.1412 -0.0641551 9.06671 1.51719L1.54293 12.5901C1.37365 12.8393 1.27236 13.1282 1.24908 13.4285L0.884363 18.1332C0.800348 19.217 1.69952 20.0416 2.6849 19.9984C2.70135 19.9995 2.71786 20 2.73458 20H16.0853C16.5236 20 16.8789 19.6197 16.8789 19.1507C16.8789 18.6816 16.5236 18.3013 16.0853 18.3013H6.47955L7.61395 17.7465C7.88461 17.6141 8.11605 17.4134 8.28533 17.1643L15.8091 6.09131C16.8836 4.50997 16.4717 2.35769 14.8891 1.28403L13.8777 0.597925ZM10.4995 2.48918C11.0367 1.69854 12.1137 1.49276 12.905 2.02955L13.9163 2.71569C14.7076 3.25253 14.9136 4.32865 14.3763 5.11933L13.7542 6.03502L9.90167 3.36901L10.4995 2.48918ZM8.93789 4.78737L12.7904 7.45338L6.85256 16.1922L2.61096 18.2668L2.9757 13.5621L8.93789 4.78737Z'
        fill={color}
      />
    </svg>
  )
}

export default IconPencil
