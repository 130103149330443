export const SETTINGS = [
  {
    route: '/',
    title: 'Proteção Pix: Aplicativo de Segurança para Celulares',
    description:
      'Conheça o Proteção Pix e garanta mais segurança para seu celular. Evite transações fraudulentas em caso de perda ou roubo e controle seu aparelho à distância!',
  },
  {
    route: '/login',
    title: 'Login – Proteção PIX',
    description:
      'Faça seu login no Proteção PIX. Acesse o site, preencha seus dados e acesse seu perfil agora mesmo!',
  },
  {
    route: '/faq',
    title: 'Dúvidas Frequentes sobre Proteção Pix',
    description:
      'Saiba mais sobre a solução que te ajuda a se proteger de golpes e fraudes do PIX. Entenda como a Proteção PIX funciona e começa agora mesmo a se manter seguro!',
  },
]
