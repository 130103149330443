import React, { useState, useEffect } from 'react'

import Grid from '@/components/Presentational/Grid'
import { useAuth } from '@/contexts/Authentication'
import { useLoader } from '@/contexts/Loader'
import { useSinisters } from '@/contexts/Sinisters'
import useEvents from '@/hooks/useEvents'
import useToast from '@/hooks/useToast'
import { sendResendDocuments } from '@/services/APIService/Sinisters'
import { convertBase64 } from '@/utils/ConvertBase64'

import ButtonGoBack from './atoms/ButtonGoBack'
import Card from './atoms/Card'
import History from './atoms/History'
import OpenSinister from './atoms/OpenSinister'
import * as S from './styled'

const INITIAL_QTDE_DATA = 2

const RequestRefund = () => {
  const {
    user: {
      profile: {
        device_os,
        lastAction,
        subscription: { premium },
      },
    },
  } = useAuth()
  const { setLoader } = useLoader()
  const { toastrMessage } = useToast()
  const { listEvent, removeEvent } = useEvents()
  const { sinisters, isDraft, loader } = useSinisters()
  const [isOpen, setIsOpen] = useState(false)
  const [showMore, setShowMore] = useState(true)
  const [qtdeData, setQtdeData] = useState(INITIAL_QTDE_DATA)
  const [historyDetails, setHistoryDetails] = useState({})
  const [formData, setFormData] = useState()
  const [display, setDisplay] = useState({
    stepOne: true,
    stepTwo: true,
  })

  const handleChange = (e) => {
    const { name, files } = e.target

    setFormData({
      ...formData,
      [name]: files[0],
    })
  }

  const handleSubmitResendDocuments = async (e) => {
    e.preventDefault()

    const data = await Promise.all(
      Object.keys(formData).map(async (fileName) => {
        return {
          name_document: fileName.replace(/\s/g, '_').toLowerCase(),
          data_document: await convertBase64(formData[fileName]),
        }
      })
    )

    setLoader(true)

    const sendResendDocumentsResponse = await sendResendDocuments(
      historyDetails.protocol,
      data
    )

    if (!sendResendDocumentsResponse.status) {
      setLoader(false)
      toastrMessage('error', sendResendDocumentsResponse.message)
      return
    }

    toastrMessage(
      'success',
      'Envio dos documentos pendentes efetuada com sucesso!'
    )

    setLoader(false)
  }

  const handleClickButtonCard = () => {
    setIsOpen(!isOpen)
  }

  const handleClickButtonGoBack = () => {
    if (display.stepOne) {
      setIsOpen(false)
      setDisplay({
        stepOne: true,
        stepTwo: true,
      })
      return
    }

    if (Object.values(historyDetails).length > 0) {
      setHistoryDetails({})
      setQtdeData(sinisters.length)
      setDisplay({
        stepOne: false,
        stepTwo: true,
      })
      return
    }

    setDisplay({
      stepOne: true,
      stepTwo: true,
    })
    setShowMore(true)
    setQtdeData(INITIAL_QTDE_DATA)
    setHistoryDetails({})
  }

  const handleClickButtonMoreData = () => {
    setShowMore(false)
    setQtdeData(sinisters.length)
    setDisplay({
      stepOne: false,
      stepTwo: true,
    })
  }

  const handleClickItemHistory = (item) => {
    setShowMore(false)
    setHistoryDetails(item)
    setDisplay({
      stepOne: false,
      stepTwo: true,
    })
  }

  useEffect(() => {
    listEvent('drawerRequestRefund', () => setIsOpen(true))
    return () => {
      removeEvent('drawerRequestRefund', () => setIsOpen(false))
    }
  }, [])

  if (!premium) {
    return null
  }

  return (
    <React.Fragment>
      <Card
        isDraft={isDraft}
        isLoader={loader}
        deviceOS={device_os}
        disabled={lastAction === 'CLEANED' || lastAction === 'LOGGED_OUT'}
        onClick={() => handleClickButtonCard()}
      />
      {isOpen && (
        <Grid>
          <ButtonGoBack
            dataTestId='button-go-back'
            onClick={handleClickButtonGoBack}
          />

          {display.stepOne && <OpenSinister />}

          {display.stepTwo && (
            <History
              loader={loader}
              history={sinisters.slice(0, qtdeData)}
              historyDetails={historyDetails}
              onChange={handleChange}
              onSubmit={handleSubmitResendDocuments}
              onClickItem={handleClickItemHistory}
            />
          )}

          {sinisters.length > 3 && showMore && (
            <S.MoreData>
              <button
                data-testid='show-all-data'
                type='button'
                onClick={() => handleClickButtonMoreData()}
              >
                Acessar todas
              </button>
            </S.MoreData>
          )}
        </Grid>
      )}
    </React.Fragment>
  )
}

export default RequestRefund
