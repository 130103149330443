import React from 'react'

import * as S from './styled'

const Banner = () => {
  return (
    <S.Container>
      <S.Text>
        As funções de proteção estão desabilitadas <br />
        por causa da limpeza de dados realizada. Para <br />
        habilitar, instale o app Proteção Pix no seu <br />
        aparelho novamente e ative as permissões.
      </S.Text>
    </S.Container>
  )
}

export default Banner
