import React from 'react'

const IconClaims = ({ onClick, color = '#FDFDFD' }) => {
  return (
    <svg
      width='56'
      height='56'
      viewBox='0 0 56 56'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <path
        d='M23 47.2502C23 47.2502 12.5 47.2502 9 47.2502C5.5 47.2502 2 43.7502 2 40.2502C2 33.2502 2.00016 20.1252 2 13.1252C1.99992 9.62524 5.5 6.12524 9 6.12524C12.5 6.12525 31.75 6.12525 35.25 6.12524C38.75 6.12524 42.25 9.62524 42.25 13.1252C42.25 20.1252 42.25 28.0002 42.25 28.0002'
        stroke={color}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M37 47.2502H47.5'
        stroke={color}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M42.25 42.0002V52.5002'
        stroke={color}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.5 15.7502H33.5M12.5 26.2502H33.5M12.5 36.7502H19.5'
        stroke={color}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default IconClaims
