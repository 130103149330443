import React, { useEffect } from 'react'
import { isMobile } from 'react-device-detect'

import Alarm from '@/components/Containers/Alarm'
import AntiTheftCamera from '@/components/Containers/AntiTheftCamera'
import BlockPhone from '@/components/Containers/BlockPhone'
import ClearData from '@/components/Containers/ClearData'
import Location from '@/components/Containers/Location'
import Panic from '@/components/Containers/Panic'
import RequestRefund from '@/components/Containers/RequestRefund'
import StatusRange from '@/components/Containers/StatusRange'
import { useAuth } from '@/contexts/Authentication'
import useToast from '@/hooks/useToast'
import Layout from '@/layout'

import Banner from './atoms/Banner'

const Dashboard = () => {
  const {
    user: {
      profile: { device_os, lastAction },
    },
  } = useAuth()
  const { toastrMessage } = useToast()

  const getOrCreateNotification = () => {
    if (localStorage.getItem('_ncd') === null) {
      localStorage.setItem('_ncd', true)
      toastrMessage(
        'success',
        'Limpeza de dados realizada com sucesso',
        'bottom-left'
      )
      return
    }
  }

  useEffect(() => {
    if (lastAction === 'CLEANED') {
      getOrCreateNotification()
    }
  }, [lastAction])

  return (
    <Layout>
      <div className='content'>
        <div className='col gap'>
          {isMobile && (
            <div className='card protect-status desk'>
              <StatusRange />
            </div>
          )}
          <Location />
        </div>
        <div
          className={`col action-remote ${
            device_os === 'ios' ? 'ios_action' : ''
          }`}
        >
          {lastAction === 'CLEANED' ||
            (lastAction === 'LOGGED_OUT' && <Banner />)}
          {!isMobile && (
            <div className='card protect-status mobile'>
              <StatusRange />
            </div>
          )}
          <BlockPhone />
          <AntiTheftCamera />
          <ClearData />
          <Alarm />
          <Panic />
          <RequestRefund />
        </div>
      </div>
    </Layout>
  )
}
export default Dashboard
