import React from 'react'

const IconChevronRight = ({
  onClick,
  width = 20,
  height = 20,
  color = '#004BC1',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.89069 13.8047C4.59193 13.5444 4.59194 13.1223 4.8907 12.8619L10.4699 8.00011L4.89069 3.13807C4.59193 2.87771 4.59194 2.4556 4.8907 2.19526C5.18947 1.93491 5.67385 1.93492 5.97261 2.19527L11.5518 7.05732C12.1493 7.57802 12.1493 8.42224 11.5518 8.94293L5.97259 13.8047C5.67383 14.0651 5.18945 14.0651 4.89069 13.8047Z'
        fill={color}
      />
    </svg>
  )
}

export default IconChevronRight
