import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(20px);

  @media screen and (max-width: 767px) {
    position: relative;
    bottom: 440px;
    right: 140px;
    width: 300px;
  }

  @media screen and (max-width: 600px) {
    position: relative;
    bottom: 430px;
    right: 145px;
    width: 280px;
  }
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 6px;
`

export const Icon = styled.div`
  display: flex;
  gap: 6px;
`

export const Paragraph = styled.p`
  font-size: 14px;
  color: #ffffff;
`

export const Divider = styled.hr`
  margin: 0;
  color: #757575;
`
