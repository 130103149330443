import React from 'react'

const IconFileMoney = ({ color = '#004BC1', onClick }) => {
  return (
    <svg
      width='33'
      height='32'
      viewBox='0 0 33 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <g clipPath='url(#clip0_26088_1253)'>
        <path
          d='M13.5 27C13.5 27 7.5 27 5.5 27C3.5 27 1.5 25 1.5 23C1.5 19 1.50009 11.5 1.5 7.5C1.49996 5.5 3.5 3.5 5.5 3.5C7.5 3.5 18.5 3.5 20.5 3.5C22.5 3.5 24.5 5.5 24.5 7.5C24.5 11.5 24.5 16 24.5 16'
          stroke={color}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.5 9H19.5M7.5 15H19.5M7.5 21H11.5'
          stroke={color}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M26.2383 22.6406C25.7383 22.4983 25.1851 22.4986 24.5 22.5038C24.2709 22.5056 24.4094 22.4944 24.1 22.5038C23.2926 22.5327 22.5016 22.9178 22.5 24.0088C22.4982 25.1711 23.5 25.515 24.5 25.515C25.5 25.515 26.5 25.7592 26.5 27C26.5 27.9325 25.6925 28.3621 24.6861 28.4974C23.8861 28.4974 23.7344 28.5263 22.7344 28.3828M24.5 28.5V30M24.5 21V22.5'
          stroke={color}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_26088_1253'>
          <rect
            width='32'
            height='32'
            fill='white'
            transform='translate(0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconFileMoney
