import React, { useRef, useState } from 'react'

import IconCircleClose from '@/components/Presentational/Icons/IconCircleClose'

import * as S from './styled'

const InputFile = ({ id, dataTestId, name, title, iconRight, onChange }) => {
  const inputRef = useRef(null)
  const [errorMessage, setErrorMessage] = useState(false)

  const onChangeFile = (e) => {
    const { files } = e.target

    setErrorMessage(false)

    if (files.length > 0) {
      if (files[0].size > 5242880) {
        inputRef.current.value = ''
        setErrorMessage(true)
        return
      }

      onChange(e)
      inputRef.current.value = ''
    }
  }

  return (
    <React.Fragment>
      <label>
        <input
          data-testid={dataTestId}
          ref={inputRef}
          type='file'
          id={id}
          name={name}
          accept={['.jpg', '.png', '.pdf']}
          onChange={onChangeFile}
          required
        />
        {title} {iconRight}
      </label>
      {errorMessage && (
        <S.ErrorMessage>
          <p>O tamanho do arquivo excede 5 MB. Escolha um arquivo menor.</p>
          <IconCircleClose onClick={() => setErrorMessage(false)} />
        </S.ErrorMessage>
      )}
    </React.Fragment>
  )
}

export default InputFile
