import colors from '@/styles/colors'

import styled from 'styled-components'

export const InputContainer = styled.input`
  ::placeholder {
    font-size: 16px;
    color: #000000;
  }

  :disabled {
    color: ${colors.gray};
  }
`
