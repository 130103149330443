import React from 'react'

import { useForm } from '@/components/Containers/Claims/contexts/Forms'
import Collapse from '@/components/Presentational/Collapsible/Collapse'
import { useCollapse } from '@/components/Presentational/Collapsible/contexts/Collapse'
import Item from '@/components/Presentational/Collapsible/Item'

import * as S from './styled'

const validateForms = (flagTerms) => {
  return !flagTerms ? 'PENDING' : 'SUCCESS'
}

const TermsOfUseItem = () => {
  const { collapse, setCollapse } = useCollapse()
  const {
    formData: { flagTerms },
    spinner,
  } = useForm()

  return (
    <Item
      spinner={spinner}
      status={validateForms(flagTerms)}
      title='Termos'
      dataTestId='button-terms-of-use'
      onClick={() =>
        setCollapse((prev) => ({
          ...prev,
          termsOfUse: !collapse.termsOfUse,
        }))
      }
    />
  )
}

const TermsOfUseForm = () => {
  const {
    formData: { flagTerms },
    updateFormData,
    spinner,
  } = useForm()
  const { collapse, setCollapse } = useCollapse()

  const handleClickAcceptTerms = (e) => {
    const { name, checked } = e.target
    updateFormData({ [name]: checked ? checked : '' })
  }

  return (
    <Collapse
      spinner={spinner}
      isOpen={collapse.termsOfUse}
      title='Termos'
      subTitle='Para continuar, você precisa ler e aceitar os termos de uso.'
      onClick={() =>
        setCollapse((prev) => ({
          ...prev,
          termsOfUse: !collapse.termsOfUse,
        }))
      }
      status={validateForms(flagTerms)}
    >
      <S.Form data-testid='form-terms-of-use'>
        <small>
          Ao preencher este formulário o segurado, beneficiário ou seu
          representante legal, consente que as informações disponibilizadas
          serão enviadas a MAPFRE para fins de identificação de contrato de
          seguro, regulação do processo de sinistro e verificação do direito ao
          recebimento da indenização do seguro.
          <br />
          <br />
          Além disso, as informações serão armazenadas e utilizadas pela
          seguradora na comunicação sobre o andamento desta solicitação e prova
          documental para todos os fins de direito. A autenticidade e veracidade
          dos dados e documentos anexados neste formulário são de
          responsabilidade exclusiva do comunicante, sob as penas da lei.
          Qualquer alteração dos dados cadastrais e/ou de qualquer natureza aqui
          informados deverá ser comunicado imediatamente a seguradora.
          <br />
          <br />A MAPFRE trabalha com políticas de prevenção e detecção de
          fraudes. Caso alguma fraude seja confirmada, além da perda do direito
          a indenização do segurado, medidas judiciais serão adotadas, sem
          prejuízo da comunicação aos órgãos reguladores, atendendo as
          exigências legais vigentes.
        </small>
        <label>
          <input
            type='checkbox'
            data-testid='flagTerms'
            name='flagTerms'
            value={flagTerms}
            checked={flagTerms}
            onChange={handleClickAcceptTerms}
            required
          />
          Li e concordo com o termos acima
        </label>
      </S.Form>
    </Collapse>
  )
}

export { TermsOfUseItem, TermsOfUseForm }
