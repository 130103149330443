import colors from '@/styles/colors'

import styled from 'styled-components'

export const MoreData = styled.div`
  display: flex;
  justify-content: end;

  > button {
    color: ${colors.primary};
    background: none;
    border: none;
    font-size: 12px;
  }
`
