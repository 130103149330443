import styled from 'styled-components'

export const Card = styled.div`
  color: var(--color-grey);
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  width: 30%;

  > img {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  > div {
    padding: 25px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    > p {
      font-size: 14px;
      font-family: 'Space Grotesk';
    }

    > h5 {
      font-size: 22px;
      font-family: 'Space Grotesk';
      font-weight: 500;
    }

    > button {
      font-size: 16px;
      text-align: center;
      text-decoration: none;
      font-family: 'Space Grotesk';
      background: none;
      border: none;
      color: #000000;
    }
  }

  @media screen and (max-width: 767px) {
    width: 100%;

    > img {
      width: 100%;
    }
  }
`
