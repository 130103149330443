import React from 'react'

import IC_CRUZ from '@/assets/img/ic-positive.svg'

import * as S from './styled'

const Doubts = () => {
  const date = new Date()

  return (
    <S.Section>
      <S.Container>
        <S.Content>
          <h2>
            Ficou com <br /> alguma dúvida?
          </h2>
          <a href='/?show-bot'>Clique aqui e acesse o nosso chat</a>
        </S.Content>
        <S.UsefulLinks>
          <ul>
            <li>
              <img src={IC_CRUZ} alt='Ícone de cruz(positivo)' />
              <a href='/faq'>O que é o Proteção PIX</a>
            </li>

            <li>
              <img src={IC_CRUZ} alt='Ícone de cruz(positivo)' />
              <a href='/faq'>Como contratar?</a>
            </li>

            <li>
              <img src={IC_CRUZ} alt='Ícone de cruz(positivo)' />
              <a href='/faq'>Como ativar? Primeiro acesso</a>
            </li>

            <li>
              <img src={IC_CRUZ} alt='Ícone de cruz(positivo)' />
              <a href='/faq'>Ver todas as perguntas frequentes</a>
            </li>
          </ul>
        </S.UsefulLinks>
        <S.CopyUseFullLinks>
          <ul>
            <li>
              ©{date.getFullYear()} Proteção PIX by EXA - Todos os direitos
              reservados
            </li>

            <li>
              <a href='/?show-bot'>Ajuda</a>
            </li>

            <li>
              <a href='/faq'>FAQ</a>
            </li>

            <li>
              <a
                href='https://dpo.privacytools.com.br/policy-view/JmYBEVB2D/3/policy/pt_BR?s=1655906256350'
                target='_blank'
                rel='noopener noreferrer'
              >
                Termos de Uso e Política de Privacidade
              </a>
            </li>

            <li>
              <a
                href='https://portal.privacytools.com.br/portal/afff6f7a-f6ac-41e6-838c-fabbe060214b'
                target='_blank'
                rel='noopener noreferrer'
              >
                Portal de Privacidade
              </a>
            </li>
          </ul>
        </S.CopyUseFullLinks>
      </S.Container>
    </S.Section>
  )
}

export default Doubts
