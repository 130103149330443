import { SENTRY_DSN, NODE_ENV } from '@/constants'
import * as Sentry from '@sentry/react'

Sentry.init({
  environment: NODE_ENV,
  dsn: SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 0.1,
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.5,
})
