import React from 'react'

const IconAlarm = ({ onClick, color = '#004BC1' }) => {
  return (
    <svg
      width='56'
      height='56'
      viewBox='0 0 56 56'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <g clipPath='url(#clip0_25458_59084)'>
        <path
          d='M11.449 30H4C2.34315 30 1 28.6569 1 27V17.6667C1 16.0098 2.34315 14.6667 4 14.6667H10.525C11.127 14.6667 11.7151 14.4855 12.2129 14.1468L28.3121 3.19036C30.3037 1.83495 33 3.26141 33 5.6705V38.4737C33 40.8537 30.3615 42.286 28.3657 40.9895L22.2245 37'
          stroke={color}
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M42 22H54'
          stroke={color}
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M40.7578 16.2427L49.2431 7.75739'
          stroke={color}
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M40.7578 27.7573L49.2431 36.2426'
          stroke={color}
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_25458_59084'>
          <rect width='56' height='56' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconAlarm
