import React from 'react'

import Spinner from '@/components/Presentational/Spinner'

import Details from '../Details'
import Items from '../Items'
import * as S from './styled'

const EmptyData = () => {
  return (
    <S.ContentCenter>
      <p data-testid='empty-data'>
        Não encontrados nenhum sinistro <br />
        relacionado a este aparelho.
      </p>
    </S.ContentCenter>
  )
}

const Loader = () => {
  return (
    <S.ContentCenter data-testid='history-loader'>
      <Spinner />
    </S.ContentCenter>
  )
}

const History = ({
  loader,
  history,
  historyDetails,
  onClickItem,
  onChange,
  onSubmit,
}) => {
  return (
    <S.Container>
      <S.Content>
        <S.Description>
          <S.Title>
            {historyDetails
              ? 'Detalhes do sinistro'
              : 'Histórico de solicitações'}
          </S.Title>
        </S.Description>

        {loader ? (
          <Loader />
        ) : history.length === 0 ? (
          <EmptyData />
        ) : Object.values(historyDetails).length > 0 ? (
          <Details
            sinister={historyDetails}
            onChange={onChange}
            onSubmit={onSubmit}
          />
        ) : (
          <React.Fragment>
            {history.map((item, key) => (
              <Items
                iconRight
                key={key}
                id={item.id}
                title={item.title}
                status={item.status}
                onClick={() => onClickItem(item)}
              />
            ))}
          </React.Fragment>
        )}
      </S.Content>
    </S.Container>
  )
}

export default History
