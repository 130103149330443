import React, { useState } from 'react'

const MenuHelp = () => {
  const [goBack, setGoBack] = useState(false)

  return (
    <div className='menu-sliding' id='menu-help'>
      <div className='mask' />
      <div className='bar'>
        <div className='top-bar'>
          <div
            className='title-section'
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (goBack) {
                setGoBack(false)
                return
              }

              document.getElementById('menu-help').style.display = 'none'
            }}
          >
            <div className='group'>
              <svg
                width={24}
                height={24}
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M9.93934 22.0607C10.5251 22.6464 11.4749 22.6464 12.0607 22.0607C12.6464 21.4749 12.6464 20.5251 12.0607 19.9393L5.88909 13.7678C5.80416 13.6828 5.72662 13.5933 5.65646 13.5L21 13.5C21.8284 13.5 22.5 12.8284 22.5 12C22.5 11.1716 21.8284 10.5 21 10.5L5.65646 10.5C5.72661 10.4067 5.80416 10.3172 5.88909 10.2322L12.0607 4.06066C12.6465 3.47487 12.6465 2.52513 12.0607 1.93934C11.4749 1.35355 10.5251 1.35355 9.93935 1.93934L3.76777 8.11091C1.61988 10.2588 1.61988 13.7412 3.76777 15.8891L9.93934 22.0607Z'
                  fill='#1F1F1F'
                />
              </svg>
              <p>{goBack ? 'Voltar para o menu' : 'Voltar'}</p>
            </div>
          </div>
        </div>

        <div className='title-section'>
          <div className='group'>
            <p>Ficou com alguma dúvida?</p>
          </div>
        </div>

        <div className='menu-content-help'>
          <div className='box'>
            <svg
              width='32'
              height='32'
              viewBox='0 0 32 32'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <rect
                width='31.9998'
                height='32'
                rx='2'
                fill='#004BC1'
                fillOpacity='0.1'
              />
              <path
                d='M15.9999 6.75V6.74974L15.9886 6.75026C10.6529 6.99282 6.75005 11.0028 6.75 16C6.75 21.1003 10.8993 25.25 16 25.25C17.6908 25.25 19.3261 24.7924 20.7661 23.9229L23.6468 25.1568C23.7933 25.2197 23.9472 25.25 24.1 25.25C24.3988 25.25 24.693 25.1332 24.9132 24.913C25.2456 24.5806 25.3422 24.0786 25.1567 23.6469L23.9223 20.7665C24.7927 19.3264 25.2499 17.6912 25.2499 16C25.2499 10.8997 21.1006 6.75 15.9999 6.75ZM21.6823 19.9909L21.6821 19.9912C21.6069 20.0984 21.6073 20.2213 21.6164 20.3012C21.6264 20.3884 21.6534 20.48 21.6855 20.5666C21.7502 20.741 21.8532 20.9414 21.9592 21.1317C22.0548 21.3031 22.158 21.4751 22.2465 21.6226C22.2571 21.6403 22.2675 21.6575 22.2776 21.6744C22.378 21.8419 22.4436 21.9544 22.466 22.0065L22.8105 22.8108L22.0079 22.4669C22.0077 22.4668 22.0076 22.4667 22.0074 22.4666C21.9544 22.4435 21.8416 22.3774 21.6747 22.2773C21.6595 22.2682 21.6441 22.2589 21.6283 22.2495C21.4795 22.1601 21.3053 22.0555 21.1317 21.9588C20.9414 21.8527 20.741 21.75 20.5665 21.6855C20.4799 21.6535 20.3883 21.6265 20.301 21.6167C20.2211 21.6078 20.0984 21.6077 19.9916 21.6831C18.8135 22.5123 17.4338 22.9498 15.9997 22.9498C12.1678 22.9498 9.04971 19.8317 9.04971 15.9998C9.04971 12.1678 12.1678 9.0498 15.9997 9.0498C19.8317 9.0498 22.9497 12.1678 22.9497 15.9998C22.9497 17.4335 22.5117 18.8139 21.6823 19.9909Z'
                fill='#004BC1'
                stroke='#004BC1'
                strokeWidth='0.5'
              />
            </svg>

            <div>
              <p>
                <strong>Chat</strong>
              </p>

              <p>
                Tire suas dúvidas de maneira prática acessando nosso chatbot.
              </p>

              <a
                href='https://fs-chatbot-orquestrador.web.app/?p=protecao-pix'
                target='_blank'
                rel='noopener noreferrer'
              >
                Acessar
              </a>
            </div>
          </div>

          <div className='box'>
            <svg
              width='18'
              height='18'
              viewBox='0 0 18 18'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <rect
                x='1'
                y='1'
                width='16'
                height='16'
                rx='3'
                stroke='#004BC1'
                strokeWidth='2'
              />
              <rect
                x='4.5'
                y='4.5'
                width='9'
                height='3'
                rx='0.5'
                stroke='#004BC1'
              />
              <rect
                x='4.5'
                y='10.5'
                width='9'
                height='3'
                rx='0.5'
                stroke='#004BC1'
              />
            </svg>

            <div>
              <p>
                <strong>FAQ</strong>
              </p>

              <p>
                Aprenda com as dúvidas dos outros usuários, veja quais são as
                perguntas mais frequentes.
              </p>

              <a href='/faq' rel='noopener noreferrer'>
                Acessar
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default MenuHelp
