import React from 'react'

import IconLoader from '@/components/Presentational/Icons/IconLoader'

import * as S from './styled'

const InputGroup = ({
  label,
  dataTestId,
  type,
  placeholder,
  name,
  value,
  required = false,
  loader = false,
  onChange,
  disabled,
  onFocus,
  onBlur,
  max,
  min,
}) => {
  return (
    <S.Input>
      {label}
      <input
        data-testid={dataTestId}
        type={type}
        placeholder={placeholder}
        name={name}
        required={required}
        onChange={onChange}
        value={value}
        disabled={disabled}
        onFocus={onFocus}
        onBlur={onBlur}
        max={max}
        min={min}
      />
      {loader && <IconLoader />}
    </S.Input>
  )
}

export default InputGroup
