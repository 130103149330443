import React from 'react'
import { NavLink } from 'react-router-dom'

import LOGO from '@/assets/img/logo-protecao-pix-colorido.svg'
import Chatbot from '@/components/Containers/Chatbot'
import Footer from '@/components/Presentational/Footer'
import { LoaderProvider } from '@/contexts/Loader'
import { SettingsProvider } from '@/contexts/Settings'
import { ToastProvider } from '@/contexts/Toast'

import * as S from './styled'

const Layout = ({ children }) => {
  return (
    <SettingsProvider>
      <LoaderProvider>
        <ToastProvider>
          <S.Main>
            <S.Header>
              <NavLink to='/'>
                <img
                  src={LOGO}
                  alt='Logo Proteção PIX'
                  width='1162'
                  height='212'
                />
              </NavLink>
            </S.Header>
            <S.Section>{children}</S.Section>
            <Footer />
            <Chatbot />
          </S.Main>
        </ToastProvider>
      </LoaderProvider>
    </SettingsProvider>
  )
}

export default Layout
