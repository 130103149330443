import React from 'react'

import loading from '@/assets/img/loading.gif'

const IconLoader = ({ width = 25, height = 25 }) => {
  return (
    <img
      src={loading}
      data-testid='icon-loader'
      alt='loading'
      style={{ width: width, height: height }}
    />
  )
}

export default IconLoader
