import colors from '@/styles/colors'

import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border: 2px solid #1f1f1f;
  border-radius: 8px;
  padding: 10px;
  width: 100%;

  > input {
    color: ${colors.grey};
    font-size: 16px;
    line-height: 20px;
    width: 100%;
    border: none;
    padding: 0px;
  }
`
