import * as Sentry from '@sentry/react'

export function captureException({ error, tag, endpoint, body }) {
  Sentry.captureException(new Error(tag), {
    tags: {
      tag,
      endpoint,
    },
    extra: {
      body,
      info: error,
    },
  })
}
