import colors from '@/styles/colors'

import styled from 'styled-components'

export const Main = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto 150px;

  @media screen and (max-width: 767px) {
    margin: auto 10px;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 50px;

  @media screen and (max-width: 767px) {
    flex-direction: column-reverse;
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: 20px;

    h1 {
      font-size: 28px;
      color: ${colors.primary};
    }
  }
`

export const Link = styled.a`
  width: 100%;
  background-color: ${colors.primary};
  text-decoration: none;
  color: ${colors.white};
  padding: 20px;
  border-radius: 20px;
  text-align: center;
`
