import React, { useEffect, useState } from 'react'

import { useAuth } from '@/contexts/Authentication'
import { useLoader } from '@/contexts/Loader'
import { usePermissions } from '@/contexts/Permissions'
import useToast from '@/hooks/useToast'
import { logAnalyticsEvent } from '@/services/Analytics'
import { postNotification } from '@/services/APIService/Notification'

import Card from './atoms/Card'
import Drawer from './atoms/Drawer'
import HelpBlockIOS from './atoms/HelpBlockIOS'

const BlockPhone = () => {
  const {
    user: {
      profile: {
        device_os,
        device_id,
        subscription: { premium },
        lastAction,
      },
      mobile: { mDeviceMarketName },
    },
  } = useAuth()
  const { permissions } = usePermissions()
  const { setLoader } = useLoader()
  const { toastrMessage } = useToast()
  const [isChecked, setIsChecked] = useState(false)
  const [status, setStatus] = useState(null)
  const [isOpen, setIsOpen] = useState(false)

  const handleClickSendNotification = async (e) => {
    const { checked } = e.target

    if (!status) {
      toastrMessage(
        'error',
        'Ative no app a permissão de Administrador de dispositivo'
      )
      return
    }

    setLoader(true)

    const notification = checked ? 'remoteLoking' : 'remoteUnloking'

    logAnalyticsEvent('lock_send', {
      device_model: mDeviceMarketName,
    })

    const postNotificationResponse = await postNotification(
      device_id,
      notification
    )

    if (!postNotificationResponse.status) {
      setLoader(false)
      toastrMessage('error', postNotificationResponse.message)
      logAnalyticsEvent('lock_failure', {
        device_model: mDeviceMarketName,
      })
      return
    }

    logAnalyticsEvent('lock_confirm', {
      device_model: mDeviceMarketName,
    })

    setLoader(false)
    setIsChecked(checked)
    toastrMessage(
      'success',
      `Dispositivo ${checked ? 'bloqueado' : 'desbloqueado'} com sucesso`
    )
  }

  const handleClickDrawerInfo = () => {
    if (device_os === 'ios') {
      setIsOpen(true)
      return
    } else if (!premium) {
      toastrMessage('info', 'Desculpe, você não tem o Proteção PIX ativa')
      return
    }
  }

  useEffect(() => {
    if (permissions) {
      setStatus(permissions.block)
    }
  }, [permissions])

  return (
    <React.Fragment>
      <Card
        status={status}
        deviceOS={device_os}
        checked={isChecked}
        premium={premium}
        disabled={lastAction === 'CLEANED' || lastAction === 'LOGGED_OUT'}
        onClick={handleClickDrawerInfo}
        onChange={handleClickSendNotification}
      />
      {isOpen && (
        <Drawer onClick={() => setIsOpen(false)}>
          <HelpBlockIOS />
        </Drawer>
      )}
    </React.Fragment>
  )
}
export default BlockPhone
