/** FIREBASE ENVIRONMENT */
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID
export const FIREBASE_STORAGE_BUCKET =
  process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
export const FIREBASE_MESSAGING_SEND_ID =
  process.env.REACT_APP_FIREBASE_MESSAGING_SEND_ID
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID
export const FIREBASE_MEASUREMENT_ID =
  process.env.REACT_APP_FIREBASE_MEASUREMENT_ID

/** APPLICATION ENVIRONMENT */
export const NODE_ENV = process.env.NODE_ENV
export const SECRET_KEY = process.env.REACT_APP_SECRET_KEY
export const URL_BACKEND = process.env.REACT_APP_URL_BACKEND
export const API_KEY = process.env.REACT_APP_API_KEY
export const URL_BACKEND_BFF = process.env.REACT_APP_URL_BACKEND_BFF
export const REQUESTER_NAME = process.env.REACT_APP_REQUESTER_NAME
export const BRAND_NAME = process.env.REACT_APP_BRAND_NAME
export const TOKEN_BACKEND_BFF = process.env.REACT_APP_TOKEN_BACKEND_BFF
export const URL_CHATBOT = process.env.REACT_APP_URL_CHATBOT
export const LINK_DOWNLOAD = process.env.REACT_APP_LINK_DOWNLOAD
export const API_KEY_ADMIN_BACKEND = process.env.REACT_APP_API_KEY_ADMIN_BACKEND
export const AUTHORIZE_ADMIN_BACKEND =
  process.env.REACT_APP_AUTHORIZE_ADMIN_BACKEND
export const URL_CLOUD_FUNCTION = process.env.REACT_APP_URL_CLOUD_FUNCTION
export const SESSION_TIME = process.env.REACT_APP_SESSION_TIME
export const SESSION_SECOND_TIME = process.env.REACT_APP_SESSION_SECOND_TIME
export const LOGOUT_TIME = process.env.REACT_APP_LOGOUT_TIME

export const URL_APP_STORE = process.env.REACT_APP_URL_APP_STORE
export const URL_GOOGLE_PLAY = process.env.REACT_APP_URL_GOOGLE_PLAY
export const URL_UNIFICADA = process.env.REACT_APP_URL_UNIFICADA

export const APP_MAPS_API_KEY = process.env.REACT_APP_MAPS_API_KEY
export const URL_GOOGLE_GEOCODER = process.env.REACT_APP_URL_GOOGLE_GEOCODER

export const LIMIT_REQUEST = 5

export const LIMIT_REQUEST_PANIC_BUTTON = 10

export const FERNET_KEY = process.env.REACT_APP_FERNET_KEY
export const TERMS_OF_USE = process.env.REACT_APP_URL_TERMS_OF_USE
export const PRIVACY_TOOLS = process.env.REACT_APP_URL_PRIVACY_TOOLS

export const URL_ICLOUD_APPLE = process.env.REACT_APP_URL_ICLOUD_APPLE
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
