import styled from 'styled-components'

const handleStatusType = (status) => {
  switch (status) {
    case 'pending':
      return '#FF3724'
    case 'analysis':
      return '#FFD43B'
    default:
      return '#B8FF06'
  }
}

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
`

export const Status = styled.div`
  display: none;

  @media screen and (max-width: 739px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
  }
`

export const Title = styled.h3`
  font-size: 18px;
`

export const SubTitle = styled.small`
  font-size: 12px;
`

export const Label = styled.label`
  padding: 0px 10px;
  color: #000000;
  background-color: ${({ status }) => handleStatusType(status)};
  border-radius: 20px;
  font-size: 12px;
  width: ${({ status }) => (status === 'analysis' ? '110px' : '75px')};
  white-space: nowrap;
`
