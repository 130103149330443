import React from 'react'

const IconSecurity = ({ onClick, color = '#004BC1' }) => {
  return (
    <svg
      width='56'
      height='56'
      viewBox='0 0 56 56'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M27.7482 4.67454C27.9157 4.66391 28.0837 4.66391 28.2511 4.67454C32.6237 4.95205 36.7106 6.36771 43.3687 9.14447C44.6495 9.67859 45.5581 10.8427 45.7652 12.2145C46.7107 18.478 46.9212 23.8912 46.3539 28.5845C45.6362 34.5226 43.6723 39.3056 40.4009 43.1707C37.66 46.4092 34.0591 48.9339 29.6667 50.9663C28.6092 51.4556 27.3901 51.4556 26.3327 50.9663C21.9402 48.9339 18.3393 46.4092 15.5984 43.1707C12.3271 39.3056 10.3632 34.5226 9.64544 28.5845C9.07816 23.8912 9.28861 18.478 10.2341 12.2145C10.4412 10.8427 11.3499 9.67859 12.6306 9.14447C19.2887 6.36771 23.3756 4.95205 27.7482 4.67454ZM27.9997 47.3652C23.9915 45.5107 20.9076 43.3006 18.6282 40.6076C15.9375 37.4285 14.2265 33.4092 13.5858 28.1085C13.0743 23.8771 13.2477 18.8414 14.1587 12.8066C20.8197 10.0286 24.3581 8.86568 27.9997 8.63456C31.6413 8.86568 35.1796 10.0286 41.8407 12.8066C42.7517 18.8414 42.925 23.8771 42.4136 28.1085C41.7729 33.4092 40.0618 37.4285 37.3711 40.6076C35.0918 43.3006 32.0078 45.5107 27.9997 47.3652Z'
        fill={color}
      />
    </svg>
  )
}

export default IconSecurity
