import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  cursor: pointer;
`

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 30px;
`

export const Description = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 40px;
`

export const Button = styled.button`
  background: none;
  border: none;
  color: hsla(0, 0%, 30%, 1);
  padding: 0px;
`
