import React from 'react'

const IconArrow = ({ onClick, color = '#004BC1' }) => {
  return (
    <svg
      width='21'
      height='22'
      viewBox='0 0 21 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.0607 21.0607C12.4749 21.6464 11.5251 21.6464 10.9393 21.0607C10.3535 20.4749 10.3535 19.5251 10.9393 18.9393L17.1109 12.7678C17.2011 12.6776 17.2829 12.5822 17.3565 12.4827L2.00098 12.4989C1.17255 12.4998 0.500269 11.829 0.49939 11.0005C0.49851 10.1721 1.16937 9.49982 1.9978 9.49894L17.3304 9.48268C17.2637 9.3956 17.1906 9.3119 17.1109 9.23223L10.9393 3.06066C10.3535 2.47487 10.3535 1.52513 10.9393 0.93934C11.5251 0.353553 12.4749 0.353553 13.0607 0.93934L19.2322 7.11091C21.3801 9.2588 21.3801 12.7412 19.2322 14.8891L13.0607 21.0607Z'
        fill={color}
      />
    </svg>
  )
}

export default IconArrow
