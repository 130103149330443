import React from 'react'

const IconInformation = ({ color = '#123382' }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM11 10V18H13V10H11ZM11.0764 7.71228C11.2994 7.90409 11.6072 8 12 8C12.3928 8 12.7006 7.89344 12.9236 7.68031C13.1465 7.46718 13.258 7.19544 13.258 6.86509C13.258 6.49211 13.1465 6.20972 12.9236 6.0179C12.7113 5.81543 12.4034 5.71419 12 5.71419C11.6178 5.71419 11.3153 5.82076 11.0924 6.03389C10.8694 6.24702 10.758 6.52408 10.758 6.86509C10.758 7.22741 10.8641 7.5098 11.0764 7.71228Z'
        fill={color}
      />
    </svg>
  )
}

export default IconInformation
