import React from 'react'
import ReactDOM from 'react-dom/client'
import '@/firebase'
import '@/styles/styles.css'

import { NODE_ENV } from '@/constants'
import Routes from '@/routes'
import '@/sentry'

if (NODE_ENV === 'production') {
  console.log = () => {}
  console.warn = () => {}
  console.error = () => {}
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<Routes />)
