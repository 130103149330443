import React, { useEffect, useState } from 'react'

import { useAuth } from '@/contexts/Authentication'
import { useLoader } from '@/contexts/Loader'
import { usePermissions } from '@/contexts/Permissions'
import useEvents from '@/hooks/useEvents'
import useToast from '@/hooks/useToast'
import { logAnalyticsEvent } from '@/services/Analytics'
import { postNotification } from '@/services/APIService/Notification'

import Card from './atoms/Card'
import DataClearHelpIOS from './atoms/DataClearHelpIOS'
import Drawer from './atoms/Drawer'
import Form from './atoms/Form'

const ClearData = () => {
  const {
    user: {
      profile: {
        device_os,
        device_id,
        subscription: { premium },
        lastAction,
      },
      mobile: { device_model },
    },
  } = useAuth()
  const { listEvent, removeEvent } = useEvents()
  const { permissions } = usePermissions()
  const { setLoader } = useLoader()
  const { toastrMessage } = useToast()
  const [deletePhone, setDeletePhone] = useState('')
  const [status, setStatus] = useState(null)
  const [display, setDisplay] = useState({
    drawer: false,
    form: false,
    information: false,
  })

  const handleChange = (e) => {
    const { value } = e.target
    setDeletePhone(value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    setLoader(true)

    logAnalyticsEvent('data_cleaning_send', {
      device_model: device_model,
    })

    const postNotificationResponse = await postNotification(
      device_id,
      'remoteWipe'
    )

    if (!postNotificationResponse.status) {
      setLoader(false)
      toastrMessage('error', postNotificationResponse.message)
      return
    }

    logAnalyticsEvent('confirm_delete_date', {
      device_model: device_model,
    })

    toastrMessage('success', 'Comando enviado com sucesso')
  }

  const handleClickClearData = () => {
    if (device_os === 'ios') {
      setDisplay((display) => ({
        ...display,
        drawer: true,
        information: true,
      }))

      return
    } else if (!premium) {
      toastrMessage('info', 'Desculpe, você não tem o Proteção PIX ativa')
      return
    } else if (!status) {
      toastrMessage(
        'error',
        'Ative no app a permissão de Administrador de dispositivo'
      )
      return
    } else {
      setDisplay((display) => ({
        ...display,
        drawer: true,
        form: true,
      }))
    }
  }

  useEffect(() => {
    if (permissions) {
      setStatus(permissions.clearData)
    }
  }, [permissions])

  useEffect(() => {
    listEvent('drawerDeletePhone', () =>
      setDisplay((data) => ({
        ...data,
        drawer: true,
        form: true,
      }))
    )
    return () => {
      removeEvent('drawerDeletePhone', () =>
        setDisplay((data) => ({
          ...data,
          drawer: true,
          form: true,
        }))
      )
    }
  }, [])

  return (
    <React.Fragment>
      <Card
        status={status}
        deviceOS={device_os}
        disabled={lastAction === 'CLEANED' || lastAction === 'LOGGED_OUT'}
        onClick={() => handleClickClearData()}
      />

      {display.drawer && (
        <Drawer
          onClick={() =>
            setDisplay((data) => ({
              ...data,
              drawer: false,
              form: false,
              information: false,
            }))
          }
        >
          {display.form && (
            <Form
              deletePhone={deletePhone}
              onChange={handleChange}
              onSubmit={handleSubmit}
            />
          )}

          {display.information && <DataClearHelpIOS />}
        </Drawer>
      )}
    </React.Fragment>
  )
}

export default ClearData
