import styled from 'styled-components'

export const Main = styled.div``

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  cursor: pointer;
  margin-bottom: 30px;
`

export const Title = styled.p`
  margin: 0px;
  color: #333333;
  font-size: 16px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Items = styled.div`
  flex-direction: column;
  gap: 10px;
  margin: 0px;
  padding: 15px;
`
