import styled from 'styled-components'

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  > h1 {
    font-family: 'Space Grotesk';
    font-size: 60px;
    line-height: 45px;
    color: #bd2469;
    font-weight: 500;
  }

  > p {
    font-family: 'Space Grotesk';
    font-size: 16px;
  }

  @media screen and (max-width: 767px) {
    > h1 {
      font-size: 32px;
    }
  }
`
