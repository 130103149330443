import styled from 'styled-components'

const handleStatusType = (status) => {
  switch (status) {
    case 'pending':
      return '#FF3724'
    case 'analysis':
      return '#FFD43B'
    default:
      return '#B8FF06'
  }
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  border: none;
  border-radius: 24px;
  box-shadow: 0px 0px 8px 0px #00000014;
  ${(props) => (props.spinner ? 'pointer-events: none;' : 'cursor: pointer;')};

  > h3 {
    font-size: 18px;
    margin-bottom: 0px;
    color: #121212;
  }

  > hr {
    border: 1px solid hsla(201, 13%, 88%, 1);
    margin: 5px auto;
  }
`

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
`

export const Title = styled.h3`
  font-size: 18px;
`

export const Label = styled.label`
  padding: 0px 10px;
  color: #000000;
  background-color: ${({ status }) => handleStatusType(status)};
  border-radius: 20px;
  font-size: 12px;
  width: ${({ status }) => (status === 'analysis' ? '110px' : '75px')};
  white-space: nowrap;
`
