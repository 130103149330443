import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #f2f2f2;
  border-radius: 20px;
  padding: 20px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
`

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Paragraph = styled.p`
  margin: 0px;
`
