import React, { createContext, useContext, useEffect, useState } from 'react'

import { useAuth } from '@/contexts/Authentication'
import { db } from '@/firebase'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
export const PermissionsContext = createContext({})

export const PermissionsProvider = ({ children }) => {
  const { user } = useAuth()
  const [permissions, setPermissions] = useState(null)

  const getPermissions = async () => {
    const {
      profile: {
        device_id,
        device_os,
        subscription: { premium },
      },
    } = user

    const q = query(
      collection(db, 'permission'),
      where('device_id', '==', device_id)
    )

    onSnapshot(q, (snap) => {
      const data = snap.docs
        .map((doc) => doc.data())
        .filter((item) => item.deleted_at === null)
        .shift()

      const {
        admin_granted: adminGranted,
        location_granted: locationGranted,
        camera_granted: cameraGranted,
        alarm_granted: alarmGranted,
        overlay_granted: overlayGranted,
        notification_granted: notificationGranted,
      } = data

      let block = false
      let panic = false
      let protect = false
      let clearData = false
      const alarm = notificationGranted && alarmGranted
      const location = notificationGranted && locationGranted
      const camera = notificationGranted && cameraGranted

      if (device_os === 'ios') {
        block = notificationGranted && premium && adminGranted
        clearData = notificationGranted && premium && adminGranted
        protect = premium && notificationGranted && location
      } else {
        block = notificationGranted && premium && adminGranted && overlayGranted
        clearData = notificationGranted && premium && adminGranted
        protect = premium && notificationGranted && location && alarm && camera
        panic = premium
      }

      setPermissions({
        block,
        clearData,
        camera,
        alarm,
        location,
        protect,
        panic,
      })
    })
  }

  useEffect(() => {
    getPermissions()
  }, [user])

  return (
    <PermissionsContext.Provider
      value={{
        permissions,
        setPermissions,
      }}
    >
      {children}
    </PermissionsContext.Provider>
  )
}

export const usePermissions = () => useContext(PermissionsContext)
