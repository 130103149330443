import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
`

export const Content = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  margin: 25px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 10px;
`
