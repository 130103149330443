import React from 'react'

import styles from '../../styles.module.css'
import * as S from './styled'

const StepThree = ({
  feedback,
  checkedItems,
  onChange,
  onSubmit,
  onClickPrevious,
}) => {
  const checkboxes = [
    { name: 'reason1', label: 'Não preciso mais' },
    { name: 'reason2', label: 'Estou migrando de produto' },
    { name: 'reason3', label: 'Valor da assinatura' },
    { name: 'reason4', label: 'Outro' },
  ]

  return (
    <S.Container onSubmit={onSubmit} data-testid='step-three'>
      <p style={{ marginBottom: '24px', marginTop: '20px' }}>
        <strong>Porquê está apagando esta conta?</strong>
      </p>
      {checkboxes.map((checkbox) => (
        <label className={styles.label} key={checkbox.name}>
          <input
            type='checkbox'
            name={checkbox.name}
            checked={!!checkedItems[checkbox.name]}
            onChange={onChange}
            value={checkbox.label}
          />
          <p style={{ fontSize: 16 }}> {checkbox.label}</p>
        </label>
      ))}

      <p style={{ marginBottom: '14px', marginTop: '40px' }}>
        <strong>Como podemos melhorar?</strong>
      </p>

      <label>
        <textarea
          data-testid='input-textarea'
          name='feedback'
          value={feedback}
          rows='8'
          cols='30'
          maxLength='500'
          placeholder='Insira seu texto aqui...'
          onChange={onChange}
        ></textarea>
        <div
          id='textarea_feedback'
          style={{
            width: '100%',
            textAlign: 'right',
            marginTop: '5px',
            fontSize: '14px',
          }}
        >
          0/500
        </div>
      </label>

      <S.Button>
        <button
          type='button'
          onClick={onClickPrevious}
          data-testid='button-previous-step-three'
        >
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M9.93934 22.0607C10.5251 22.6464 11.4749 22.6464 12.0607 22.0607C12.6464 21.4749 12.6464 20.5251 12.0607 19.9393L5.88909 13.7678C5.80416 13.6828 5.72662 13.5933 5.65646 13.5L21 13.5C21.8284 13.5 22.5 12.8284 22.5 12C22.5 11.1716 21.8284 10.5 21 10.5L5.65646 10.5C5.72661 10.4067 5.80416 10.3172 5.88909 10.2322L12.0607 4.06066C12.6465 3.47487 12.6465 2.52513 12.0607 1.93934C11.4749 1.35355 10.5251 1.35355 9.93935 1.93934L3.76777 8.11091C1.61988 10.2588 1.61988 13.7412 3.76777 15.8891L9.93934 22.0607Z'
              fill='#FCFCFC'
            />
          </svg>
          Anterior
        </button>

        <button type='submit' data-testid='button-next-step-three'>
          Próximo
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M14.0613 22.0607C13.4755 22.6464 12.5257 22.6464 11.9399 22.0607C11.3542 21.4749 11.3542 20.5251 11.9399 19.9393L18.1115 13.7678C18.2017 13.6776 18.2835 13.5822 18.3571 13.4827L3.00159 13.4989C2.17316 13.4998 1.50088 12.829 1.5 12.0005C1.49912 11.1721 2.16998 10.4998 2.99841 10.4989L18.331 10.4827C18.2643 10.3956 18.1912 10.3119 18.1115 10.2322L11.9399 4.06066C11.3542 3.47487 11.3542 2.52513 11.9399 1.93934C12.5257 1.35355 13.4755 1.35355 14.0613 1.93934L20.2328 8.11091C22.3807 10.2588 22.3807 13.7412 20.2328 15.8891L14.0613 22.0607Z'
              fill='#FFFFFF'
            />
          </svg>
        </button>
      </S.Button>
    </S.Container>
  )
}

export default StepThree
