import React from 'react'

import * as S from './styled'

const Information = () => {
  return (
    <S.Information>
      <h3>Canais de atendimento</h3>
      <small>
        O prazo para análise do sinistro é de até 30 dias corridos, contados a
        partir da entrega de toda a documentação solicitada. Para saber mais
        sobre cobertura e andamento do seu pedido, entre em contato com a
        Mapfre:
      </small>
      <br />
      <br />
      <small>SAC: 08007754545</small>
      <br />
      <small>Deficientes auditivos e/ou fala: 0800 775 5045</small>
    </S.Information>
  )
}

export default Information
