import styled from 'styled-components'

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  cursor: pointer;
  margin-top: 30px;
`

export const Title = styled.p`
  margin: 0px;
  color: #333333;
  font-size: 16px;
`
