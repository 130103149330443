import colors from '@/styles/colors'

import styled from 'styled-components'

export const Button = styled.button`
  background: transparent;
  color: black;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0px;
  color: ${colors.grey};
`
