import React from 'react'

const IconClose = ({
  onClick,
  color = '#1F1F1F',
  width = '25',
  height = '24',
}) => {
  return (
    <svg
      data-testid='icon-close'
      width={width}
      height={height}
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      <path
        d='M5.00391 19.125L7.13437 16.9269C9.13898 14.8587 10.1413 13.8245 11.3041 13.4386C12.3266 13.0994 13.4312 13.0994 14.4537 13.4386C15.6165 13.8245 16.6188 14.8587 18.6234 16.9269L20.7539 19.125'
        stroke={color}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path
        d='M20.7539 4.875L18.6234 7.07309C16.6188 9.14134 15.6165 10.1755 14.4537 10.5614C13.4312 10.9006 12.3266 10.9006 11.3041 10.5614C10.1413 10.1755 9.13898 9.14134 7.13437 7.07309L5.00391 4.875'
        stroke={color}
        strokeWidth='3'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default IconClose
