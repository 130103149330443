import styled from 'styled-components'

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const Container = styled.div`
  display: none;

  @media screen and (max-width: 739px) {
    display: flex;
    flex-direction: row;
    gap: 10px;
    cursor: pointer;
  }
`
export const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  @media screen and (max-width: 739px) {
    span {
      font-size: 14px;
      word-wrap: break-word;
      text-align: end;
    }
  }
`

export const LabelStatus = styled.div`
  display: flex;
  gap: 5px;
  background: #d1d1d1;
  padding: 10px 20px;
  border-radius: 12px;
  align-items: center;
  color: white;
`
