import 'moment/locale/pt-br'
import React, { useEffect, useState } from 'react'
import { Lightbox } from 'react-modal-image'

import Grid from '@/components/Presentational/Grid'
import IconLoader from '@/components/Presentational/Icons/IconLoader'
import ImageGallery from '@/components/Presentational/ImageGallery'
import { LIMIT_REQUEST, LIMIT_REQUEST_PANIC_BUTTON } from '@/constants'
import { useAuth } from '@/contexts/Authentication'
import { useLoader } from '@/contexts/Loader'
import { usePermissions } from '@/contexts/Permissions'
import useEvents from '@/hooks/useEvents'
import useToast from '@/hooks/useToast'
import { logAnalyticsEvent } from '@/services/Analytics'
import { postNotification } from '@/services/APIService/Notification'
import {
  getUserCapturedImages,
  deleteUserImages,
} from '@/services/APIService/Users'
import moment from 'moment'

import Card from './atoms/Card'
import EmptyData from './atoms/EmptyData'
import Loader from './atoms/Loader'
import * as S from './styled'

const AntiTheftCamera = () => {
  const {
    user: {
      profile: { legacy_user_id, device_id, device_os, lastAction },
      mobile: { device_model },
    },
  } = useAuth()
  const { listEvent, removeEvent } = useEvents()
  const { permissions } = usePermissions()
  const { setLoader } = useLoader()
  const { toastrMessage } = useToast()
  const [modal, setModal] = useState(false)
  const [status, setStatus] = useState(null)
  const [images, setImages] = useState([])
  const [items, setItems] = useState([])
  const [spinner, setSpinner] = useState(false)
  const [emptyData, setEmptyData] = useState(false)
  const [error, setError] = useState(false)
  const [checkedState, setCheckedState] = useState([])
  const [btGetImages, setBtGetImages] = useState(false)
  const [imgModal, setImageModal] = useState('')
  const [stateModal, setStateModal] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [counter, setCounter] = useState(0)

  const listImages = async (imageId) => {
    const getImagesResponse = await getUserCapturedImages(imageId)

    if (!getImagesResponse.status) {
      setSpinner(false)
      toastrMessage('error', getImagesResponse.message)
      return
    }

    if (getImagesResponse.images.length === 0) {
      setLoader(false)
      setSpinner(false)
      setEmptyData(true)
      setImages([])
      return
    }

    const response = getImagesResponse.images.map((item) => ({
      ...item,
      date: moment
        .utc(item.created_at._seconds * 1000)
        .locale('pt-br')
        .format('DD [de] MMMM'),
    }))

    const data = response.reduce((key, item) => {
      const i = item.date
      if (!key[i]) {
        key[i] = []
      }
      key[i].push(item)
      return key
    }, {})

    setEmptyData(false)
    setSpinner(false)
    setImages(data)
    setItems(getImagesResponse.images)
  }

  const updateImages = (userId) => {
    if (counter > 0) {
      setTimeout(() => {
        listImages(userId)
        setCounter(counter - 1)
      }, 5000)
    }

    if (counter === 0) {
      setBtGetImages(false)
    }
  }

  const sendNotificationTakePicture = async () => {
    setLoader(true)

    const postNotificationResponse = await postNotification(device_id, 'camera')

    if (!postNotificationResponse.status) {
      setLoader(false)
      setBtGetImages(false)
      setError(true)
      toastrMessage('error', postNotificationResponse.message)
      return
    }

    setBtGetImages(true)
    setLoader(false)
    toastrMessage('info', 'Comando enviado')
    setError(false)

    logAnalyticsEvent('anti_theft_camera_send', {
      device_model: device_model,
    })

    logAnalyticsEvent('anti_theft_camera_confirm', {
      device_model: device_model,
    })

    setCounter(LIMIT_REQUEST)
  }

  const handleOnSelecting = (e) => {
    const position = e.target.value
    const checked = e.target.checked

    if (!checked) {
      setCheckedState(checkedState.filter((item) => item !== position))
      return
    }

    setCheckedState((prevState) => [...prevState, position])
  }

  const deletePictures = async (e) => {
    e.preventDefault()

    if (checkedState.length === 0) {
      toastrMessage('error', 'Selecione pelo menos uma imagem!')
      return
    }

    logAnalyticsEvent('delete_photos', {
      device_model: device_model,
    })

    setLoader(true)

    const deleteImagesResponse = await deleteUserImages(
      legacy_user_id,
      checkedState
    )

    if (!deleteImagesResponse.status) {
      setLoader(false)
      setError(true)
      toastrMessage('error', deleteImagesResponse.message)
      return
    }

    listImages(legacy_user_id)
    setLoader(false)
    toastrMessage('info', 'Foto(s) apagada(s)')
    setIsChecked(false)
    setCheckedState([])
  }

  const handleOpenLightbox = (e) => {
    setStateModal(true)
    setImageModal(e.target.src)
  }

  const handleCloseLightbox = () => {
    setStateModal(false)
    setImageModal('')
  }

  const handleClickButton = (perms) => {
    if (!perms) {
      toastrMessage('error', 'Ative a proteção de Câmera no app do seu celular')
      return
    }

    setModal(!modal)
    setImages([])
    setSpinner(true)
    listImages(legacy_user_id)
  }

  const handleOnChange = (e) => {
    const { checked } = e.target

    if (!checked) {
      setCheckedState([])
      setIsChecked(checked)
      return
    }

    setIsChecked(checked)
    setCheckedState(items.map((item) => item.id))
  }

  const handleClickCloseDrawer = () => {
    setEmptyData(false)
    setLoader(false)
    setModal(false)
  }

  useEffect(() => {
    updateImages(legacy_user_id)
  }, [counter])

  useEffect(() => {
    const handleEvent = ({ detail: permissions }) => {
      if (permissions.camera) {
        handleClickButton(permissions.camera)
      }
    }

    listEvent('drawerTakePicture', handleEvent)
    return () => {
      removeEvent('drawerTakePicture', handleEvent)
    }
  }, [])

  useEffect(() => {
    if (lastAction === 'PANIC_BUTTON') {
      setCounter(LIMIT_REQUEST_PANIC_BUTTON)
      return
    }
  }, [lastAction])

  useEffect(() => {
    if (permissions) {
      setStatus(permissions.camera)
    }
  }, [permissions])

  if (device_os === 'ios') {
    return null
  }

  return (
    <React.Fragment>
      <Card
        status={status}
        disabled={lastAction === 'CLEANED' || lastAction === 'LOGGED_OUT'}
        onClick={() => handleClickButton(status)}
      />
      {modal && (
        <Grid>
          <S.Container data-testid='close' onClick={handleClickCloseDrawer}>
            <svg
              width={24}
              height={24}
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M9.93934 22.0607C10.5251 22.6464 11.4749 22.6464 12.0607 22.0607C12.6464 21.4749 12.6464 20.5251 12.0607 19.9393L5.88909 13.7678C5.80416 13.6828 5.72662 13.5933 5.65646 13.5L21 13.5C21.8284 13.5 22.5 12.8284 22.5 12C22.5 11.1716 21.8284 10.5 21 10.5L5.65646 10.5C5.72661 10.4067 5.80416 10.3172 5.88909 10.2322L12.0607 4.06066C12.6465 3.47487 12.6465 2.52513 12.0607 1.93934C11.4749 1.35355 10.5251 1.35355 9.93935 1.93934L3.76777 8.11091C1.61988 10.2588 1.61988 13.7412 3.76777 15.8891L9.93934 22.0607Z'
                fill='#1F1F1F'
              />
            </svg>
            <p>Voltar</p>
          </S.Container>
          <S.Header>
            <S.Title>
              <svg
                width='35'
                height='35'
                viewBox='0 0 52 43'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M34 41H44C47.3137 41 50 38.3122 50 34.9985C50 27.3392 50 20.6608 50 13.0015C50 9.68777 47.3137 7 44 7H8C4.68629 7 2 9.68629 2 13V35C2 38.3137 4.68629 41 8 41H18'
                  stroke='#004BC1'
                  strokeWidth='3'
                  strokeLinecap='round'
                />
                <circle
                  cx='26'
                  cy='24'
                  r='9.5'
                  stroke='#004BC1'
                  strokeWidth='3'
                />
                <path
                  d='M6 2H20'
                  stroke='#004BC1'
                  strokeWidth='3'
                  strokeLinecap='round'
                />
              </svg>
              <p>Câmera antirroubo</p>
            </S.Title>
            {Object.keys(images).length > 0 && (
              <svg
                viewBox='0 0 56 56'
                width='35'
                height='35'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                onClick={deletePictures}
                style={{ cursor: 'pointer' }}
                data-testid='delete-image'
              >
                <path
                  d='M42 21.875V42C42 45.866 38.866 49 35 49H21C17.134 49 14 45.866 14 42V21.875'
                  stroke='#004BC1'
                  strokeWidth={5}
                  strokeLinecap='round'
                />
                <path
                  d='M46.375 14H28M9.625 14H28M28 14V7'
                  stroke='#004BC1'
                  strokeWidth={5}
                  strokeLinecap='round'
                />
              </svg>
            )}
          </S.Header>

          <S.Description>
            {spinner ? (
              <Loader />
            ) : emptyData ? (
              <EmptyData />
            ) : (
              Object.keys(images).length > 0 && (
                <ImageGallery
                  images={images}
                  error={error}
                  onSelecting={handleOnSelecting}
                  checkedCards={checkedState}
                  modal={handleOpenLightbox}
                  isChecked={isChecked}
                  onChange={handleOnChange}
                  onClickRemove={() => {
                    setIsChecked(false)
                    setCheckedState([])
                  }}
                />
              )
            )}

            <S.Button
              data-testid='button-send-notification'
              type='button'
              onClick={sendNotificationTakePicture}
              disabled={btGetImages}
            >
              {btGetImages ? (
                <>
                  Capturando imagens <IconLoader />
                </>
              ) : (
                'Tirar nova foto'
              )}
            </S.Button>
          </S.Description>
          {stateModal && (
            <Lightbox
              medium={imgModal}
              hideDownload='false'
              showRotate='true'
              className='modal_images'
              onClose={handleCloseLightbox}
            />
          )}
        </Grid>
      )}
    </React.Fragment>
  )
}

export default AntiTheftCamera
