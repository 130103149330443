import React from 'react'
import { useNavigate } from 'react-router-dom'

import Items from '../Items'
import * as S from './styled'

const Success = ({
  title,
  paymentDate,
  paymentValue,
  protocol,
  status = '',
}) => {
  const navigate = useNavigate()

  return (
    <S.Container data-testid='item-success'>
      <S.Content>
        <Items title={title} status={status} />
        <S.Items>
          <h3>{paymentValue}</h3>
          <p>Valor aprovado</p>
        </S.Items>
        <S.Items>
          <h3>{paymentDate}</h3>
          <p>Previsão de pagamento</p>
        </S.Items>
        <S.Items>
          <h3>{protocol}</h3>
          <p>Protocolo</p>
        </S.Items>
      </S.Content>
      <S.Button
        type='button'
        onClick={() => navigate('/claims')}
        data-testid='button-success'
      >
        Reabrir
      </S.Button>
    </S.Container>
  )
}

export default Success
