import React from 'react'

const IconClearData = ({ onClick, color = '#004BC1' }) => {
  return (
    <svg
      width='56'
      height='56'
      viewBox='0 0 56 56'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <path
        d='M35 21.8751V42.0001C35 45.8661 31.866 49.0001 28 49.0001H14C10.134 49.0001 7 45.8661 7 42.0001V21.8751'
        stroke={color}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path
        d='M39.375 14.0001H21M2.625 14.0001H21M21 14.0001V7.00012'
        stroke={color}
        strokeWidth='3'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default IconClearData
