import React, {
  createContext,
  useEffect,
  useContext,
  useState,
  useMemo,
} from 'react'
import { useNavigate } from 'react-router-dom'

import { useAuth } from '@/contexts/Authentication'
import { useLoader } from '@/contexts/Loader'
import { useDebounce } from '@/hooks/useDebounce'
import useToast from '@/hooks/useToast'
import { getSinisterByCPF, sendSinister } from '@/services/APIService/Sinisters'

import useFormattedData from '../../hooks/useFormattedData'

const FormContext = createContext({})

const initialState = {
  sinisterType: 'Transações Financeiras',
  sinisterDate: '',
  sinisterTime: '',
  eventDescription: '',
  cpfFiles: '',
  residencyProof: '',
  policeReport: '',
  cnhFiles: '',
  invoiceFiles: '',
  iCloudScreen: '',
  bankCode: '',
  bankAgencyNumber: '',
  bankAccountType: '',
  bankAccount: '',
  typePerson: '',
  ownerName: '',
  ownerEmail: '',
  ownerAddressName: '',
  ownerAddressNumber: '',
  ownerAddressState: '',
  ownerAddressCity: '',
  ownerAddressComplement: '',
  ownerCpf: '',
  ownerPhone: '',
  ownerAddressDistrict: '',
  onwerAddressZipcode: '',
  ownerRelationship: '',
  flagTerms: '',
}

export const FormProvider = ({ children }) => {
  const {
    user: {
      profile: { document },
    },
  } = useAuth()
  const navigate = useNavigate()
  const { setLoader } = useLoader()
  const { formattedData } = useFormattedData()
  const { toastrMessage } = useToast()
  const [formData, setFormData] = useState(initialState)
  const [isSaving, setIsSaving] = useState(null)
  const [draftId, setDraftId] = useState('')
  const [spinner, setSpinner] = useState(true)

  const handleSaveForm = useDebounce(async (itens) => {
    setIsSaving(true)

    const data = await formattedData(itens, draftId, 'RASCUNHO')

    const response = await sendSinister(data)

    if (!response.status) {
      toastrMessage(
        'error',
        'Desculpe, ocorreu um erro inesperado ao salvar o rascunho do sinistro, tente novamente!'
      )
      return
    }

    setIsSaving(false)
  }, 1500)

  const handleSubmitForm = async () => {
    setLoader(true)

    const data = await formattedData(formData, draftId, 'EM ANÁLISE')

    const response = await sendSinister(data)

    if (!response.status) {
      setLoader(false)
      toastrMessage(
        'error',
        'Desculpe, ocorreu um erro inesperado na abertura do sinistro!'
      )
      return
    }

    toastrMessage('success', 'Abertura de sinistro efetuada com sucesso!')

    setTimeout(() => {
      navigate('/dashboard')
      setLoader(false)
    }, 3500)
  }

  const updateFormData = (event) => {
    const updatedData = Object.entries(event).reduce((acc, [key, value]) => {
      acc[key] = value
      return acc
    }, {})

    setFormData((prevFormData) => ({
      ...prevFormData,
      ...updatedData,
    }))

    handleSaveForm({
      ...formData,
      ...updatedData,
    })
  }

  const parseFileData = (format, key) => {
    const documents = format
      .map((item) => ({
        ...item,
        name: 'imagem.png',
      }))
      .filter((filter) => filter.filename === key)

    return documents.length > 0 ? documents.shift() : ''
  }

  const getOrCreateClaims = async () => {
    const getSinisterByCPFResponse = await getSinisterByCPF(document)

    if (!getSinisterByCPFResponse.status) {
      toastrMessage(
        'error',
        'Desculpe, ocorreu um erro inesperado ao salvar o rascunho do sinistro, tente novamente!'
      )
      return
    }

    const filtered = getSinisterByCPFResponse.sinisters.filter(
      (filter) => filter.status === 'RASCUNHO'
    )

    if (filtered.length > 0) {
      const item = getSinisterByCPFResponse.sinisters.shift()

      setFormData({
        ...formData,
        sinisterDate: item.open_date.split(' ')[0],
        sinisterTime: item.open_date.split(' ')[1],
        eventDescription: item.event,
        bankAccountType: item.bank_account_type,
        bankAgencyNumber: item.bank_branch,
        bankAccount: item.bank_account_number,
        bankCode: item.bank_code,
        typePerson: item.type_person,
        ownerRelationship: item.claimant_relationship,
        ownerName: item.type_person === 'Fisica' ? '' : item.claimant_name,
        ownerCpf: item.type_person === 'Fisica' ? '' : item.claimant_cpf_cnpj,
        ownerEmail: item.type_person === 'Fisica' ? '' : item.claimant_email,
        ownerPhone: item.type_person === 'Fisica' ? '' : item.claimant_phone,
        ownerAddressName:
          item.type_person === 'Fisica' ? '' : item.address.address_name,
        ownerAddressNumber:
          item.type_person === 'Fisica' ? '' : item.address.address_number,
        ownerAddressComplement:
          item.type_person === 'Fisica' ? '' : item.address.address_complement,
        ownerAddressDistrict:
          item.type_person === 'Fisica' ? '' : item.address.address_district,
        ownerAddressCity:
          item.type_person === 'Fisica' ? '' : item.address.address_city,
        ownerAddressState:
          item.type_person === 'Fisica' ? '' : item.address.address_state,
        onwerAddressZipcode:
          item.type_person === 'Fisica' ? '' : item.address.address_zipcode,
        flagTerms: item.terms_acceptance,
        cpfFiles: parseFileData(item.files, 'cpffiles'),
        cnhFiles: parseFileData(item.files, 'cnhfiles'),
        residencyProof: parseFileData(item.files, 'residencyproof'),
        policeReport: parseFileData(item.files, 'policereport'),
        invoiceFiles: parseFileData(item.files, 'invoicefiles'),
        iCloudScreen: parseFileData(item.files, 'icloudscreen'),
      })
      setDraftId(item.id)
      setSpinner(false)
      return
    }

    const data = await formattedData(formData, '', 'RASCUNHO')

    const sendSinisterResponse = await sendSinister(data)

    if (!sendSinisterResponse.status) {
      toastrMessage(
        'error',
        'Desculpe, ocorreu um erro inesperado ao salvar o rascunho do sinistro, tente novamente!'
      )
      return
    }

    setDraftId(sendSinisterResponse.id)
    setSpinner(false)
  }

  const formIsCompleted = useMemo(() => {
    const counter =
      Object.values(formData).filter((item) => item !== '').length - 1

    return counter === 14 || counter === 15 || counter === 25 || counter === 26 ? false : true
  }, [formData])

  useEffect(() => {
    getOrCreateClaims()
  }, [])

  return (
    <FormContext.Provider
      value={{
        formData,
        formIsCompleted,
        setFormData,
        updateFormData,
        isSaving,
        spinner,
        handleSubmitForm,
      }}
    >
      {children}
    </FormContext.Provider>
  )
}

export const useForm = () => useContext(FormContext)
