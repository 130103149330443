import React from 'react'

const IconDraw = ({ onClick, color = '#004BC1' }) => {
  return (
    <svg
      width='57'
      height='56'
      viewBox='0 0 57 56'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <path
        d='M23.8789 47.2499C23.8789 47.2499 13.3789 47.2499 9.87891 47.2499C6.37891 47.2499 2.87891 43.7499 2.87891 40.2499C2.87891 33.2499 2.87906 20.1249 2.87891 13.1249C2.87883 9.62488 6.37891 6.12488 9.87891 6.12488C13.3789 6.12488 32.6289 6.12488 36.1289 6.12488C39.6289 6.12488 43.1289 9.62488 43.1289 13.1249C43.1289 20.1249 43.1289 27.9999 43.1289 27.9999'
        stroke={color}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.3789 15.7499H34.3789M13.3789 26.2499H34.3789M13.3789 36.7499H20.3789'
        stroke={color}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <circle
        cx='38.7539'
        cy='46.3749'
        r='6.375'
        stroke={color}
        strokeWidth='3'
      />
      <path
        d='M44.8789 36.7499L46.6289 38.4999'
        stroke={color}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path
        d='M30.8789 38.4999L32.6289 36.7499'
        stroke={color}
        strokeWidth='3'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default IconDraw
