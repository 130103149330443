import axios from '@/services/BaseService'
import { captureException } from '@/utils/Instrumentation/Exception'
import { translateMessage } from '@/utils/Translator'

export async function getCertificate(extref) {
  const endpoint = `/v1/certificate/${extref}`

  try {
    const response = await axios.get(endpoint)
    return { status: true, certificate: response.data }
  } catch (error) {
    captureException({
      error,
      tag: 'GET_CERTIFICATE_EXCEPTION',
      endpoint,
    })

    return {
      status: false,
      message: translateMessage(error.message),
    }
  }
}
