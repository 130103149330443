import { decodeData } from '@/utils/Fernet'
/**
 * Function Get Data Token
 * @param {string} type
 * @param {string} brand
 */
export function getToken(type) {
  try {
    const decodeToken = decodeData(localStorage.getItem('st'))

    switch (type) {
      case 'token':
        return decodeToken.token
      case 'email':
        return decodeToken.email
      case 'cpf':
        return decodeToken.cpf
      case 'mobile':
        return decodeToken.mobile
      case 'packs':
        return decodeToken.packs

      default:
        return { status: true, profile: decodeToken }
    }
  } catch (error) {
    return false
  }
}
