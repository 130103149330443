import axios from '@/services/BaseService'
import { captureException } from '@/utils/Instrumentation/Exception'
import { translateMessage } from '@/utils/Translator'

export async function getSinisterByCPF(cpf) {
  const endpoint = `/v1/insurance_claim/user_document/${cpf}`
  try {
    const response = await axios.get(endpoint)

    return {
      status: true,
      sinisters: response,
    }
  } catch (error) {
    captureException({
      error,
      tag: 'SINISTER_GET_DATA_BY_CPF_EXCEPTION',
      endpoint,
      body: {
        cpf,
      },
    })

    return {
      status: false,
      message: translateMessage(error.message),
    }
  }
}

export async function sendSinister(body) {
  const endpoint = '/v1/insurance_claim/create'
  try {
    const response = await axios.post(endpoint, body)

    return {
      status: true,
      id: response.message.id,
    }
  } catch (error) {
    captureException({
      error,
      tag: 'SINISTER_SEND_DATA_EXCEPTION',
      endpoint,
      body,
    })
    return {
      status: false,
      message: translateMessage(error.message),
    }
  }
}

export async function sendResendDocuments(protocol, files) {
  const endpoint = '/v1/insurance_claim/resend_documents'
  try {
    await axios.post(endpoint, {
      protocol,
      files,
    })

    return {
      status: true,
    }
  } catch (error) {
    captureException({
      error,
      tag: 'SINISTER_SEND_REOPEN_EXCEPTION',
      endpoint,
      body: {
        protocol,
        files,
      },
    })
    return {
      status: false,
      message: translateMessage(error.message),
    }
  }
}
