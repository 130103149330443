import React, { useEffect, useState } from 'react'

import Grid from '@/components/Presentational/Grid'
import { useAuth } from '@/contexts/Authentication'
import { useLoader } from '@/contexts/Loader'
import { usePermissions } from '@/contexts/Permissions'
import { useSinisters } from '@/contexts/Sinisters'
import useEvents from '@/hooks/useEvents'
import useFormattedData from '@/hooks/useFormattedData'
import useToast from '@/hooks/useToast'
import { postNotification } from '@/services/APIService/Notification'
import { sendSinister } from '@/services/APIService/Sinisters'
import { updateActionUser } from '@/services/Firebase'

import Card from './atoms/Card'
import StepOne from './atoms/StepOne'
import StepTwo from './atoms/StepTwo'

const Panic = () => {
  const {
    user: {
      profile: {
        id,
        device_id,
        device_os,
        subscription: { premium },
        lastAction,
      },
    },
  } = useAuth()
  const { customEvent } = useEvents()
  const { toastrMessage } = useToast()
  const { permissions } = usePermissions()
  const { formattedData } = useFormattedData()
  const { setLoader } = useLoader()
  const { getSinisters, isDraft } = useSinisters()
  const [modal, setModal] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [items, setItems] = useState([])
  const [status, setStatus] = useState(null)

  const handleClickSendNotification = async (params) => {
    setLoader(true)

    const postNotificationResponse = await postNotification(
      device_id,
      params ? 'panicOn' : 'panicOff'
    )

    if (!postNotificationResponse.status) {
      setLoader(false)
      toastrMessage('error', postNotificationResponse.message, 'bottom-left')
      return
    }

    const updateActionUserResponse = await updateActionUser({
      userId: id,
      lastAction: params ? 'PANIC_BUTTON' : 'LOGGED_IN',
    })

    if (!updateActionUserResponse.status) {
      setLoader(false)
      toastrMessage('error', updateActionUserResponse.message, 'bottom-left')
      return
    }

    setIsOpen(params)

    if (params) {
      if (!isDraft) {
        const sendSinisterResponse = await sendSinister(
          formattedData({}, '', 'RASCUNHO')
        )

        if (sendSinisterResponse.status) {
          getSinisters()
        }
      }

      toastrMessage('success', 'Comando disparado', 'bottom-left')
    } else {
      toastrMessage(
        'success',
        'Modo de segurança desativado com sucesso',
        'bottom-left'
      )
    }
    setLoader(false)
  }

  const handleClickTakePicture = () => {
    if (!permissions.camera) {
      toastrMessage('error', 'Ative a proteção de Câmera no app do seu celular')
      return
    }

    setModal(false)
    customEvent('drawerTakePicture', {
      permissions: {
        camera: permissions.camera,
      },
    })
  }

  const handleClickDeletePhone = () => {
    setModal(false)
    customEvent('drawerDeletePhone')
  }

  const handleClickGoBack = () => {
    if (isDraft) {
      toastrMessage(
        'info',
        'Finalize o preenchimento da solicitação de reembolso',
        'bottom-left'
      )
    }
    toastrMessage(
      'info',
      'Acesse as fotos tiradas pela câmera antirroubo',
      'bottom-left'
    )
    setModal(false)
  }

  const getPermissions = () => {
    setStatus(permissions.panic)
    setItems([
      {
        title: 'Disparo de alarme',
        checked: permissions.alarm,
      },
      {
        title: 'Bloquear tela',
        checked: permissions.block,
      },
      {
        title: 'Rastrear localização',
        checked: permissions.location,
      },
      {
        title: 'Câmera antirroubo',
        checked: permissions.camera,
      },
      {
        title: 'Limpeza de dados',
        checked: permissions.clearData,
        chevronRight: true,
      },
    ])
  }

  useEffect(() => {
    if (permissions) {
      getPermissions()
    }
  }, [permissions])

  if (device_os === 'ios' || (device_os === 'android' && !premium)) {
    return null
  }

  return (
    <React.Fragment>
      <Card
        status={status}
        disabled={lastAction === 'CLEANED' || lastAction === 'LOGGED_OUT'}
        activeCard={lastAction === 'PANIC_BUTTON'}
        onClick={() => setModal(!modal)}
      />
      {modal && (
        <Grid>
          {!isOpen && lastAction !== 'PANIC_BUTTON' ? (
            <StepOne
              onClick={() => handleClickSendNotification(true)}
              onClickGoBack={() => setModal(false)}
            />
          ) : (
            <StepTwo
              items={items}
              onClick={() => handleClickSendNotification(false)}
              onClickGoBack={handleClickGoBack}
              onClickTakePicture={handleClickTakePicture}
              onClickDeletePhone={handleClickDeletePhone}
            />
          )}
        </Grid>
      )}
    </React.Fragment>
  )
}

export default Panic
