import { Strong } from '@/styles/fonts'

import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  left: 57%;
  top: 40%;
  padding: 15px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 15px 10px -15px #111;

  @media screen and (max-width: 768px) {
    margin-top: 130px;
    left: 13%;
    top: auto;
  }
`

export const Text = styled(Strong)`
  font-size: 12px;
  margin: 0px;
`
