import React from 'react'
import { Link } from 'react-router-dom'

import ARROW from '@/assets/img/arrow-back-page.svg'
import AuthenticationEmail from '@/components/Containers/Authentication/Email'
import Layout from '@/layout/External'

const Login = () => {
  return (
    <Layout>
      <Link to={'/'}>
        <img src={ARROW} alt='Voltar página' />
      </Link>
      <AuthenticationEmail />
    </Layout>
  )
}
export default Login
