import React from 'react'

import CardAction from '@/components/Presentational/Cards/Action'
import CardDisabled from '@/components/Presentational/Cards/Disabled'
import IconAlarm from '@/components/Presentational/Icons/IconAlarm'
import IconArrow from '@/components/Presentational/Icons/IconArrow'
import IconCheckIn from '@/components/Presentational/Icons/IconCheckIn'
import IconCheckOut from '@/components/Presentational/Icons/IconCheckOut'
import IconClock from '@/components/Presentational/Icons/IconClock'
import IconLoader from '@/components/Presentational/Icons/IconLoader'
import Toggle from '@/components/Presentational/Inputs/Toggle'
import colors from '@/styles/colors'

const Card = ({ status, checked, disabled, onChange }) => {
  if (disabled) {
    return (
      <CardDisabled
        dataTestId='card-disabled-alarm'
        type={'reverse'}
        title={'Disparo de alarme'}
        iconLeft={<IconAlarm color={colors.disabled2} />}
        iconRight={<IconClock />}
        button={<IconArrow color={colors.disabled2} />}
      />
    )
  }

  return (
    <CardAction
      dataTestId='card-alarm'
      type={'reverse'}
      disabled={disabled}
      status={status}
      title={'Disparo de alarme'}
      iconLeft={<IconAlarm />}
      iconRight={
        status === null ? (
          <IconLoader />
        ) : status ? (
          <IconCheckIn />
        ) : (
          <IconCheckOut />
        )
      }
      button={
        <Toggle dataTestId='toogle' checked={checked} onChange={onChange} />
      }
    />
  )
}

export default Card
