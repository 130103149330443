import axios from '@/services/BaseService'
import { captureException } from '@/utils/Instrumentation/Exception'
import { translateMessage } from '@/utils/Translator'

export async function sendPincode(email) {
  const endpoint = '/v1/auth/otp/send'
  const body = {
    email: email,
  }

  try {
    await axios.post(endpoint, body)

    return {
      status: true,
    }
  } catch (error) {
    captureException({
      error,
      tag: 'AUTH_SEND_PINCODE_EXCEPTION',
      endpoint,
      body,
    })
    return {
      status: false,
      message: translateMessage(error.message),
    }
  }
}

export async function validatePincode(email, code) {
  const endpoint = '/v1/auth/otp/validate'
  const body = {
    email: email,
    otpCode: code,
  }

  try {
    const response = await axios.post(endpoint, body)

    return {
      status: true,
      token: response.token || '',
      message: translateMessage(response.message),
    }
  } catch (error) {
    captureException({
      error,
      tag: 'AUTH_VALIDATE_PINCODE_EXCEPTION',
      endpoint,
      body,
    })

    return {
      status: false,
      message: translateMessage(error.message),
    }
  }
}

export async function getWebToken(email) {
  const endpoint = `/v1/generateWebToken/${email}`

  try {
    const response = await axios.get(endpoint)

    return {
      status: true,
      auth: response.headers.authorization,
    }
  } catch (error) {
    captureException({
      error,
      tag: 'AUTH_GET_WEB_TOKEN_EXCEPTION',
      endpoint,
      body: {
        email,
      },
    })

    return {
      status: false,
      message: translateMessage(error.message),
    }
  }
}
