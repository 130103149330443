import React from 'react'

const IconCamera = ({ onClick, color = '#004BC1' }) => {
  return (
    <svg
      width='52'
      height='43'
      viewBox='0 0 52 43'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <path
        d='M34 41H44C47.3137 41 50 38.3122 50 34.9985C50 27.3392 50 20.6608 50 13.0015C50 9.68777 47.3137 7 44 7H8C4.68629 7 2 9.68629 2 13V35C2 38.3137 4.68629 41 8 41H18'
        stroke={color}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <circle cx='26' cy='24' r='9.5' stroke={color} strokeWidth='3' />
      <path d='M6 2H20' stroke={color} strokeWidth='3' strokeLinecap='round' />
    </svg>
  )
}

export default IconCamera
