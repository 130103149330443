import React from 'react'

const IconChevronDown = ({
  onClick,
  width = 24,
  height = 24,
  color = '#121212',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      cursor='pointer'
      data-testid='icon-chevron-down'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.26789 8.23342C5.61017 7.90822 6.14866 7.92486 6.47062 8.27058L12.0032 14.2114L17.5291 8.27227C17.8509 7.9264 18.3894 7.90951 18.7318 8.23456C19.0743 8.5596 19.091 9.10349 18.7692 9.44936L13.1339 15.5061C12.5213 16.1644 11.4859 16.1647 10.8731 15.5066L5.2311 9.44825C4.90913 9.10252 4.9256 8.55862 5.26789 8.23342Z'
        fill={color}
      />
    </svg>
  )
}

export default IconChevronDown
