import React from 'react'

import Items from '../Items'
import * as S from './styled'

const Analysis = ({ title, protocol, status }) => {
  return (
    <S.Container data-testid='item-analysis'>
      <S.Content>
        <Items title={title} status={status} />
        <S.Items>
          <h3>{protocol}</h3>
          <p>Protocolo</p>
        </S.Items>
      </S.Content>
    </S.Container>
  )
}

export default Analysis
