import styled from 'styled-components'

export const Information = styled.div`
  margin-top: 30px;
  margin-bottom: 100px;
  width: 370px;

  > h3 {
    font-size: 18px;
  }

  > small {
    font-size: 10px;
  }

  @media screen and (max-width: 739px) {
    width: 100%;
  }
`
