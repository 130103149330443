import React from 'react'

const LocationIcon = ({ color = '#004BC1' }) => {
  return (
    <svg
      width='44'
      height='52'
      viewBox='0 0 44 52'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M27.6569 27.6569C24.5327 30.781 19.4673 30.781 16.3431 27.6569C13.219 24.5327 13.219 19.4673 16.3431 16.3431C19.4673 13.219 24.5327 13.219 27.6569 16.3431C30.781 19.4673 30.781 24.5327 27.6569 27.6569Z'
        stroke={color}
        strokeWidth='3'
        strokeLinejoin='round'
      />
      <path
        d='M22 50L7.85786 35.9411C0.0473787 28.1766 0.0473787 15.5879 7.85786 7.82338C15.6684 0.0588743 28.3317 0.0588743 36.1421 7.82338C43.9526 15.5879 43.9526 28.1766 36.1421 35.9411L29.0711 42.9706'
        stroke={color}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default LocationIcon
