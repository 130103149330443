import colors from '@/styles/colors'

import styled from 'styled-components'

export const Input = styled.input`
  outline: none;
  padding: 0.75rem;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin: 0;

  ::placeholder {
    color: #b7b8bf;
  }

  :disabled {
    color: ${colors.gray};
  }
`
