import moment from 'moment'

export const createDate = (timestamp) => {
  if (!timestamp) {
    return '--/--/--'
  }

  let date = moment.utc(timestamp * 1000).format('DD/MM/YYYY')

  if (date === 'Invalid date') {
    return '--/--/--'
  }

  return date
}
