import React from 'react'

import { useForm } from '@/components/Containers/Claims/contexts/Forms'
import Collapse from '@/components/Presentational/Collapsible/Collapse'
import { useCollapse } from '@/components/Presentational/Collapsible/contexts/Collapse'
import Item from '@/components/Presentational/Collapsible/Item'
import IconAttachment from '@/components/Presentational/Icons/IconAttachment'
import IconCircleClose from '@/components/Presentational/Icons/IconCircleClose'
import IconFillAdd from '@/components/Presentational/Icons/IconFillAdd'
import InputFile from '@/components/Presentational/Inputs/File'
import { useAuth } from '@/contexts/Authentication'

import { UPLOAD_FIELS } from '../../constants'
import * as S from './styled'

const validateForms = (
  cpfFiles,
  residencyProof,
  policeReport,
  cnhFiles,
  invoiceFiles,
  iCloudScreen
) => {
  const validate = [
    cpfFiles,
    residencyProof,
    policeReport,
    cnhFiles,
    invoiceFiles,
    iCloudScreen,
  ].filter((item) => item !== '').length

  return validate === 0
    ? 'PENDING'
    : validate === 5 || validate === 6
      ? 'SUCCESS'
      : 'ANALYSIS'
}

const DocumentsItem = () => {
  const {
    formData: {
      cpfFiles,
      residencyProof,
      policeReport,
      cnhFiles,
      invoiceFiles,
      iCloudScreen,
    },
    spinner,
  } = useForm()
  const { collapse, setCollapse } = useCollapse()

  return (
    <Item
      spinner={spinner}
      status={validateForms(
        cpfFiles,
        residencyProof,
        policeReport,
        cnhFiles,
        invoiceFiles,
        iCloudScreen
      )}
      title='Documentos'
      dataTestId='button-documents'
      onClick={() =>
        setCollapse((prev) => ({
          ...prev,
          documents: !collapse.documents,
        }))
      }
    />
  )
}

const DocumentsForm = () => {
  const {
    user: {
      profile: { device_os },
    },
  } = useAuth()
  const { collapse, setCollapse } = useCollapse()
  const {
    formData: {
      cpfFiles,
      residencyProof,
      policeReport,
      cnhFiles,
      invoiceFiles,
      iCloudScreen,
    },
    formData,
    updateFormData,
    spinner,
  } = useForm()

  return (
    <Collapse
      spinner={spinner}
      status={validateForms(
        cpfFiles,
        residencyProof,
        policeReport,
        cnhFiles,
        invoiceFiles,
        iCloudScreen
      )}
      isOpen={collapse.documents}
      title='Documentos'
      subTitle='Os documentos do segurado devem estar nos seguintes formatos: JPG, PNG ou PDF. Atenção: O tamanho do arquivo não pode exceder 5 MB.'
      onClick={() =>
        setCollapse((prev) => ({
          ...prev,
          documents: !collapse.documents,
        }))
      }
    >
      <S.Form>
        {UPLOAD_FIELS.map((item, key) => {
          if (item.field === 'iCloudScreen' && device_os === 'android') {
            return null
          }

          return (
            <S.Container key={key}>
              <React.Fragment>
                <S.Title>
                  <span>{item.label}</span>
                  {item.title && <p>{item.title}</p>}
                </S.Title>
                <InputFile
                  id={item.field}
                  name={item.field}
                  dataTestId={item.field}
                  title='Anexar'
                  iconRight={<IconFillAdd />}
                  onChange={(e) =>
                    updateFormData({ [e.target.name]: e.target.files[0] })
                  }
                />
              </React.Fragment>
              {formData[item.field].name && (
                <S.SelectFile data-testid='select-file'>
                  <S.Label>
                    <div>
                      <IconAttachment />
                      <span>{formData[item.field].name}</span>
                    </div>
                  </S.Label>
                  <IconCircleClose
                    onClick={() => updateFormData({ [item.field]: '' })}
                  />
                </S.SelectFile>
              )}
            </S.Container>
          )
        })}
      </S.Form>
    </Collapse>
  )
}

export { DocumentsItem, DocumentsForm }
