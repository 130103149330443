import React from 'react'

import IconChevronDown from '@/components/Presentational/Icons/IconChevronDown'
import IconChevronUp from '@/components/Presentational/Icons/IconChevronUp'

import * as S from './styled'

const Collapse = ({
  title,
  status,
  spinner,
  subTitle,
  children,
  isOpen,
  onClick,
}) => {
  return (
    <S.Form>
      <S.Header>
        <S.Title>{title}</S.Title>
        {spinner ? (
          ''
        ) : isOpen ? (
          <IconChevronUp onClick={onClick} />
        ) : (
          <IconChevronDown onClick={onClick} />
        )}
      </S.Header>
      <S.Status>
        {status === 'PENDING' ? (
          <S.Label status='pending' data-testid='status-pending'>
            Pendente
          </S.Label>
        ) : status === 'ANALYSIS' ? (
          <S.Label status='analysis' data-testid='status-analysis'>
            Em andamento
          </S.Label>
        ) : (
          <S.Label status='success' data-testid='status-success'>
            Completo
          </S.Label>
        )}
      </S.Status>
      <S.Header>
        <S.SubTitle>{subTitle}</S.SubTitle>
      </S.Header>

      {isOpen && <React.Fragment>{children}</React.Fragment>}
      <hr />
    </S.Form>
  )
}

export default Collapse
