import React from 'react'

import CardAction from '@/components/Presentational/Cards/Action'
import CardDisabled from '@/components/Presentational/Cards/Disabled'
import IconArrow from '@/components/Presentational/Icons/IconArrow'
import IconCheckIn from '@/components/Presentational/Icons/IconCheckIn'
import IconCheckOut from '@/components/Presentational/Icons/IconCheckOut'
import IconClearData from '@/components/Presentational/Icons/IconClearData'
import IconClock from '@/components/Presentational/Icons/IconClock'
import IconInformation from '@/components/Presentational/Icons/IconInformation'
import IconLoader from '@/components/Presentational/Icons/IconLoader'
import colors from '@/styles/colors'

const Card = ({ status, deviceOS, disabled, onClick }) => {
  if (disabled) {
    return (
      <CardDisabled
        dataTestId='card-disabled-clear-data'
        type={deviceOS === 'ios' ? 'no-full-width' : 'no-reverse'}
        title={'Limpeza de dados'}
        iconLeft={<IconClearData color={colors.disabled2} />}
        iconRight={<IconClock />}
        button={<IconArrow color={colors.disabled2} />}
      />
    )
  }

  return (
    <CardAction
      dataTestId='card-clear-data'
      type={deviceOS === 'ios' ? 'no-full-width' : 'no-reverse'}
      disabled={disabled}
      status={status}
      onClick={onClick}
      title={'Limpeza de dados'}
      iconLeft={<IconClearData />}
      iconRight={
        deviceOS === 'ios' ? (
          <IconInformation />
        ) : status === null ? (
          <IconLoader />
        ) : status ? (
          <IconCheckIn />
        ) : (
          <IconCheckOut />
        )
      }
      button={<IconArrow />}
    />
  )
}

export default Card
