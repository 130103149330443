import React, { useState } from 'react'

import IconChevronDown from '@/components/Presentational/Icons/IconChevronDown'
import IconChevronRight from '@/components/Presentational/Icons/IconChevronRight'

import Details from '../Details'
import * as S from './styled'

const AllAnswers = ({ faq }) => {
  const [active, setActive] = useState(0)

  const getDescription = (type) => {
    const description = {
      signature:
        'Saiba sobre a ativação do seu serviço e os benefícios da assinatura do app Proteção Pix',
      funcionalities:
        'Saiba mais sobre todas as funcionalidades disponívels no app Proteção Pix',
      refund: 'Saiba sobre o processo de indenização e reembolso.',
      general:
        'Saiba sobre as principais funcionalidades e processos para ativar e gerenciar suas proteções.',
    }

    return description[type]
  }

  const scrollToSection = (key) => {
    setActive(Number(key))
    window.scrollTo({
      top: document.getElementById(`list-${key}`).offsetTop,
      behavior: 'smooth',
    })
  }

  let categories = faq.reduce((result, obj) => {
    result[obj.category.name] = result[obj.category.name] || {
      description: getDescription(obj.category.id),
      results: [],
    }
    result[obj.category.name].results.push(obj)
    return result
  }, {})

  return (
    <S.Container>
      <S.Sidebar>
        {Object.keys(categories).map((item, index) => (
          <S.SidebarItem
            key={index}
            onClick={() => scrollToSection(index)}
            active={active === index}
          >
            Sobre {item.toLowerCase()}
            {active === index ? (
              <IconChevronRight />
            ) : (
              <IconChevronDown width={16} height={16} />
            )}
          </S.SidebarItem>
        ))}
      </S.Sidebar>
      <S.Content>
        {Object.entries(categories).map(([category, item], key) => (
          <S.Item key={key} id={`list-${key}`}>
            <div>
              <h3>Sobre {category.toLowerCase()}</h3>
              <small>{item.description}</small>
            </div>
            {item.results.map((item, key) => (
              <Details
                key={key}
                answer={item.answer}
                question={item.question}
              />
            ))}
          </S.Item>
        ))}
      </S.Content>
    </S.Container>
  )
}

export default AllAnswers
