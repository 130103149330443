import IMAGE1 from '@/assets/img/image-card-1.png'
import IMAGE2 from '@/assets/img/image-card-2.png'
import IMAGE3 from '@/assets/img/image-card-3.png'

export const CARDS = [
  {
    title: 'Sobre assinatura',
    description:
      'Saiba sobre a ativação do seu serviço e os benefícios da assinatura',
    image: IMAGE1,
    category: {
      name: 'Assinatura',
      id: 'signature',
    },
  },
  {
    title: 'Sobre reembolso',
    description: 'Saiba sobre o processo de indenização e reembolso',
    image: IMAGE2,
    category: {
      name: 'Reembolso',
      id: 'refund',
    },
  },
  {
    title: 'Sobre acesso remoto',
    description:
      'Saiba como acessar remotamente seu celular perdido ou roubado',
    image: IMAGE3,
    category: {
      name: 'Funcionalidades',
      id: '',
    },
  },
]

export const CATEGORY_DEFAULT = 'Informações Gerais'
