import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }

  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
`

export const Chatbot = styled.div``

export const ChatbotImage = styled.img`
  display: block;
  position: fixed;
  bottom: ${({ bottomSize }) => (bottomSize ? '80px;' : '10px;')};
  margin: 10px;
  z-index: 8;
  right: 0px;
  cursor: pointer;
  border-radius: 100px;
  width: 65px;
  animation: ${rotate} 2s linear infinite;
`

export const ChatbotContainer = styled.div`
  height: auto;
  width: 400px;
  position: fixed;
  bottom: 10px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  right: 10px;
  z-index: 999999999;
  overflow: hidden;
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.5);
  border-radius: 10px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    right: 0;
    bottom: 0;
    border-radius: 0;
  }
`

export const ChatbotClose = styled.span`
  text-align: right;
  padding: 0.6rem 1rem 0 1rem;
  font-size: 0.6rem;
  text-transform: uppercase;
  cursor: pointer;
`

export const Iframe = styled.iframe`
  width: 100%;
  max-height: 655px;
  height: 90vh;

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    max-height: 100%;
  }
`
