import React from 'react'

import AuthenticationEmailActions from '@/components/Containers/Authentication/EmailActions'
import Layout from '@/layout/External'

import * as S from './styled'

const EmailActions = () => {
  return (
    <Layout>
      <S.Main>
        <S.Container>
          <AuthenticationEmailActions />
        </S.Container>
      </S.Main>
    </Layout>
  )
}

export default EmailActions
