import styled from 'styled-components'

export const GoBack = styled.div`
  color: var(--color-font-grey-clear);
  display: flex;
  font-size: 16px;
  grid-gap: 10px;
  gap: 10px;
  line-height: 20px;
  cursor: pointer;
`

export const Button = styled.div`
  align-items: center;
  display: flex;
  grid-gap: 10px;
  gap: 10px;

  > p {
    margin: 0px;
  }
`
