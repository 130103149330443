import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { AuthProvider } from '@/contexts/Authentication'
import { LoaderProvider } from '@/contexts/Loader'
import PrivateRoute from '@/contexts/PrivateRoute'
import Certificate from '@/pages/Certificate'
import Claims from '@/pages/Claims'
import Dashboard from '@/pages/Dashboard'
import DeleteAccount from '@/pages/DeleteAccount'
import EmailActions from '@/pages/EmailActions'
import Faq from '@/pages/Faq'
import Help from '@/pages/Help'
import Home from '@/pages/Home'
import Login from '@/pages/Login'
import NotFound from '@/pages/NotFound'
import Regulamento from '@/pages/RegulamentoTIM'

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <LoaderProvider>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/login' element={<Login />} />
            <Route path='/faq' element={<Faq />} />
            <Route path='/validate' element={<Login />} />
            <Route path='/acao/email' element={<EmailActions />} />
            <Route path='/certificado' element={<Certificate />} />
            <Route path='/regulamento-tim' element={<Regulamento />} />
            <Route path='/ajuda' element={<Help />} />
            <Route path='/help' element={<Help />} />
            <Route path='/success-delete-account' element={<DeleteAccount />} />
            <Route element={<PrivateRoute />}>
              <Route path='/dashboard' element={<Dashboard />} />
              <Route path='/claims/:id?' element={<Claims />} />
            </Route>
            <Route path='/page-not-found' element={<NotFound />} />
            <Route exact path='*' element={<Home />} />
          </Routes>
        </LoaderProvider>
      </AuthProvider>
    </BrowserRouter>
  )
}

export default AppRoutes
