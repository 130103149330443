import React from 'react'

import REGULAMENTO from '../../assets/Regulamento_Prot_PIX_AGO.24.pdf'

const RegulamentoTIM = () => {
  return (
    <React.Fragment>
      <object
        data={REGULAMENTO}
        style={{ width: '100%', height: '100vh' }}
        type='application/pdf'
      >
        <p>Seu navegador não tem um plugin pra PDF</p>
      </object>
    </React.Fragment>
  )
}

export default RegulamentoTIM
