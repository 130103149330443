import React, { createContext, useContext, useEffect, useState } from 'react'

import { useAuth } from '@/contexts/Authentication'
import useToast from '@/hooks/useToast'
import { getSinisterByCPF } from '@/services/APIService/Sinisters'
import { moneyMask } from '@/utils/Validator/Money'
import moment from 'moment'

export const SinistersContext = createContext({})

export const SinistersProvider = ({ children }) => {
  const {
    user: {
      profile: { document },
    },
  } = useAuth()
  const { toastrMessage } = useToast()
  const [loader, setLoader] = useState(true)
  const [isDraft, setIsDraft] = useState(false)
  const [sinisters, setSinisters] = useState([])
  const [draftSinisters, setDraftSinisters] = useState([])

  const parseStatus = (item) => {
    const status = {
      SUCCESS: ['PAGO', 'Indenizado'],
      ANALYSIS: [
        'EM ANÁLISE',
        'Em Análise',
        'Em Analise',
        'em análise',
        'em analise',
      ],
      PENDING: ['PENDENTE', 'Pendente', 'pendente'],
      REFUSED: ['NEGADO', 'Negado', 'negado', 'REPROVADO', 'RECUSADO'],
    }

    return Object.keys(status).find((key) =>
      status[key].find((obj) => obj === item)
    )
  }

  const getSinisters = async () => {
    const response = await getSinisterByCPF(document, '')

    if (!response.status) {
      toastrMessage('error', response.message)
      setLoader(false)
      return
    }

    const sinistersFiltered = response.sinisters
      .filter((item) => item.status !== 'RASCUNHO')
      .map((row) => {
        return {
          id: row.id,
          title: row.open_date
            ? `Aberto em ${moment(row.open_date).format('DD/MM/YYYY')}`
            : '',
          createdAt: moment(row.open_date).format('DD/MM/YYYY'),
          openDate: row.open_date,
          protocol: row.protocol,
          imei: row.imei,
          status: parseStatus(row.status),
          reason: row.reason,
          notes: row.notes ? row.notes.split(', ') : [],
          paymentValue: row.payment_value
            ? moneyMask(row.payment_value)
            : 'R$ 0,00',
          paymentDate: row.payment_date
            ? moment(row.payment_date).format('DD/MM/YYYY')
            : '00/00/0000',
        }
      })

    sinistersFiltered.sort(function (a, b) {
      return new Date(b.openDate) - new Date(a.openDate)
    })

    const draft = response.sinisters.filter(
      (filter) => filter.status === 'RASCUNHO'
    )

    setIsDraft(draft.length > 0)
    setDraftSinisters(draft)
    setSinisters(sinistersFiltered)
    setLoader(false)
  }

  useEffect(() => {
    getSinisters()
  }, [])

  return (
    <SinistersContext.Provider
      value={{
        loader,
        setLoader,
        isDraft,
        sinisters,
        draftSinisters,
        getSinisters,
      }}
    >
      {children}
    </SinistersContext.Provider>
  )
}

export const useSinisters = () => useContext(SinistersContext)
