import React from 'react'

import * as S from './styled'

const ShimmerEffect = () => {
  return (
    <S.Container>
      <S.Shimmer />
      <hr />
      <S.Shimmer />
      <hr />
      <S.Shimmer />
      <hr />
    </S.Container>
  )
}

export default ShimmerEffect
