import React from 'react'

import * as S from './styled'

const Grid = ({ children }) => {
  return (
    <S.Container>
      <S.Background />
      <S.Content>{children}</S.Content>
    </S.Container>
  )
}

export default Grid
