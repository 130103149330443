import colors from '@/styles/colors'
import { Paragraph, Small } from '@/styles/fonts'

import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 20px;
`

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;

  p {
    font-size: 14px;
  }
`

export const Text = styled(Paragraph)`
  color: #121212;
  font-size: 16px;
`

export const SubTitle = styled(Small)`
  color: #383838;
  font-size: 14px;
`

export const LabelInformation = styled.label`
  padding: 0px 10px;
  background-color: #e3e3e3;
  border-radius: 20px;
  color: #121212;
  font-size: 12px;
  margin-bottom: 0px;
`

export const LabelSuccess = styled.label`
  padding: 0px 10px;
  background-color: ${colors.green};
  border-radius: 20px;
  color: #121212;
  width: 72px;
  font-size: 12px;
  margin-bottom: 0px;
`

export const LabelAlert = styled.label`
  padding: 0px 10px;
  background-color: #ffd43b;
  border-radius: 20px;
  color: #121212;
  width: 102px;
  font-size: 12px;
  margin-bottom: 0px;
`

export const Label = styled.label`
  padding: 5px 10px;
  background-color: #e3e3e3;
  border-radius: 20px;
  color: #121212;
`

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const Item = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  list-style-type: none;
  gap: 15px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
  border: 1px solid hsla(0, 0%, 90%, 1);
  border-radius: 24px;
  box-shadow: 0px 8px 16px 0px #c4c4c429;
`

export const Description = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`

export const Information = styled(Paragraph)`
  padding-top: 30px;
  color: #666666;
  font-size: 12px;
`
