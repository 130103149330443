import React from 'react'
import { PatternFormat } from 'react-number-format'

import { InputContainer } from './styles'

const InputCEP = ({
  type = 'text',
  placeholder = '00000-000',
  name,
  value,
  required = false,
  onChange,
}) => {
  return (
    <PatternFormat
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      format={'#####-###'}
      mask={'_'}
      placeholder={placeholder}
      required={required}
      customInput={InputContainer}
    />
  )
}

export default InputCEP
