import React from 'react'

import { useAuth } from '@/contexts/Authentication'

import EmptyData from './atoms/EmptyData'
import ListData from './atoms/ListData'

const TrustContacts = () => {
  const {
    user: {
      profile: { trust_contacts },
    },
  } = useAuth()

  return (
    <React.Fragment>
      <div className='title-section'>
        <div className='group'>
          <p>Contatos de confiança</p>
        </div>
      </div>
      {trust_contacts.length === 0 ? (
        <EmptyData />
      ) : (
        <ListData items={trust_contacts} />
      )}
    </React.Fragment>
  )
}
export default TrustContacts
