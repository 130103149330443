import colors from '@/styles/colors'

import styled from 'styled-components'

export const ButtonContainer = styled.button`
  border: 1px solid ${colors.text};
  color: ${colors.white};
  text-align: center;
  cursor: pointer;
  background-color: transparent;
  border-radius: 16px;
  padding: 12px 20px;
  font-size: 16px;
  background-color: ${colors.primary};

  :disabled {
    background-color: ${colors.disabled};
    border: 1px solid ${colors.disabled};
    color: ${colors.white};
  }

  ${(props) =>
    props.active &&
    `
    background-color: ${colors.primary};
    border: 1px solid ${colors.primary};
    color: ${colors.white};

  `}
`
