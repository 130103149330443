import React, { useEffect } from 'react'

import { Container } from './styled'

const Help = () => {
  useEffect(() => {
    const robots = document.createElement('meta')
    robots.setAttribute('name', 'robots')
    robots.setAttribute('content', 'noindex')

    document.head.insertAdjacentElement('beforeend', robots)

    const description = document.createElement('meta')
    description.setAttribute('name', 'description')
    description.setAttribute(
      'content',
      'Acesse o nosso chatbot para obter toda ajuda necessária.'
    )

    document.head.insertAdjacentElement('beforeend', description)

    document.getElementsByTagName('noscript')[0].remove()
  }, [])

  return (
    <Container>
      <iframe
        id='iframe'
        src='https://fs-chatbot-orquestrador-homol.web.app?p=protecao-pix'
        title='Chatbot'
        frameBorder='0'
        width='100%'
        height='100%'
      ></iframe>
    </Container>
  )
}
export default Help
