import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useForm } from '@/components/Containers/Claims/contexts/Forms'
import ButtonPrimary from '@/components/Presentational/Buttons/Primary'
import IconFileMoney from '@/components/Presentational/Icons/IconFileMoney'
import IconGoBack from '@/components/Presentational/Icons/IconGoBack'

import AutoSave from './atoms/AutoSave'
import { BankDataForm, BankDataItem } from './atoms/BankData'
import Banner from './atoms/Banner'
import { ContactsForm, ContactsItem } from './atoms/Contacts'
import { DocumentsForm, DocumentsItem } from './atoms/Documents'
import Information from './atoms/Information'
import {
  OccurrenceSummaryForm,
  OccurrenceSummaryItem,
} from './atoms/OccurrenceSummary'
import { TermsOfUseForm, TermsOfUseItem } from './atoms/TermsOfUse'
import * as S from './styled'

const Claims = () => {
  const navigate = useNavigate()
  const { formIsCompleted, handleSubmitForm } = useForm()

  return (
    <React.Fragment>
      <S.Header>
        <S.ButtonGoBack
          data-testid='button-go-back'
          onClick={() => navigate('/dashboard')}
        >
          <IconGoBack /> Voltar
        </S.ButtonGoBack>
        <S.HeaderContent>
          <S.Title>
            <IconFileMoney />
            <p>Nova solicitação</p>
          </S.Title>
          <AutoSave />
        </S.HeaderContent>
        <Banner />
      </S.Header>

      <S.Container>
        <S.ContentAction>
          <OccurrenceSummaryItem />
          <DocumentsItem />
          <BankDataItem />
          <ContactsItem />
          <TermsOfUseItem />
        </S.ContentAction>
        <S.ContentForm>
          <OccurrenceSummaryForm />
          <DocumentsForm />
          <BankDataForm />
          <ContactsForm />
          <TermsOfUseForm />
        </S.ContentForm>
      </S.Container>

      <Information />

      <S.Actions>
        <ButtonPrimary
          type='button'
          name='Fechar'
          onClick={() => navigate('/dashboard')}
        />
        <ButtonPrimary
          type='submit'
          name='Finalizar e enviar'
          disabled={formIsCompleted}
          onClick={() => handleSubmitForm()}
        />
      </S.Actions>
    </React.Fragment>
  )
}

export default Claims
