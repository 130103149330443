import React from 'react'

import { URL_ICLOUD_APPLE } from '@/constants'

const DataClearHelpIOS = () => {
  return (
    <div className='content-flow'>
      <section className='infos_help_ios'>
        <p>
          Todos os dispositivos da Apple contam com a limpeza de dados já
          inclusas no aparelho.
        </p>

        <p style={{ fontSize: '16px', marginTop: '20px' }}>
          <strong>
            Siga o passo o passo a passo para apagar o dispositivo remotamente:
          </strong>
        </p>

        <ul>
          <li>1. Acesse o iCloud e selecione a aba “Dispositivos”.</li>
          <li>
            2. Selecione o dispositivo que você deseja apagar remotamente.
          </li>
          <li>
            3. Role a tela para baixo e escolha a opção “Apagar Dispositivo”.
          </li>
          <li>4. Selecione “Apagar”.</li>
        </ul>

        <div className='warning-message'>
          <svg
            width='18'
            height='16'
            viewBox='0 0 18 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M4.16932 16H13.8308C15.6424 16 16.5481 16 17.0718 15.618C17.5285 15.2849 17.8232 14.7727 17.8822 14.209C17.9499 13.5627 17.4971 12.7752 16.5912 11.2001L11.7605 2.8001C10.8547 1.22492 10.4015 0.437221 9.81019 0.172908C9.29441 -0.0576362 8.70521 -0.0576361 8.18942 0.172908C7.59835 0.437106 7.14569 1.22423 6.24061 2.79803L1.40871 11.2001C0.502843 12.7752 0.0500392 13.5627 0.117699 14.209C0.176716 14.7727 0.471414 15.2849 0.92818 15.618C1.45184 16 2.35765 16 4.16932 16ZM8.99997 3.55556C9.49089 3.55556 9.88886 3.95352 9.88886 4.44444V9.77778C9.88886 10.2687 9.49089 10.6667 8.99997 10.6667C8.50905 10.6667 8.11108 10.2687 8.11108 9.77778V4.44444C8.11108 3.95352 8.50905 3.55556 8.99997 3.55556ZM9.88886 12.4444C9.88886 12.9354 9.49089 13.3333 8.99997 13.3333C8.50905 13.3333 8.11108 12.9354 8.11108 12.4444C8.11108 11.9535 8.50905 11.5556 8.99997 11.5556C9.49089 11.5556 9.88886 11.9535 9.88886 12.4444Z'
              fill='#666666'
            />
          </svg>

          <p>
            Essa ação apagará todos os dados do seu celular e não poderá ser
            desfeita.
          </p>
        </div>

        <a
          href={URL_ICLOUD_APPLE}
          target='_blank'
          rel='noreferrer'
          style={{
            backgroundColor: 'hsla(217, 100%, 38%, 1)',
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
            color: '#ffffff',
            padding: '10px 20px',
            marginTop: 20,
            borderRadius: 20,
          }}
        >
          Acessar iCloud
          <svg
            width='31'
            height='31'
            viewBox='0 0 31 31'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M19.5291 23.3603C19.0338 23.2276 18.7399 22.7185 18.8726 22.2232L21.0111 14.2422L8.47942 21.4774C8.03536 21.7338 7.46754 21.5816 7.21116 21.1375C6.95478 20.6935 7.10692 20.1257 7.55099 19.8693L20.0827 12.6341L12.1016 10.4956C11.6064 10.3629 11.3124 9.85378 11.4451 9.35849C11.5779 8.8632 12.087 8.56927 12.5822 8.70198L22.7284 11.4206C23.2236 11.5533 23.5176 12.0624 23.3849 12.5577L20.6662 22.7038C20.5335 23.1991 20.0244 23.4931 19.5291 23.3603Z'
              fill='#FDFDFD'
            />
          </svg>
        </a>
      </section>
    </div>
  )
}
export default DataClearHelpIOS
