import React from 'react'

import IconClose from '@/components/Presentational/Icons/IconClose'
import moment from 'moment'

import * as S from './styled'

const DeviceInfo = ({ battery, address, date, time, onClose }) => {
  return (
    <S.Container data-testid='device-info'>
      <S.Content>
        <S.Icon>
          <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <rect
              width='6'
              height='7'
              transform='translate(5 8)'
              fill='#757575'
            />
            <rect
              x='4.5'
              y='3.16699'
              width='7'
              height='12.3333'
              rx='1.5'
              stroke='#CDCDCD'
            />
            <path
              d='M9.71373 1.33333H6.28516C6.28516 1.33333 6.85658 0 7.99944 0C9.1423 0 9.71373 1.33333 9.71373 1.33333Z'
              fill='#CDCDCD'
            />
          </svg>

          <S.Paragraph>{battery ? battery + '%' : '--'}</S.Paragraph>
        </S.Icon>
        <IconClose color='#ffffff' width='15' height='15' onClick={onClose} />
      </S.Content>
      <S.Divider />
      <S.Paragraph>{address}</S.Paragraph>
      <S.Paragraph>
        Atualizado às {moment(time).format('HH:ss')} em{' '}
        {moment(date).format('DD/MM/YYYY')}
      </S.Paragraph>
    </S.Container>
  )
}

export default DeviceInfo
