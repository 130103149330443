import React from 'react'

const IconWarning = () => {
  return (
    <svg
      width='31'
      height='28'
      viewBox='0 0 31 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.5823 27.3332H7.41656H7.41637C4.38507 27.3332 2.8696 27.3332 1.99341 26.6966C1.22913 26.1413 0.736037 25.2877 0.63729 24.3482C0.524079 23.2711 1.28172 21.9586 2.79744 19.3333L10.8803 5.33333L10.8823 5.3299L10.8823 5.32988C12.3967 2.70688 13.1541 1.39501 14.1431 0.954685C15.0061 0.570444 15.992 0.570444 16.855 0.954685C17.8444 1.39521 18.6026 2.70804 20.1183 5.33333L28.2012 19.3333C29.7169 21.9586 30.4746 23.2711 30.3614 24.3482C30.2626 25.2877 29.7695 26.1413 29.0052 26.6966C28.129 27.3332 26.6138 27.3332 23.5825 27.3332H23.5823ZM16.5819 8.9999C16.5819 8.44761 16.1341 7.9999 15.5819 7.9999C15.0296 7.9999 14.5819 8.44761 14.5819 8.9999V15.6665C14.5819 16.2188 15.0296 16.6665 15.5819 16.6665C16.1341 16.6665 16.5819 16.2188 16.5819 15.6665V8.9999ZM15.4989 19.6665C14.9466 19.6665 14.4989 20.1142 14.4989 20.6665V20.8331C14.4989 21.3853 14.9464 21.833 15.4986 21.8331L15.6646 21.8332C15.9299 21.8333 16.1843 21.7279 16.3719 21.5404C16.5595 21.3528 16.6649 21.0984 16.6649 20.8332V20.6665C16.6649 20.1142 16.2172 19.6665 15.6649 19.6665H15.4989Z'
        fill='#CF7100'
      />
    </svg>
  )
}

export default IconWarning
