import React, { useEffect, useState } from 'react'

import { SelectSearch } from '@/components/Presentational/Inputs'
import { getCity, getState } from '@/services/APIService/Utilities'

const SelectLocation = ({
  stateName,
  stateValue,
  cityName,
  cityValue,
  required,
  onChange,
}) => {
  const [loader, setLoader] = useState(true)
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])

  const listState = async () => {
    const getStateResponse = await getState('')
    const data = []

    if (!getStateResponse.status) {
      setLoader(false)
      return
    }

    getStateResponse.data.map((state) =>
      data.push({
        label: state.nome,
        id: state.id,
        value: state.sigla,
      })
    )

    setLoader(false)
    setStates(data)
    return
  }

  const listCities = async (state) => {
    setLoader(true)

    const getCityByStateResponse = await getCity(state)
    const data = []

    getCityByStateResponse.data.map((state) =>
      data.push({
        label: state.nome,
        value: state.nome,
      })
    )

    setLoader(false)
    setCities(data)
    return
  }

  useEffect(() => {
    listState()
  }, [])

  useEffect(() => {
    if (stateValue) {
      listCities(stateValue)
    }
  }, [stateValue])

  return (
    <>
      <div>
        <span>Estado</span>
        <SelectSearch
          id='states'
          name={stateName}
          value={states.filter((option) => option.value === stateValue)}
          loader={loader}
          options={states}
          required={required}
          onChange={(e) => {
            onChange(e)
            listCities(e.value)
          }}
        />
      </div>
      <div>
        <span>Cidade</span>
        <SelectSearch
          id='city'
          name={cityName}
          value={cities.filter((option) => option.value === cityValue)}
          loader={loader}
          options={cities}
          required={required}
          onChange={(e) => {
            onChange(e)
          }}
        />
      </div>
    </>
  )
}

export default SelectLocation
