import axios from '@/services/BaseService'
import { captureException } from '@/utils/Instrumentation/Exception'
import { translateMessage } from '@/utils/Translator'

export async function getDeviceInfo(imei) {
  const endpoint = `/v1/device/${imei}`

  try {
    const response = await axios.get(endpoint)
    return { status: true, deviceInfo: response }
  } catch (error) {
    captureException({
      error,
      tag: 'DEVICE_GET_INFO_EXCEPTION',
      endpoint,
      body: {
        imei,
      },
    })

    return {
      status: false,
      message: translateMessage(error.message),
    }
  }
}

export async function getLocation(userId) {
  const endpoint = `/v1/location/${userId}`
  try {
    const response = await axios.get(endpoint)
    return { status: true, location: response }
  } catch (error) {
    captureException({
      error,
      tag: 'DEVICE_GET_LOCATION_EXCEPTION',
      endpoint,
      body: {
        userId,
      },
    })

    return {
      status: false,
      message: translateMessage(error.message),
    }
  }
}
