import React, { createContext, useContext, useState } from 'react'

const CollapseContext = createContext()

export const CollapseProvider = ({ children }) => {
  const [collapse, setCollapse] = useState({})

  return (
    <CollapseContext.Provider value={{ collapse, setCollapse }}>
      {children}
    </CollapseContext.Provider>
  )
}

export const useCollapse = () => useContext(CollapseContext)
