import React from 'react'

import CardAction from '@/components/Presentational/Cards/Action'
import CardDisabled from '@/components/Presentational/Cards/Disabled'
import IconArrow from '@/components/Presentational/Icons/IconArrow'
import IconCamera from '@/components/Presentational/Icons/IconCamera'
import IconCheckIn from '@/components/Presentational/Icons/IconCheckIn'
import IconCheckOut from '@/components/Presentational/Icons/IconCheckOut'
import IconClock from '@/components/Presentational/Icons/IconClock'
import IconLoader from '@/components/Presentational/Icons/IconLoader'
import colors from '@/styles/colors'

const Card = ({ status, disabled, onClick }) => {
  if (disabled) {
    return (
      <CardDisabled
        dataTestId='card-disabled-anti-theft'
        type={'no-full-width'}
        title={'Câmera antirroubo'}
        iconLeft={<IconCamera color={colors.disabled2} />}
        iconRight={<IconClock />}
        button={<IconArrow color={colors.disabled2} />}
      />
    )
  }

  return (
    <CardAction
      dataTestId='card-anti-theft'
      type={'no-full-width'}
      status={status}
      onClick={onClick}
      title={'Câmera antirroubo'}
      iconLeft={<IconCamera />}
      iconRight={
        status === null ? (
          <IconLoader />
        ) : status ? (
          <IconCheckIn />
        ) : (
          <IconCheckOut />
        )
      }
      button={<IconArrow />}
    />
  )
}

export default Card
