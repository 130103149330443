import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  cursor: pointer;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
`

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  > p {
    margin: 0px;
  }
`

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  margin-top: 40px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
`
