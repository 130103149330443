import colors from '@/styles/colors'

import styled from 'styled-components'

export const Main = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto 240px;

  @media screen and (max-width: 767px) {
    margin: auto 10px;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  > div {
    display: flex;
    flex-direction: column;
    gap: 10px;

    h1 {
      font-size: 28px;
      color: ${colors.primary};
    }
  }
`

export const Content = styled.div`
  text-align: 'center';

  > h1 {
    font-size: 28px;
    color: #004bc1;
    margin-bottom: 35px;
  }
`

export const Description = styled.div`
  padding: 20px;
  background: #f5f5f5;
  border-radius: 20px;
`
