import React from 'react'

const IconCircleClose = ({ onClick, color = '#004BC1' }) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      style={{ cursor: 'pointer' }}
      data-testid='icon-circle-close'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.0001 18.3332C14.6025 18.3332 18.3334 14.6022 18.3334 9.99984C18.3334 5.39746 14.6025 1.6665 10.0001 1.6665C5.39771 1.6665 1.66675 5.39746 1.66675 9.99984C1.66675 14.6022 5.39771 18.3332 10.0001 18.3332ZM6.87832 6.87808C7.16043 6.59598 7.61781 6.59598 7.89991 6.87808L10.0001 8.97826L12.1003 6.87808C12.3824 6.59598 12.8397 6.59598 13.1218 6.87808C13.4039 7.16018 13.4039 7.61756 13.1218 7.89966L11.0217 9.99984L13.1218 12.1C13.4039 12.3821 13.4039 12.8395 13.1218 13.1216C12.8397 13.4037 12.3824 13.4037 12.1003 13.1216L10.0001 11.0214L7.89991 13.1216C7.61781 13.4037 7.16043 13.4037 6.87832 13.1216C6.59622 12.8395 6.59622 12.3821 6.87832 12.1L8.9785 9.99984L6.87832 7.89966C6.59622 7.61756 6.59622 7.16018 6.87832 6.87808Z'
        fill={color}
      />
    </svg>
  )
}

export default IconCircleClose
