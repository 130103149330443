import axios from '@/services/BaseService'
import { captureException } from '@/utils/Instrumentation/Exception'
import { translateMessage } from '@/utils/Translator'

export async function getBanks() {
  const endpoint = 'https://bff.whitelabel.com.br/bff/api/v2/too/banks'

  try {
    const response = await axios.get(endpoint, {
      headers: {
        'X-Fs-Brand-Name': 'seguros',
        'X-Fs-Requester-Name': 'anti-thief-frontend',
      },
    })
    return {
      status: true,
      banks: response,
    }
  } catch (error) {
    captureException({
      error,
      tag: 'GET_BANKS_EXCEPTION',
      endpoint,
    })

    return {
      status: false,
      message: translateMessage(error.message),
    }
  }
}
