import React from 'react'

const IconAttachment = ({ onClick }) => {
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <path
        d='M5.9999 7.42864V3.14293C5.9999 2.35395 6.57553 1.71436 7.28561 1.71436C7.99569 1.71436 8.57132 2.35395 8.57132 3.14293V7.42864C8.57132 9.0066 7.42006 10.2858 5.9999 10.2858C4.57973 10.2858 3.42847 9.0066 3.42847 7.42864V3.61912'
        stroke='#004BC1'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default IconAttachment
