import React from 'react'

import Analysis from '../Analysis'
import Pending from '../Pending'
import Refused from '../Refused'
import Success from '../Success'
import * as S from './styled'

const Details = ({ sinister, onChange, onSubmit }) => {
  return (
    <S.Main>
      {sinister.status === 'SUCCESS' && (
        <Success
          title={sinister.title}
          paymentDate={sinister.paymentDate}
          paymentValue={sinister.paymentValue}
          protocol={sinister.protocol}
        />
      )}
      {sinister.status === 'ANALYSIS' && (
        <Analysis
          status={sinister.status}
          title={sinister.title}
          protocol={sinister.protocol}
        />
      )}
      {sinister.status === 'PENDING' && (
        <Pending
          status={sinister.status}
          title={sinister.title}
          notes={sinister.notes}
          protocol={sinister.protocol}
          onChange={onChange}
          onSubmit={onSubmit}
        />
      )}
      {sinister.status === 'REFUSED' && (
        <Refused
          protocol={sinister.protocol}
          status={sinister.status}
          title={sinister.title}
          reason={sinister.reason}
        />
      )}
    </S.Main>
  )
}

export default Details
