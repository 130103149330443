import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 40px;

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 100px;
`

export const Sidebar = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 5px;

  @media screen and (max-width: 767px) {
    display: none;
  }
`

export const SidebarItem = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style-type: none;
  cursor: pointer;
  font-family: 'Space Grotesk';
  font-weight: 700;
  font-size: 16px;
  padding: 10px;

  :hover {
    box-shadow: 0px 0px 0px 4px #004bc129;
    border-radius: 10px;
  }

  ${({ active }) =>
    active &&
    css`
      box-shadow: 0px 0px 0px 4px #004bc129;
      border-radius: 10px;
    `};
`

export const Item = styled.div`
  > div {
    margin-bottom: 20px;

    > h3 {
      font-family: 'Space Grotesk';
      font-size: 42px;
      line-height: 45px;
      font-weight: 500;
      color: #bd2469;
      margin-bottom: 10px;
    }
  }
`
