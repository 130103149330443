import React from 'react'

const IconFillAdd = ({ color = '#004BB8', onClick }) => {
  return (
    <svg
      width='17'
      height='16'
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <g clipPath='url(#clip0_26088_1414)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M3.78644 12.7139C6.38994 15.3174 10.611 15.3174 13.2145 12.7139C15.818 10.1104 15.818 5.88932 13.2145 3.28582C10.611 0.682327 6.38994 0.682327 3.78644 3.28582C1.18295 5.88932 1.18295 10.1104 3.78644 12.7139ZM8.50049 4.468C8.81965 4.468 9.07838 4.72674 9.07838 5.0459L9.07838 7.42197L11.4545 7.42197C11.7736 7.42197 12.0324 7.68071 12.0324 7.99987C12.0324 8.31903 11.7736 8.57776 11.4545 8.57776L9.07838 8.57776L9.07838 10.9538C9.07838 11.273 8.81965 11.5317 8.50049 11.5317C8.18133 11.5317 7.92259 11.273 7.92259 10.9538L7.92259 8.57776L5.54652 8.57776C5.22736 8.57776 4.96862 8.31903 4.96862 7.99987C4.96862 7.68071 5.22736 7.42197 5.54652 7.42197L7.92259 7.42197L7.92259 5.0459C7.92259 4.72674 8.18133 4.468 8.50049 4.468Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_26088_1414'>
          <rect
            width='16'
            height='16'
            fill='white'
            transform='translate(0.500488)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconFillAdd
