import React from 'react'
import { NavLink } from 'react-router-dom'

import { PRIVACY_TOOLS, TERMS_OF_USE } from '@/constants'

import * as S from './styled'

const Footer = () => {
  return (
    <S.Footer>
      <ul>
        <li>
          © {new Date().getFullYear()} Proteção PIX by EXA - Todos os direitos
          reservados
        </li>
        <li>
          <NavLink to='/ajuda'>Ajuda</NavLink>
        </li>
        <li>
          <NavLink to='/faq'>FAQ</NavLink>
        </li>
        <li>
          <a href={TERMS_OF_USE} target='_blank' rel='noopener noreferrer'>
            Termos de Uso e Política de Privacidade
          </a>
        </li>
        <li>
          <a href={PRIVACY_TOOLS} target='_blank' rel='noopener noreferrer'>
            Portal de Privacidade
          </a>
        </li>
      </ul>
    </S.Footer>
  )
}

export default Footer
