import React, { createContext, useContext } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const ToastContext = createContext({})

export const ToastProvider = ({ children }) => {
  return (
    <ToastContext.Provider value={{}}>
      {children}
      <ToastContainer />
    </ToastContext.Provider>
  )
}

export const useToast = () => useContext(ToastContext)
