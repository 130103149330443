import React from 'react'
import Loader from 'react-fullscreen-loading'

const Loading = ({ display = true }) => {
  return (
    <div data-testid='loader'>
      <Loader
        loading={display}
        background={'rgba(0, 0, 0, 0.6)'}
        loaderColor={'#004BC1'}
      />
    </div>
  )
}

export default Loading
