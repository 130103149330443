import React, { createContext, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import useToast from '@/hooks/useToast'
import { encodeData } from '@/utils/Fernet'
export const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate()
  const { toastrMessage } = useToast()
  const [user, setUser] = useState(null)
  const [mobile, setMobile] = useState({})

  const signIn = (user) => {
    const st = encodeData({
      email: user.email,
    })

    localStorage.setItem('st', st)
    navigate('/dashboard')
  }

  const signOut = async () => {
    try {
      localStorage.removeItem('st')
      localStorage.removeItem('naddr')
      navigate('/')
    } catch (error) {
      toastrMessage('Ops, ocorreu um erro, por favor tente novamente.')
      return
    }
  }

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        user,
        setUser,
        mobile,
        setMobile,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
