import React from 'react'

import * as S from './styled'

const EmptyData = () => {
  return (
    <S.Container>
      <S.Content>
        <p>
          Você não tem nenhum <br />
          contato de confiança salvo
        </p>
        <svg
          width='33'
          height='32'
          viewBox='0 0 33 32'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M16.4995 29C9.31981 29 3.49951 23.1797 3.49951 16C3.49951 8.8203 9.31981 3 16.4995 3C23.6792 3 29.4995 8.8203 29.4995 16C29.4995 23.1797 23.6792 29 16.4995 29ZM16.4986 6.75045C15.8083 6.75045 15.2486 7.31009 15.2486 8.00045V8.16337C15.2486 8.85361 15.8081 9.4132 16.4983 9.41337L16.6607 9.41341C16.9922 9.41349 17.3103 9.28183 17.5447 9.0474C17.7792 8.81297 17.911 8.49498 17.911 8.16341V8.00045C17.911 7.31009 17.3513 6.75045 16.661 6.75045H16.4986ZM17.8294 13.4967C17.8294 12.8064 17.2697 12.2467 16.5794 12.2467C15.889 12.2467 15.3294 12.8064 15.3294 13.4967V23.4967C15.3294 24.1871 15.889 24.7467 16.5794 24.7467C17.2697 24.7467 17.8294 24.1871 17.8294 23.4967V13.4967Z'
            fill='#0069C2'
          />
        </svg>
      </S.Content>
      <p>
        Com contatos de confiança, você poderá salvar números importantes e
        visualizá-los pelo Portal de Proteção caso perca acesso ao seu aparelho
        celular.
        <br />
        <br />
        Você pode adicionar o contato de confiança pelo aplicativo Proteção Pix.
      </p>
    </S.Container>
  )
}

export default EmptyData
