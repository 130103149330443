import React from 'react'
import BeatLoader from 'react-spinners/BeatLoader'

import colors from '@/styles/colors'

const Spinner = () => {
  return <BeatLoader color={colors.primary} loading={true} size={20} />
}

export default Spinner
