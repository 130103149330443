import React, { useEffect, useState } from 'react'

import IconCheckIn from '@/components/Presentational/Icons/IconCheckIn'
import IconCheckOut from '@/components/Presentational/Icons/IconCheckOut'
import IconChevronDown from '@/components/Presentational/Icons/IconChevronDown'
import IconChevronUp from '@/components/Presentational/Icons/IconChevronUp'
import { useAuth } from '@/contexts/Authentication'
import { usePermissions } from '@/contexts/Permissions'

import Information from './atoms/Information'
import * as S from './styled'

const StatusRange = () => {
  const {
    user: {
      profile: {
        emailVerified,
        device_os,
        subscription: { premium },
        lastAction,
      },
    },
  } = useAuth()
  const { permissions } = usePermissions()
  const [range, setRange] = useState(0)
  const [status, setStatus] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [loader, setLoader] = useState(false)

  const getStatusRange = async () => {
    if (permissions === null) {
      setLoader(true)
      return
    }

    let percentage = 0
    const { location, alarm, camera, block, protect, clearData } = permissions

    // ANDROID FREE
    if (device_os === 'android' && !premium) {
      const index = [alarm, location, camera].filter((value) => value).length
      const key = index === 3 && emailVerified ? 4 : index
      const limit = [0, 15, 30, 45, 60]
      percentage = limit[key]
    }

    // ANDROID PREMIUM
    if (device_os === 'android' && premium) {
      const index = [alarm, location, camera, block, clearData].filter(
        (value) => value
      ).length
      const key = index < 5 ? 0 : 1
      const limit = [101, 100]
      percentage = limit[key]
    }

    // IOS FREE
    if (device_os === 'ios' && !premium) {
      const index = [location].filter((value) => value).length
      const key = index === 1 && emailVerified ? 2 : index
      const limit = [0, 45, 60]
      percentage = limit[key]
    }

    // IOS PREMIUM
    if (device_os === 'ios' && premium) {
      const index = [location, premium].filter((value) => value).length
      const limit = [101, 100]
      const key = index < 2 ? 0 : 1
      percentage = limit[key]
    }

    setRange(percentage)
    setStatus([
      {
        title: 'Proteçao PIX',
        isActive: protect || percentage === 60 || percentage === 100,
      },
      {
        title: 'Rastreio do celular',
        isActive: location,
      },
      {
        title: 'Bloqueio e limpeza de dados',
        isActive: block,
      },
      {
        title: 'Disparo de alarme',
        isActive: alarm,
      },
      {
        title: 'Câmera antirroubo',
        isActive: camera,
      },
    ])
    setLoader(false)
  }

  useEffect(() => {
    getStatusRange()
  }, [permissions])

  if (lastAction === 'CLEANED' || lastAction === 'LOGGED_OUT') {
    return <Information />
  }

  return (
    <S.Container
      status={range}
      data-testid='status-range'
      onClick={() => setIsOpen(!isOpen)}
    >
      <div />
      <p>
        {loader
          ? 'Carregando...'
          : range === 101
            ? 'Desprotegido'
            : `${range}% protegido`}
      </p>
      {isOpen ? <IconChevronUp /> : <IconChevronDown />}

      {isOpen && (
        <S.Content data-testid='status-range-container'>
          <li>
            <h1>
              {(range === 100) | (range === 60)
                ? 'Tudo ok com as proteções do seu celular!'
                : 'Opa, algo aconteceu com suas proteções.'}
            </h1>
          </li>
          {status.map(({ title, isActive }) => (
            <li key={title}>
              {title} {isActive ? <IconCheckIn /> : <IconCheckOut />}
            </li>
          ))}
        </S.Content>
      )}
    </S.Container>
  )
}

export default StatusRange
